import { Box, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CheckCircle } from "../../assets/icons/components";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
import { ApplicationType, GetPaymentStatusType, ProfileAssessment, ProfileType } from "../../api/schemas/schema";
import { hasUserPaidSemesterFeesFn } from "../../utils/payment";
import { getCourseOnbaordingTimelineListFn } from "../../utils/timeline-step";

interface Props {
  profile: ProfileType;
  lastAssessment: ProfileAssessment;
  activeApplication: ApplicationType;
  paymentData: GetPaymentStatusType;
  semesterPaymentData: GetPaymentStatusType;
}

const IITCourseTimeLine = ({ profile, lastAssessment, activeApplication, paymentData, semesterPaymentData }: Props) => {
  const isForIITRopar = profile?.program_profile_type === ProgramProfileType.IIT_ROPAR;
  const isForIITMandi = profile?.program_profile_type === ProgramProfileType.IIT_MANDI;
  const isForIITGuwahati = profile?.program_profile_type === ProgramProfileType.IIT_GUWAHATI;
  const isForIITMandiV2 = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_V2;
  const isForIITMandiESCourse = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_ES;
  const isForIITMandiBACourse = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_BA;

  const hasUserPaidSemesterFees = hasUserPaidSemesterFeesFn(semesterPaymentData, profile?.program_profile_type as ProgramProfileType);

  const initialData = getCourseOnbaordingTimelineListFn(profile?.program_profile_type as ProgramProfileType);

  const [stepsData, setStepsData] = useState(initialData);

  useEffect(() => {
    setStepsData(initialData);
  }, [initialData]);

  useEffect(() => {
    const data = [...initialData];
    const isOnboardingCompleted = activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE";

    let activeIndex = -1;

    if (
      (isForIITGuwahati && !paymentData?.is_iit_guwahati_course) ||
      (isForIITRopar && !paymentData?.is_iit_ropar_course) ||
      (isForIITMandi && !paymentData?.is_iit_mandi_course) ||
      (isForIITMandiV2 && !paymentData?.is_iit_mandi_v2_course) ||
      (isForIITMandiESCourse && !paymentData?.is_iit_mandiES_course) ||
      (isForIITMandiBACourse && !paymentData?.is_iit_mandiBA_course)
    ) {
      activeIndex = 0;
    } else if (
      profile &&
      profile?.meta &&
      ((profile.meta?.hasOrientationVideoWatched !== "True" && !isForIITRopar && !isForIITMandiV2) ||
        (profile.meta?.hasOrientationVideoWatchedForRopar !== "True" && isForIITRopar) ||
        (profile.meta?.hasClickedOnJoinSlackForMandiV2 !== "True" && isForIITMandiV2) ||
        (profile.meta?.hasOrientationVideoWatchedForMandiES !== "True" && isForIITMandiESCourse) ||
        (profile.meta?.hasOrientationVideoWatchedForMandiBA !== "True" && isForIITMandiBACourse))
    ) {
      activeIndex = 1;
    } else if (
      profile &&
      profile?.meta &&
      ((!isForIITRopar && !profile.meta?.emiOptionSelected) || (isForIITRopar && !profile.meta?.emiOptionSelectedForRopar)) &&
      ((!isForIITMandiV2 && !profile.meta?.emiOptionSelected) || (isForIITMandiV2 && !profile.meta?.emiOptionSelectedForMandi)) &&
      !hasUserPaidSemesterFees
    ) {
      activeIndex = 2;
    } else if (activeApplication && !isOnboardingCompleted) {
      activeIndex = 3;
    } else if (!isForIITRopar && !isForIITMandiV2 && ((lastAssessment && !["ENDED", "GRADED"].includes(lastAssessment?.status?.toUpperCase())) || !lastAssessment)) {
      activeIndex = 4;
    } else if (!isForIITRopar && !isForIITMandiV2) {
      activeIndex = 5;
    } else if ((isForIITRopar || isForIITMandiV2) && activeApplication && isOnboardingCompleted) {
      activeIndex = 4;
    }

    if (activeIndex >= 0) {
      for (let i = 0; i < activeIndex; i++) {
        data[i].isDone = true;
      }
      if (activeIndex < 5 && !isForIITRopar && !isForIITMandiV2) {
        data[activeIndex].isActive = true;
      } else if ((isForIITRopar || isForIITMandiV2) && activeIndex < 4) {
        data[activeIndex].isActive = true;
      }
    }

    setStepsData(data);
  }, [paymentData, profile?.meta, lastAssessment, activeApplication, hasUserPaidSemesterFees, isForIITRopar, isForIITGuwahati, isForIITMandi, initialData, isForIITMandiV2]);

  return (
    <Box>
      <Text textStyle="caption" fontWeight="600" color="#544D4F">
        Your schedule is as follows:
      </Text>
      <Box display="flex" flexDir="column" mt="8px" pl="8px">
        {stepsData &&
          stepsData.map((timeline, index) => (
            <Box key={index} display="flex" gap="9px">
              <Box>
                <Box minH="24px" minW="24px">
                  {timeline?.isDone ? (
                    <Icon color="#6FCD9E" as={CheckCircle} minH="24px" minW="24px" />
                  ) : (
                    <Text
                      minH="24px"
                      minW="24px"
                      bg={timeline.isActive ? "#6E71CC" : "white"}
                      color={timeline.isActive ? "white" : "#544D4F"}
                      rounded="100%"
                      textStyle="btn-md"
                      textAlign="center"
                      textTransform="capitalize"
                    >
                      {index + 1}
                    </Text>
                  )}
                </Box>
                {index === stepsData.length - 1 ? null : <Box w="2px" h="50%" border="1px dashed #0A0103" mx="auto" mt="3px"></Box>}
              </Box>
              <Box pb="24px">
                <Text textStyle="caption" color="#0A0103">
                  {timeline.text}
                </Text>
                <Text textStyle="body2-md" color="#0A0103">
                  {timeline.name}
                </Text>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default IITCourseTimeLine;
