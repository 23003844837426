import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { SemesterPaymentType } from "../../../api/schemas/schema";
import { useState } from "react";

interface Props {
  setSemesterPaymentOptionsData?: (data: SemesterPaymentType) => void;
  semesterPaymentOptionsData?: SemesterPaymentType;
  isForIITRopar?: boolean;
  amountToBePaid?: number;
  isForIITMandiV2?: boolean;
  isForIITMandiES?: boolean;
  isForIITMandiBA?: boolean;
}

const SemesterFeesOptions = ({ setSemesterPaymentOptionsData, isForIITRopar, isForIITMandiV2, isForIITMandiES, isForIITMandiBA }: Props) => {
  const [semesterPaymentOption, setSemesterPaymentOption] = useState<SemesterPaymentType>("FULLY_PAID");
  const showEmiOptions = () => {
    return (
      <>
        <Radio value="FULLY_PAID">
          <Box>
            <Text fontSize={"16px"}>₹{isForIITRopar ? "47,000" : isForIITMandiV2 ? "47,000" : isForIITMandiES ? "97,000" : isForIITMandiBA ? "40,000" : "1,60,000"}/-</Text>
          </Box>
        </Radio>
      </>
    );
  };

  return (
    <Box>
      <Box mt={2} display={"flex"} flexDir={"column"} gap={4}>
        {/* EMI Options */}
        <Box display={"flex"} flexDir={"column"} gap={2}>
          <Text textStyle={"body2-md"} color={"black"}>
            Option 1 (One time payment)
          </Text>
          <RadioGroup
            color={"blackAlpha.900"}
            value={semesterPaymentOption}
            colorScheme="blue"
            onChange={(data) => {
              if (data) {
                setSemesterPaymentOptionsData && setSemesterPaymentOptionsData("FULLY_PAID");
                setSemesterPaymentOption("FULLY_PAID");
              }
            }}
          >
            <Stack spacing={{ base: 5, md: 10 }} direction={{ base: "column", md: "row" }}>
              {showEmiOptions()}
            </Stack>
          </RadioGroup>
        </Box>

        {/* Current Working Status */}
        <Box display={isForIITRopar || isForIITMandiV2 || isForIITMandiBA || isForIITMandiES ? "none" : "flex"} flexDir={"column"} gap={2}>
          <Text textStyle={"body2-md"} color={"black"}>
            Option 2 (Payment in two half)
          </Text>
          <RadioGroup
            color={"blackAlpha.900"}
            value={semesterPaymentOption}
            colorScheme="blue"
            onChange={(data) => {
              if (data) {
                setSemesterPaymentOptionsData && setSemesterPaymentOptionsData("PARTIALLY_PAID");
                setSemesterPaymentOption("PARTIALLY_PAID");
              }
            }}
          >
            <Stack spacing={8} direction="row">
              <Radio value="PARTIALLY_PAID">
                <Box display={"flex"} flexDirection={"row"} gap="4" padding={"10px"}>
                  <Box>
                    <Text fontSize={"16px"}>₹{isForIITRopar ? "24,000" : isForIITMandiES ? "54,500" : isForIITMandiV2 ? "28,500" : "90,000"}/-</Text>
                    <Text>(upfront)</Text>
                  </Box>
                  <Box mt="10px">+</Box>
                  <Box>
                    <Text fontSize={"16px"}>₹{isForIITRopar ? "24,000" : isForIITMandiES ? "54,500" : isForIITMandiV2 ? "28,500" : "90,000"}/-</Text>
                    <Text> {isForIITRopar || isForIITMandiV2 || isForIITMandiES ? "(after 1st semester)" : "(after 6 months)"}</Text>
                  </Box>
                </Box>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default SemesterFeesOptions;
