import React, { useState } from "react";
import { Box, Heading, Input, FormControl, FormLabel, FormErrorMessage, Flex, Radio, RadioGroup, Text, Select, Checkbox, Button } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../common/general/SnackBar";
import { SaveUserInfo } from "../../api/utils/api/v2/IITEntranceExam";
import { axiosErrorHandler } from "../../api/utils/error";
import { useLocation } from "react-router-dom";
import { courDataDynamic } from "../EntranceExam/constents";

// Define an interface for the error messages
interface ErrorMessages {
  fullName?: string;
  dateOfBirth?: string;
  currentLocation?: string;
  graduationMonthYear?: string;
  tshirtSize?: string;
  address?: string;
  pincode?: string;
  townCity?: string;
  state?: string;
  consent?: string;
}

const OnboardingForm = ({ refetchIITOnboardingStatus }: { refetchIITOnboardingStatus: () => void }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  // State to hold form values
  const [formValues, setFormValues] = useState({
    fullName: "",
    dateOfBirth: "",
    currentLocation: "",
    alternateContact: "",
    contactMode: "",
    graduationMonthYear: "",
    tshirtSize: "",
    address: "",
    areaStreet: "",
    landmark: "",
    pincode: "",
    townCity: "",
    state: "",
    consent: false,
  });

  // State to hold error messages for required fields
  const [errors, setErrors] = useState<ErrorMessages>({});

  const snackbar = useSnackBar();
  const { mutate: saveuserInfoMutate, isLoading: isSavinguserInfo } = useMutation(SaveUserInfo, {
    onSuccess: async () => {
      snackbar.success("Your information have been saved");
      refetchIITOnboardingStatus();
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    });
  };

  // Validate required fields and set errors
  const validateForm = () => {
    const tempErrors: ErrorMessages = {};
    if (!formValues.fullName) tempErrors.fullName = "This field is required";
    if (!formValues.dateOfBirth) tempErrors.dateOfBirth = "This field is required";
    if (!formValues.currentLocation) tempErrors.currentLocation = "This field is required";
    if (!formValues.graduationMonthYear) tempErrors.graduationMonthYear = "This field is required";
    if (!formValues.tshirtSize) tempErrors.tshirtSize = "This field is required";
    if (!formValues.address) tempErrors.address = "This field is required";
    if (!formValues.pincode) tempErrors.pincode = "This field is required";
    if (!formValues.townCity) tempErrors.townCity = "This field is required";
    if (!formValues.state) tempErrors.state = "This field is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      // Handle form submission logic here
      console.log("Form submitted:", formValues);
    } else {
      console.log("Form validation failed");
    }

    saveuserInfoMutate({
      course_id: course,
      name: formValues.fullName,
      dob: formValues.dateOfBirth,
      currentLocation: formValues.currentLocation,
      alternateContact: formValues.alternateContact,
      currentStatus: formValues.contactMode,
      graduationDate: formValues.graduationMonthYear,
      tShirtSize: formValues.tshirtSize,
      houseAddress: formValues.address,
      areaAddress: formValues.areaStreet,
      landmark: formValues.landmark,
      pincode: +formValues.pincode,
      town: formValues.townCity,
      state: formValues.state,
    });
  };
  return (
    <>
      <Box borderRadius="14px" boxShadow={{ base: "none", md: "sm" }} p={{ base: "5px", md: "24px" }} border={{ base: "none", md: "1px solid #E5E5E5" }}>
        <Heading as="h6" mt={{ base: "0px" }}>
          We need some of your basic details for your admit
        </Heading>

        <FormControl isInvalid={!!errors.fullName} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Full Name<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="fullName" value={formValues.fullName || ""} onChange={handleInputChange} placeholder="Enter your Full Name" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.fullName}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.dateOfBirth} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Date of Birth<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input type="date" name="dateOfBirth" value={formValues.dateOfBirth} onChange={handleInputChange} maxW="484px" h="48px" />
          <FormErrorMessage>{errors.dateOfBirth}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.currentLocation} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Your Current Location<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="currentLocation" value={formValues.currentLocation} onChange={handleInputChange} placeholder="Enter your Current Location" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.currentLocation}</FormErrorMessage>
        </FormControl>

        <FormControl mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Add alternate contact number
          </FormLabel>
          <Input name="alternateContact" value={formValues.alternateContact} onChange={handleInputChange} placeholder="Enter alternate contact number" maxW="484px" h="48px" />
        </FormControl>

        <FormControl as="fieldset" mt="24px">
          <FormLabel as="legend" fontSize="14px" fontWeight="600">
            What is your current status?
          </FormLabel>
          <RadioGroup name="contactMode" onChange={(value) => setFormValues({ ...formValues, contactMode: value })} value={formValues.contactMode}>
            <Flex gap="24px">
              <Flex alignItems="center" w="145px">
                <Radio value="currently_studying" />
                <Text ml="2" fontSize="14px" fontWeight="500">
                  Currently studying
                </Text>
              </Flex>
              <Flex alignItems="center" w="145px">
                <Radio value="currently_working" />
                <Text ml="2" fontSize="14px" fontWeight="500">
                  Currently working
                </Text>
              </Flex>
              <Flex alignItems="center" w="145px">
                <Radio value="neither" />
                <Text ml="2" fontSize="14px" fontWeight="500">
                  Neither working nor studying
                </Text>
              </Flex>
            </Flex>
          </RadioGroup>
        </FormControl>

        <FormControl isInvalid={!!errors.graduationMonthYear} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Select your graduation month and year<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input type="month" name="graduationMonthYear" value={formValues.graduationMonthYear} onChange={handleInputChange} maxW="484px" h="48px" />
          <FormErrorMessage>{errors.graduationMonthYear}</FormErrorMessage>
        </FormControl>

        <Heading as="h6" mt={{ base: "24px", md: "24px" }}>
          Delivery Address
        </Heading>

        <FormControl isInvalid={!!errors.tshirtSize} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            T-shirt size<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Select name="tshirtSize" value={formValues.tshirtSize} onChange={handleInputChange} placeholder="Select your T-shirt size" maxW="484px" h="48px">
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="XXXL">XXXL</option>
          </Select>
          <FormErrorMessage>{errors.tshirtSize}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.address} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Flat, House no., Building, Apartment<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="address" value={formValues.address} onChange={handleInputChange} maxW="484px" h="48px" />
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        </FormControl>

        <FormControl mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Area, Street, Sector, Village
          </FormLabel>
          <Input name="areaStreet" value={formValues.areaStreet} onChange={handleInputChange} maxW="484px" h="48px" />
        </FormControl>

        <FormControl mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Landmark
          </FormLabel>
          <Input name="landmark" value={formValues.landmark} onChange={handleInputChange} maxW="484px" h="48px" />
        </FormControl>

        <FormControl isInvalid={!!errors.pincode} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Pincode<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="pincode" value={formValues.pincode} onChange={handleInputChange} placeholder="6 digits [0-9] PIN code" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.pincode}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.townCity} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            Town/City<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="townCity" value={formValues.townCity} onChange={handleInputChange} placeholder="Enter Town/City" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.townCity}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.state} mt="24px">
          <FormLabel fontSize="14px" fontWeight="600">
            State<span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input name="state" value={formValues.state} onChange={handleInputChange} placeholder="Enter State" maxW="484px" h="48px" />
          <FormErrorMessage>{errors.state}</FormErrorMessage>
        </FormControl>
      </Box>

      <Box mt="24px" w="full" mb="100px">
        <Button isLoading={isSavinguserInfo} variant="primary" size="md" w="full" maxW="full" onClick={handleSubmit}>
          SUBMIT DETAIL
        </Button>
      </Box>
    </>
  );
};

export default OnboardingForm;
