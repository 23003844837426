export const iitOnboardingFormInputList = [
  {
    inputLabel: "Mother's name *",
    inputType: "text",
    inputName: "motherName",
    inputPlaceholder: "Enter mother's name",
  },
  {
    inputLabel: "Mother’s Phone Number *",
    inputType: "tel",
    inputPattern: "[0-9]{10}",
    inputMinlenght: "10",
    inputMaxLenght: "10",
    inputName: "motherPhoneNumber",
    inputPlaceholder: "Enter mother’s phone number",
  },
  {
    inputLabel: "Mother's Email ID *",
    inputType: "email",
    inputName: "motherEmail",
    inputPattern: "^[^@]+@[^@]+\\.[^@]+$",
    inputPlaceholder: "Enter mother’s email ID",
  },
  {
    inputLabel: "Father’s name *",
    inputType: "text",
    inputName: "fatherName",
    inputPlaceholder: "Enter father’s name",
  },
  {
    inputLabel: "Father’s Phone Number *",
    inputType: "tel",
    inputPattern: "[0-9]{10}",
    inputMinlenght: "10",
    inputMaxLenght: "10",
    inputName: "fatherPhoneNumber",
    inputPlaceholder: "Enter father’s phone number",
  },
  {
    inputLabel: "Father’s Email ID *",
    inputType: "email",
    inputName: "fatherEmail",
    inputPattern: "^[^@]+@[^@]+\\.[^@]+$",
    inputPlaceholder: "Enter father’s email ID",
  },
];

export type NewAadhaarPANCardUploadedDataType = {
  aadhaarFront?: string | File;
  aadhaarBack?: string | File;
  panCardFront?: string | File;
  panCardBack?: string | File;
  incomeProof?: string | File;
};

export interface IITOboardingFormInputInterface {
  motherName?: string;
  motherPhoneNumber?: string;
  motherEmail?: string;
  fatherName?: string;
  fatherPhoneNumber?: string;
  fatherEmail?: string;
  hasOrientationVideoWatched?: boolean;
  motherNameForRopar?: string;
  motherPhoneNumberForRopar?: string;
  motherEmailForRopar?: string;
  fatherNameForRopar?: string;
  fatherPhoneNumberForRopar?: string;
  fatherEmailForRopar?: string;
  hasOrientationVideoWatchedForRopar?: boolean;
  hasClickedOnJoinSlackForMandiV2?: boolean;
  hasClickedOnJoinSlackForMandiES?: boolean;
  hasClickedOnJoinSlackForMandiBA?: boolean;
  programmingFoundationClass?: string;
  englishFoundationClass?: string;

  hasSkippedLaunchForm?: boolean;
  hasFilledLaunchFormDetails?: boolean;
  youtubeLink?: string;
  youtubeLinkSubmittedAt?: string;

  // For MandiV2
  motherNameForMandiV2?: string;
  motherPhoneNumberForMandiV2?: string;
  motherEmailForMandiV2?: string;
  fatherNameForMandiV2?: string;
  fatherPhoneNumberForMandiV2?: string;
  fatherEmailForMandiV2?: string;
  hasOrientationVideoWatchedForMandiV2?: boolean;

  // For MandiES
  motherNameForMandiES?: string;
  motherPhoneNumberForMandiES?: string;
  motherEmailForMandiES?: string;
  fatherNameForMandiES?: string;
  fatherPhoneNumberForMandiES?: string;
  fatherEmailForMandiES?: string;
  hasOrientationVideoWatchedForMandiES?: boolean;

  // For MandiBA
  motherNameForMandiBA?: string;
  motherPhoneNumberForMandiBA?: string;
  motherEmailForMandiBA?: string;
  fatherNameForMandiBA?: string;
  fatherPhoneNumberForMandiBA?: string;
  fatherEmailForMandiBA?: string;
  hasOrientationVideoWatchedForMandiBA?: boolean;
}

export interface IITEMIOptions {
  emiOptionSelected: string;
  selfPaid: string;
  coApplicant?: string;
  currentWorkingStatus?: string;
  studentMonthlySalary?: string;
  employmentType?: string;
  coApplicantEmploymentType?: string;
  emiOptionSelectedForRopar: string;
  selfPaidForRopar: string;
  coApplicantForRopar?: string;
  currentWorkingStatusForRopar?: string;
  studentMonthlySalaryForRopar?: string;
  coApplicantEmploymentTypeForRopar?: string;
  employmentTypeForRopar?: string;

  // For MandiV2
  emiOptionSelectedForMandiV2: string;
  selfPaidForMandiV2: string;
  coApplicantForMandiV2?: string;
  currentWorkingStatusForMandiV2?: string;
  studentMonthlySalaryForMandiV2?: string;
  coApplicantEmploymentTypeForMandiV2?: string;
  employmentTypeForMandiV2?: string;

  // For MandiES
  emiOptionSelectedForMandiES: string;
  selfPaidForMandiES: string;
  coApplicantForMandiES?: string;
  currentWorkingStatusForMandiES?: string;
  studentMonthlySalaryForMandiES?: string;
  coApplicantEmploymentTypeForMandiES?: string;
  employmentTypeForMandiES?: string;

  // For MandiBA
  emiOptionSelectedForMandiBA: string;
  selfPaidForMandiBA: string;
  coApplicantForMandiBA?: string;
  currentWorkingStatusForMandiBA?: string;
  studentMonthlySalaryForMandiBA?: string;
  coApplicantEmploymentTypeForMandiBA?: string;
  employmentTypeForMandiBA?: string;
}

export interface PanCardImageUrlInterface {
  panCardFront?: string;
  panCardBack?: string;
}

export interface PapOnboardingInterface {
  highest_qualification: string;
  hasGoodInternet: string;
  current_occupation_status?: string;
}

export interface EmpowerHerDetailsType {
  familyIncome?: number;
  state?: string;
  pincode?: number;
  empowerHerCity?: string;
  hasAcceptedDeclaration?: boolean;
  empowerHerMSATNextBtn?: boolean;
  gender?: string;
  hasMarkedEmpowerHerChoosen?: boolean;
}

export interface EmpowerHerVerifyUserType {
  hasMarkedEmpowerHerChoosen?: boolean;
}

export const iitOnboardingFormDefaultValue: IITOboardingFormInputInterface = {
  fatherEmail: "",
  fatherPhoneNumber: "",
  motherEmail: "",
  motherName: "",
  motherPhoneNumber: "",
  fatherName: "",
};

export enum PaymentMethod {
  ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
  EMI_PAYMENT = "EMI_PAYMENT",
}

export interface IITGuwahatiProfileDetailInputInterface extends PanCardImageUrlInterface {
  parentGuardianEmail?: string;
  parentGuardianPhoneNumber?: string;
  parentGuardianName?: string;
  businessName?: string;
  companyName?: string;
  monthlySalary?: string;
  monthlyBusinessIncome?: string;
  alternativePhoneNumber?: string;
  annualCTC?: string;
  bankStatement?: string;
  coApplicantCompanyName?: string;
  coApplicantBusinessName?: string;
  coApplicantMonthlySalary?: string;
  coApplicantMonthlyBusinessIncome?: string;
  coApplicantEmail?: string;
  coApplicantPhoneNumber?: string;
  coApplicantName?: string;
  coApplicantBankStatement?: string;
  bankStatementPassword?: string;
  coApplicantProfilePic?: string;
  coApplicantPanCard?: string;
  coApplicantAadhaarFront?: string;
  coApplicantAadhaarBack?: string;
  creditInforConcent?: boolean | string;
  aadharFront?: string;
  aadharBack?: string;
  coApplicantRelationProof?: string;
  // For Ropar
  parentGuardianEmailForRopar?: string;
  parentGuardianPhoneNumberForRopar?: string;
  businessNameForRopar?: string;
  companyNameForRopar?: string;
  monthlySalaryForRopar?: string;
  monthlyBusinessIncomeForRopar?: string;
  coApplicantCompanyNameForRopar?: string;
  coApplicantBusinessNameForRopar?: string;
  coApplicantMonthlySalaryForRopar?: string;
  coApplicantMonthlyBusinessIncomeForRopar?: string;
  parentGuardianNameForRopar?: string;
  alternativePhoneNumberForRopar?: string;
  annualCTCForRopar?: string;
  bankStatementForRopar?: string;
  coApplicantEmailForRopar?: string;
  coApplicantPhoneNumberForRopar?: string;
  coApplicantNameForRopar?: string;
  coApplicantBankStatementForRopar?: string;
  bankStatementPasswordForRopar?: string;
  coApplicantProfilePicForRopar?: string;
  coApplicantPanCardForRopar?: string;
  coApplicantAadhaarFrontForRopar?: string;
  coApplicantAadhaarBackForRopar?: string;
  creditInforConcentForRopar?: boolean | string;
  coApplicantRelationProofForRopar?: string;
  dob?: string;

  // For MandiV2
  parentGuardianEmailForMandiV2?: string;
  parentGuardianPhoneNumberForMandiV2?: string;
  businessNameForMandiV2?: string;
  companyNameForMandiV2?: string;
  monthlySalaryForMandiV2?: string;
  monthlyBusinessIncomeForMandiV2?: string;
  coApplicantCompanyNameForMandiV2?: string;
  coApplicantBusinessNameForMandiV2?: string;
  coApplicantMonthlySalaryForMandiV2?: string;
  coApplicantMonthlyBusinessIncomeForMandiV2?: string;
  parentGuardianNameForMandiV2?: string;
  alternativePhoneNumberForMandiV2?: string;
  annualCTCForMandiV2?: string;
  bankStatementForMandiV2?: string;
  coApplicantEmailForMandiV2?: string;
  coApplicantPhoneNumberForMandiV2?: string;
  coApplicantNameForMandiV2?: string;
  coApplicantBankStatementForMandiV2?: string;
  bankStatementPasswordForMandiV2?: string;
  coApplicantProfilePicForMandiV2?: string;
  coApplicantPanCardForMandiV2?: string;
  coApplicantAadhaarFrontForMandiV2?: string;
  coApplicantAadhaarBackForMandiV2?: string;
  creditInforConcentForMandiV2?: boolean | string;
  coApplicantRelationProofForMandiV2?: string;

  // For MandiES
  parentGuardianEmailForMandiES?: string;
  parentGuardianPhoneNumberForMandiES?: string;
  businessNameForMandiES?: string;
  companyNameForMandiES?: string;
  monthlySalaryForMandiES?: string;
  monthlyBusinessIncomeForMandiES?: string;
  coApplicantCompanyNameForMandiES?: string;
  coApplicantBusinessNameForMandiES?: string;
  coApplicantMonthlySalaryForMandiES?: string;
  coApplicantMonthlyBusinessIncomeForMandiES?: string;
  parentGuardianNameForMandiES?: string;
  alternativePhoneNumberForMandiES?: string;
  annualCTCForMandiES?: string;
  bankStatementForMandiES?: string;
  coApplicantEmailForMandiES?: string;
  coApplicantPhoneNumberForMandiES?: string;
  coApplicantNameForMandiES?: string;
  coApplicantBankStatementForMandiES?: string;
  bankStatementPasswordForMandiES?: string;
  coApplicantProfilePicForMandiES?: string;
  coApplicantPanCardForMandiES?: string;
  coApplicantAadhaarFrontForMandiES?: string;
  coApplicantAadhaarBackForMandiES?: string;
  creditInforConcentForMandiES?: boolean | string;
  coApplicantRelationProofForMandiES?: string;

  // For MandiBA
  parentGuardianEmailForMandiBA?: string;
  parentGuardianPhoneNumberForMandiBA?: string;
  businessNameForMandiBA?: string;
  companyNameForMandiBA?: string;
  monthlySalaryForMandiBA?: string;
  monthlyBusinessIncomeForMandiBA?: string;
  coApplicantCompanyNameForMandiBA?: string;
  coApplicantBusinessNameForMandiBA?: string;
  coApplicantMonthlySalaryForMandiBA?: string;
  coApplicantMonthlyBusinessIncomeForMandiBA?: string;
  parentGuardianNameForMandiBA?: string;
  alternativePhoneNumberForMandiBA?: string;
  annualCTCForMandiBA?: string;
  bankStatementForMandiBA?: string;
  coApplicantEmailForMandiBA?: string;
  coApplicantPhoneNumberForMandiBA?: string;
  coApplicantNameForMandiBA?: string;
  coApplicantBankStatementForMandiBA?: string;
  bankStatementPasswordForMandiBA?: string;
  coApplicantProfilePicForMandiBA?: string;
  coApplicantPanCardForMandiBA?: string;
  coApplicantAadhaarFrontForMandiBA?: string;
  coApplicantAadhaarBackForMandiBA?: string;
  creditInforConcentForMandiBA?: boolean | string;
  coApplicantRelationProofForMandiBA?: string;
}
