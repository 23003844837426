import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import CollegeDropdown from "../../../pages/Application/CollegeDropdown";
import { CustomDropDown } from "../../common/general";
import { AppContext } from "../../../context/ApplicationContext";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { useLocation } from "react-router-dom";
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type InitPorp = {
  name: string;
  email_address: string;
  in_college: number | null;
  graduation_year: string;
  college_id: number | null;
  phone_number: string;
};

type Props = {
  phoneNumber: string;
  closeModal: () => void;
  nextStep: () => void;
  formData: InitPorp | null;
  setFormData: (data: InitPorp) => void;
};

const BookADemo = ({ phoneNumber, closeModal, nextStep, formData, setFormData }: Props) => {
  const location = useLocation();
  const state = useContext(AppContext)[0];
  const initValues = {
    name: state.profileData?.name || "",
    email_address: state.profileData?.email || "",
    in_college:
      state.profileData?.professional_status === "CURRENTLY_STUDING" && state.profileData?.college?.id ? 1 : state.profileData?.professional_status && state.profileData?.college?.id ? 0 : null,
    graduation_year: state.profileData?.graduation_year && state.profileData?.graduation_year !== "0" ? state.profileData?.graduation_year : "",
    college_id: state.profileData?.college?.id || null,
    phone_number: state.profileData?.phone_number || "",
  };

  const handleSubmit = (data: InitPorp) => {
    setFormData(data);
    nextStep();
  };

  const validateName = (value: string) => {
    let error;

    if (/^\s/.test(value)) {
      value = "";
    }

    if (!value) {
      error = "Your name is required";
    }

    return error;
  };

  const validateEmailAddress = (value: string) => {
    let error;

    if (/^\s/.test(value)) {
      value = "";
    }

    const emailTest = emailRegex.test(value);

    if (!value) {
      error = "Email address is required";
    } else if (!emailTest) {
      error = "Please enter a valid email ID";
    }
    return error;
  };

  const validateInCollege = (value: number) => {
    let error;

    if (value === null || value === undefined) {
      error = "The above field is required";
    }

    return error;
  };

  const validateCollegeId = (value: string) => {
    let error;

    if (!value) {
      error = "College Name is required";
    }

    return error;
  };

  const validateGraduationYear = (value: string) => {
    let error;

    if (!value) {
      error = "Graduation Year is required";
    }

    return error;
  };

  const years: { value: string; label: string }[] = [];
  for (let i = 0; i <= 5; i++) {
    const year = (new Date().getFullYear() + i).toString();
    years.push({ value: year, label: year });
  }

  return (
    <div>
      <Heading as="h3">Book a Demo for FREE!</Heading>

      <Formik initialValues={initValues} onSubmit={handleSubmit}>
        {({ values, handleChange, setFieldValue }) => {
          console.log("values:", values);
          return (
            <Box as={Form} mt="24px">
              <Field name="name" validate={validateName}>
                {({ form }: { form: { errors: { name: boolean }; touched: { name: boolean } } }) => (
                  <FormControl isInvalid={form.errors.name && form.touched.name} isReadOnly={state.profileData?.name ? true : false} isDisabled={state.profileData?.name ? true : false}>
                    <FormLabel color="#21191B" fontSize="14px" fontWeight="600" fontFamily="Open Sans" m="0px" mb="6px !important">
                      Your Name{" "}
                      <Text as="span" color="#E53E3E">
                        *
                      </Text>
                    </FormLabel>

                    <Input name="name" type="text" placeholder="Enter your name" value={values.name} onChange={handleChange} />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="email_address" validate={validateEmailAddress}>
                {({ form }: { form: { errors: { email_address: boolean }; touched: { email_address: boolean } } }) => (
                  <FormControl
                    mt="12px"
                    isInvalid={form.errors.email_address && form.touched.email_address}
                    isReadOnly={state.profileData?.email ? true : false}
                    isDisabled={state.profileData?.email ? true : false}
                  >
                    <FormLabel color="#21191B" fontSize="14px" fontWeight="600" fontFamily="Open Sans" m="0px" mb="6px !important">
                      Your Email address{" "}
                      <Text as="span" color="#E53E3E">
                        *
                      </Text>
                    </FormLabel>

                    <Input name="email_address" type="text" placeholder="Enter your email address" value={values.email_address} onChange={handleChange} />

                    <FormErrorMessage>{form.errors.email_address}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="in_college" validate={validateInCollege}>
                {({ form }: { form: { errors: { in_college: boolean }; touched: { in_college: boolean } } }) => (
                  <FormControl
                    mt="12px"
                    isInvalid={form.errors.in_college && form.touched.in_college}
                    isReadOnly={state.profileData?.professional_status && state.profileData?.college?.id ? true : false}
                    isDisabled={state.profileData?.professional_status && state.profileData?.college?.id ? true : false}
                  >
                    <FormLabel color="#21191B" fontSize="14px" fontWeight="600" fontFamily="Open Sans" m="0px" mb="6px !important">
                      Are you currently studying in college{" "}
                      <Text as="span" color="#E53E3E">
                        *
                      </Text>
                    </FormLabel>

                    <RadioGroup
                      mt="6px"
                      name="in_college"
                      value={values?.in_college?.toString() || ""}
                      onChange={(value) => {
                        setFieldValue("college_id", null);
                        setFieldValue("graduation_year", "");
                        setFieldValue("in_college", +value);
                      }}
                    >
                      <Stack direction="row">
                        <Radio value="1" p="8px">
                          Yes
                        </Radio>
                        <Radio value="0" p="8px">
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>

                    <FormErrorMessage>{form.errors.in_college}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {values?.in_college && typeof values.in_college === "number" && values.in_college === 1 ? (
                <>
                  <Field name="college_id" validate={validateCollegeId}>
                    {({ form }: { form: { errors: { college_id: boolean }; touched: { college_id: boolean } } }) => (
                      <FormControl
                        mt="12px"
                        isInvalid={form.errors.college_id && form.touched.college_id}
                        isReadOnly={state.profileData?.college?.id ? true : false}
                        isDisabled={state.profileData?.college?.id ? true : false}
                      >
                        <FormLabel color="#21191B" fontSize="14px" fontWeight="600" fontFamily="Open Sans" m="0px" mb="6px !important">
                          College name{" "}
                          <Text as="span" color="#E53E3E">
                            *
                          </Text>
                        </FormLabel>

                        <CollegeDropdown
                          inputSize="lg"
                          value={
                            state.profileData?.college?.id
                              ? {
                                  id: state.profileData?.college?.id,
                                  name: state.profileData?.college?.name,
                                }
                              : undefined
                          }
                          onChange={(event) => {
                            if (event.id) {
                              setFieldValue("college_id", event.id);
                            }
                          }}
                        />

                        <FormErrorMessage>{form.errors.college_id}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="graduation_year" validate={validateGraduationYear}>
                    {({
                      form,
                    }: {
                      form: {
                        errors: { graduation_year: boolean };
                        touched: { graduation_year: boolean };
                      };
                    }) => (
                      <FormControl
                        mt="12px"
                        isInvalid={form.errors.graduation_year && form.touched.graduation_year}
                        isReadOnly={initValues?.graduation_year ? true : false}
                        isDisabled={initValues?.graduation_year ? true : false}
                      >
                        <FormLabel color="#21191B" fontSize="14px" fontWeight="600" fontFamily="Open Sans" m="0px" mb="6px !important">
                          Your Graduation year{" "}
                          <Text as="span" color="#E53E3E">
                            *
                          </Text>
                        </FormLabel>

                        <CustomDropDown
                          value={values.graduation_year ? { value: values.graduation_year, label: values.graduation_year } : undefined}
                          allOptions={years}
                          handleChange={(data) => {
                            setFieldValue("graduation_year", data?.value);
                          }}
                          placeholder="YYYY"
                        />

                        <FormErrorMessage>{form.errors.graduation_year}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </>
              ) : (
                <></>
              )}

              <Button
                size="md"
                m="auto"
                px="8"
                w="100%"
                h="50px"
                mt="16px"
                variant="primary"
                colorScheme="blue"
                fontWeight="600"
                fontSize="ms-14"
                lineHeight="ms-24"
                bg="ms-blue.500"
                color="white"
                cursor="pointer"
                textTransform="uppercase"
                type="submit"
                onClick={() => {
                  sendTrackingEvent({
                    event: `dash_book_demo_submit`,
                    eventLabel: location.pathname,
                  });
                }}
              >
                Submit
              </Button>
            </Box>
          );
        }}
      </Formik>
    </div>
  );
};

export default BookADemo;
