import { GetPaymentStatusType } from "../api/schemas/schema";
import { ProgramProfileType } from "../enums/ProgramType.enum";

export const hasUserPaidSemesterFeesFn = (semesterPaymentData: GetPaymentStatusType | undefined, institute: ProgramProfileType): boolean => {
  if (!semesterPaymentData) return false;

  switch (institute) {
    case ProgramProfileType.IIT_ROPAR:
      return semesterPaymentData.is_iit_ropar_course && semesterPaymentData.status === "PAID";
    case ProgramProfileType.IIT_MANDI:
    case ProgramProfileType.IIT_MANDI_V2:
      return semesterPaymentData.is_iit_mandi_course && semesterPaymentData.status === "PAID";
    case ProgramProfileType.IIT_GUWAHATI:
      return semesterPaymentData.is_iit_guwahati_course && semesterPaymentData.status === "PAID";
    case ProgramProfileType.IIT_MANDI_ES:
      return semesterPaymentData.is_iit_mandiES_course && semesterPaymentData.status === "PAID";
    case ProgramProfileType.IIT_MANDI_BA:
      return semesterPaymentData.is_iit_mandiBA_course && semesterPaymentData.status === "PAID";
    default:
      return false;
  }
};
