import type { components } from "../generated/API";

export type GetCourse = components["schemas"]["GetCourse"];
export type CourseBase = components["schemas"]["CourseBase"];
export type GetBatch = components["schemas"]["GetBatch"];
export type GetUnappliedBatch = components["schemas"]["GetUnappliedBatch"];
export type CourseType = components["schemas"]["CourseType"];
export type ProfessionalStatus = components["schemas"]["ProfessionalStatus"];
export type UGDEGREE = components["schemas"]["UGDEGREE"];
export type ReferralLeaderboardDataType = components["schemas"]["ReferralLeaderboardData"];
export type GetCounsellingRequestStatusType = components["schemas"]["GetCounsellingRequestStatus"];

export type PGDEGREE = components["schemas"]["PGDEGREE"];
export type GetApplicationType = components["schemas"]["GetApplication"];
export type GetProfileType = components["schemas"]["GetProfile"];
export type ApplicationStatus = components["schemas"]["ApplicationStatus"];
export type PatchProfileType = components["schemas"]["PatchProfile"];
export type GetOnboardingProgress = components["schemas"]["GetOnboardingProgress"];
export type SendAadharOTPResponse = components["schemas"]["SendAadharOTPResponse"];
export type GetFormFilledStatusType = components["schemas"]["GetFormFilledStatus"];
export type ProfileType = components["schemas"]["Profile"];
export type GetCourseV2Type = components["schemas"]["GetCourseV2"];
export type ApplicationType = components["schemas"]["Application"];
export type AadhaarInfoType = components["schemas"]["AadhaarInfo"];
export type ProfileProgramType = components["schemas"]["ProgramInfo"];
export type GetS3PresignedUrlType = components["schemas"]["GetS3PresignedUrl"];
export type ReferralsInputType = components["schemas"]["ReferralsInput"];
export type GetDostRequestType = components["schemas"]["GetDostRequest"];
export type ChangeBulkDostRequestStatusType = components["schemas"]["ChangeBulkDostRequestStatus"];
export type DostRequestStatusType = components["schemas"]["DostRequestStatus"];
export type GetAdminCounsellingRequestType = components["schemas"]["GetAdminCounsellingRequest"];
export type GetAdminCounsellingRequestResponseType = components["schemas"]["GetAdminCounsellingRequestResponse"];
export type GetAdminDostRequestResponseType = components["schemas"]["GetAdminDostRequestResponse"];
export type ChangeCounsellingRequestStatusType = components["schemas"]["ChangeCounsellingRequestStatus"];
export type CounsellingRequestStatusType = components["schemas"]["CounsellingRequestStatus"];
export type CreateEventType = components["schemas"]["CreateEvent"];
export type UpdateEventType = components["schemas"]["UpdateEvent"];
export type GetProfileTypeV2 = components["schemas"]["Profile"];
export type BatchType = components["schemas"]["BatchBaseV2"];

export type GetS3PresignedUrlToUploadDPType = components["schemas"]["GetS3PresignedUrlToUploadDP"];

export type GetReferralsType = components["schemas"]["GetReferrals"];
export type ReferredProfileType = components["schemas"]["ReferredProfile"];

export type ReferralProgramInformationType = components["schemas"]["ReferralProgramInformation"];
export type ReferralListInformationType = components["schemas"]["ReferralData"];
export type ReferralAddressType = components["schemas"]["Address"];
export type ReferralCoinResponseType = components["schemas"]["ReferralCoinResponse"];
export type GenderType = components["schemas"]["Gender"];
export type GetEventType = components["schemas"]["GetEvent"];
export type GetAdminEventType = components["schemas"]["GetAdminEvent"];
export type EventTypeV2 = components["schemas"]["Event"];
export type GetAlumniType = components["schemas"]["GetAlumni"];
export type GetDostType = components["schemas"]["GetDost"];
export type GetEventProfile = components["schemas"]["GetEventProfile"];
export type GetContestType = components["schemas"]["GetContest"];
export type GetContestURLType = components["schemas"]["GetContestURL"];
export type LeaderboardType = components["schemas"]["Leaderboard"];
export type LeaderboardDataType = components["schemas"]["LeaderboardData"];
export type GetRegistrationFeePaymentDetailsType = components["schemas"]["GetRegistrationFeePaymentDetails"];
export type GetCouponType = components["schemas"]["GetCoupon"];
export type VerifyCouponType = components["schemas"]["VerifyCoupon"];
export type GetCollegeType = components["schemas"]["GetCollege"];
export type GetSelfLearningCourseType = components["schemas"]["GetSelfLearningCourse"];
export type GetCourseEnrollmentType = components["schemas"]["GetCourseEnrollment"];
export type SelfLearningCourseStatusType = components["schemas"]["SelfLearningCourseStatus"];
export type ErrorResponseModelType = components["schemas"]["ErrorResponseModel"];
export type RequestCallbackResponseType = components["schemas"]["RequestCallbackResponse"];
export type GetMSATAssessmentInfoType = components["schemas"]["GetAssessmentURLInfo"];
export type PaymentInfoOfRazorpayType = components["schemas"]["PaymentInfoOfRazorpay"];
export type GetRegistrationFeeType = components["schemas"]["GetRegistrationFee"];
// export type PaymentStatusType = components["schemas"]["PaymentStatus"];
export type SemesterPaymentType = components["schemas"]["SemesterPaymentType"];

export type PaymentStatusType = components["schemas"]["models__payment_record__PaymentStatus"];
export type EventsForTracking = components["schemas"]["EventsForTracking"];
export type ProfileAssessment = components["schemas"]["ProfileAssessment"];
export type TriggerType = components["schemas"]["TriggerType"];
export type MarkingSchemeType = components["schemas"]["MarkingScheme"];
export type CAPOneTimeLink = components["schemas"]["GetCapDemoSession"];
export type GetSpeakerType = components["schemas"]["GetSpeaker"];
export type IsEntityRatedResponse = components["schemas"]["IsEntityRatedResponse"];
export type BannedUserType = components["schemas"]["BannedUser"];
export type UnbannedUserType = components["schemas"]["UnbannedUser"];
export type CreatePaymentAdminType = components["schemas"]["CreatePaymentAdmin"];
export type CreatePaymentInBulkAdmin = components["schemas"]["CreatePaymentInBulkAdmin"];
export type CreatePaymentInBulkResponse = components["schemas"]["CreatePaymentInBulkResponse"];
export type ProfileTypeByAdmin = components["schemas"]["ProfileByAdmin"];
export type UpdateBatchDetailsType = components["schemas"]["UpdateBatchDetails"];
export type CreateBatchDetailsType = components["schemas"]["CreateBatchType"];
export type CreateBatchType = components["schemas"]["BatchType"];
export type CreateBatchResponse = components["schemas"]["CreateBatchResponse"];
export type CreateIITEntranceMockTestInput = components["schemas"]["CreateIITEntranceMockTestInput"];
export type UpdateTestStatusInput = components["schemas"]["UpdateTestStatusInput"];
export type RescheduleIITEntranceSlot = components["schemas"]["RescheduleIITEntranceTestInput"];
export type CreateIITEntranceMainTestInput = components["schemas"]["CreateIITEntranceMainTestInput"];

export type RazorpaySuccessReportType = components["schemas"]["ReportRazorpaySuccessV2"];
export type CreatePaymentRecordType = components["schemas"]["CreatePaymentRecord"];
export type IITEntranceExamStatusParams = components["schemas"]["GetIITEntranceTestStatus"];
export type CreatePaymentRecordTypeForIITEntranceTest = components["schemas"]["CreatePaymentRecordForIITEntranceTest"];
export type DeadlineExtensionRequestInput = components["schemas"]["DeadlineEntranceRequest"];
export type ScheduleIITEntranceCounsellingV2Input = components["schemas"]["ScheduleIITEntranceCounsellingV2Input"];
export type CreatePaymentRecordForIITEntranceTest = components["schemas"]["CreatePaymentRecordForIITEntranceTest"];
export type CreatePaymentRecordForIITOnboarding = components["schemas"]["CreatePaymentRecordForIITOnboarding"];
export type CreatePaymentRecordForIITOnboardingEMI = components["schemas"]["CreatePaymentRecordForIITOnboardingEMI"];
export type SaveUserInfoInput = components["schemas"]["SaveUserInfoInput"];
export type ScheduleIITEntranceCounsessingInput = components["schemas"]["ScheduleIITEntranceCounsessingInput"];
export type CreatePaymentRecordTypeForIITEntranceTestSeatBook = components["schemas"]["CreatePaymentRecordForIITEntranceTestSeatBook"];
export type CreatePaymentRecordForIITEntranceTestSeatBookForNotSelected = components["schemas"]["CreatePaymentRecordForIITEntranceTestSeatBookForNotSelected"];
export type UpdateUserInfoIITEntranceTest = components["schemas"]["UpdateUserDetailsIITEntranceTestInput"];
export type GetPaymentStatusType = components["schemas"]["GetPaymentStatus"];
export type PaymentType = components["schemas"]["PaymentType"];
export type ProgramProfileType = components["schemas"]["ProgramProfileType"];
export type UpdateProfileByAdmin = components["schemas"]["UpdateProfileByAdmin"];

// levelup related schemas
export type GetLevelupAlumniType = components["schemas"]["GetLevelupAlumniType"];
export type ScheduleAlumniSessionType = components["schemas"]["ScheduleAlumniSessionType"];
export type FeedbackType = components["schemas"]["FeedbackType"];
export type StartTest = components["schemas"]["StartTest"];
export type GetUserDetailsForAIInterview = components["schemas"]["GetUserDetailsForAIInterview"];
export type GetHiresOfTheWeekAPIResponse = components["schemas"]["HiresOfTheWeekResponse"];

export const genderTypeMap = new Map<string | number, GenderType>();

genderTypeMap.set("MALE", "MALE");
genderTypeMap.set("FEMALE", "FEMALE");
genderTypeMap.set("OTHERS", "OTHERS");

export enum ApplicationStatusEnums {
  PROFILE_UNFILLED = "PROFILE_UNFILLED",
  INELIGIBLE = "INELIGIBLE",
  METTL_TO_BE_TAKEN = "METTL_TO_BE_TAKEN",
  METTL_STARTED = "METTL_STARTED",
  METTL_DESCISION_PENDING = "METTL_DESCISION_PENDING",
  METTL_FAILED = "METTL_FAILED",
  METTL_PASSED = "METTL_PASSED",
  INTERVIEW_BOOKING_PENDING = "INTERVIEW_BOOKING_PENDING",
  INTERVIEW_BOOKED = "INTERVIEW_BOOKED",
  INTERVIEW_DECISION_PENDING = "INTERVIEW_DECISION_PENDING",
  INTERVIEW_FAILED = "INTERVIEW_FAILED",
  DOCUMENTATION_PENDING = "DOCUMENTATION_PENDING",
  DOCUMENTATION_DECISION_PENDING = "DOCUMENTATION_DECISION_PENDING",
  DOCUMENTATION_FAILED = "DOCUMENTATION_FAILED",
  FELLOW = "FELLOW",
  FDN_FELLOW = "FDN_FELLOW",
  BATCH_CHANGE = "BATCH_CHANGE",
  DROP_OUT = "DROP_OUT",
  ASYNC = "ASYNC",
  ISA_SUCCESSFUL = "ISA_SUCCESSFUL",
  DISCONTINUED = "DISCONTINUED",
}

export type SectionScores = {
  sectionMaxScore?: number | 0;
  sectionName?: string | null;
  sectionScore?: number | 0;
  category?: QuestionCategory;
}[];

export enum QuestionCategory {
  cognitive_ability_sectionA = "Cognitive Ability - Section A",
  cognitive_ability_sectionB = "Cognitive Ability - Section B",
  cognitive_ability_sectionC = "Cognitive Ability - Section C",
  cognitive_ability_sectionD = "Cognitive Ability - Section D",
  attention_to_details = "Attention to Details",
  communication_score = "communication_score",
  interview = "Interview",
}

export enum ReferralStatus {
  Signed_Up = "Signed Up",
  Application_Registered = "Application Registered",
  MSAT_Pending = "MSAT Pending",
  MSAT_Started = "MSAT Started",
  MSAT_Cleared = "MSAT Cleared",
  MSAT_Not_Cleared = "MSAT Not Cleared",
  Admitted = "Admitted",
  Batch_Change = "Batch Change",
  Drop_Out = "Drop Out",
  Fellow = "Fellow",
  Async = "Async",
  ISA_Successful = "PAP Successful",
  Application_Closed = "Application Closed",
  Discontinued = "Discontinued",
  Payment_Pending = "Payment Pending",
  Payment_Successful = "Payment Successful",
  Scholarship_Test_To_be_Taken = "Scholarship Test To be Taken",
  Scholarship_Test_Failed = "Scholarship Test Failed",
  Scholarship_Test_Passed = "Scholarship Test Passed",
  Coding_test_to_be_taken = "Coding test to be taken",
  Coding_Test_Passed = "Coding Test Passed",
  Coding_Test_Failed = "Coding Test Failed",
}

export const onboardingStatus = ["ONBOARDING_COMPLETE", "FELLOW", "ISA_SUCCESSFUL"];

export enum COUNSELLING_TYPE {
  MSAT_CANCEL = "msat-cancel",
  MSAT_FAIL_1ST_ATTEMPT = "msat-fail-1st-attempt",
  MSAT_FAIL_2ND_ATTEMPT = "msat-fail-2nd-attempt",
  PROFILE_INELIGIBLE = "profile-ineligible",
  FAILS_AT_AADHAAR = "fails-at-aadhaar",
  SKIP_COURSE_SELECTION_MSAT_PASSED = "skip-course-selection-msat-passed",
  REQUEST_CALLBACK = "request-callback",
  HOME_COUNSELLING_ELIGIBLE = "home-counselling-eligible",
  COURSES_COUNSELLING_ELIGIBLE = "course-counselling-eligible",
  EVENTS_COUNSELLING_ELIGIBLE = "event-counselling-eligible",
  MSAT_COUNSELLING_ELIGIBLE = "msat-counselling-eligible",
  HOME_COUNSELLING_INELIGIBLE = "home-counselling-ineligible",
  COURSES_COUNSELLING_INELIGIBLE = "course-counselling-ineligible",
  EVENTS_COUNSELLING_INELIGIBLE = "event-counselling-ineligible",
  MSAT_COUNSELLING_INELIGIBLE = "msat-counselling-ineligible",
  COUNSELLING_ELIGIBLE = "counselling-eligible",
  COUNSELLING_INELIGIBLE = "counselling-ineligible",
  SLACK_JOINING_ISSUE = "slack-joining-issue",

  MSAT_CANCEL_IITG = "msat-cancel_iitg",
  MSAT_FAIL_1ST_ATTEMPT_IITG = "msat-fail-1st-attempt_iitg",
  MSAT_FAIL_2ND_ATTEMPT_IITG = "msat-fail-2nd-attempt_iitg",
  PROFILE_INELIGIBLE_IITG = "profile-ineligible_iitg",
  FAILS_AT_AADHAAR_IITG = "fails-at-aadhaar_iitg",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IITG = "skip-course-selection-msat-passed_iitg",
  REQUEST_CALLBACK_IITG = "request-callback_iitg",
  HOME_COUNSELLING_ELIGIBLE_IITG = "home-counselling-eligible_iitg",
  COURSES_COUNSELLING_ELIGIBLE_IITG = "course-counselling-eligible_iitg",
  EVENTS_COUNSELLING_ELIGIBLE_IITG = "event-counselling-eligible_iitg",
  MSAT_COUNSELLING_ELIGIBLE_IITG = "msat-counselling-eligible_iitg",
  HOME_COUNSELLING_INELIGIBLE_IITG = "home-counselling-ineligible_iitg",
  COURSES_COUNSELLING_INELIGIBLE_IITG = "course-counselling-ineligible_iitg",
  EVENTS_COUNSELLING_INELIGIBLE_IITG = "event-counselling-ineligible_iitg",
  MSAT_COUNSELLING_INELIGIBLE_IITG = "msat-counselling-ineligible_iitg",
  COUNSELLING_ELIGIBLE_IITG = "counselling-eligible_iitg",
  COUNSELLING_INELIGIBLE_IITG = "counselling-ineligible_iitg",

  SEMSTER_FEES_PAYMENT_COUNCELLING_IIT = "semester-fees-payment-councelling_iit",

  MSAT_CANCEL_IIT_MANDI = "msat-cancel_iit_mandi",
  MSAT_FAIL_1ST_ATTEMPT_IIT_MANDI = "msat-fail-1st-attempt_iit_mandi",
  MSAT_FAIL_2ND_ATTEMPT_IIT_MANDI = "msat-fail-2nd-attempt_iit_mandi",
  PROFILE_INELIGIBLE_IIT_MANDI = "profile-ineligible_iit_mandi",
  FAILS_AT_AADHAAR_IIT_MANDI = "fails-at-aadhaar_iit_mandi",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_MANDI = "skip-course-selection-msat-passed_iit_mandi",
  REQUEST_CALLBACK_IIT_MANDI = "request-callback_iit_mandi",
  REQUEST_CALLBACK_IIT_MANDI_V2 = "request-callback_iit_mandi_v2",
  REQUEST_CALLBACK_IIT_MANDI_ES = "request-callback_iit_mandi_es",
  REQUEST_CALLBACK_IIT_MANDI_BA = "request-callback_iit_mandi_ba",
  REQUEST_CALLBACK_IIT_ROPAR = "request-callback_iit_ropar",
  HOME_COUNSELLING_ELIGIBLE_IIT_MANDI = "home-counselling-eligible_iit_mandi",
  COURSES_COUNSELLING_ELIGIBLE_IIT_MANDI = "course-counselling-eligible_iit_mandi",
  EVENTS_COUNSELLING_ELIGIBLE_IIT_MANDI = "event-counselling-eligible_iit_mandi",
  MSAT_COUNSELLING_ELIGIBLE_IIT_MANDI = "msat-counselling-eligible_iit_mandi",
  HOME_COUNSELLING_INELIGIBLE_IIT_MANDI = "home-counselling-ineligible_iit_mandi",
  COURSES_COUNSELLING_INELIGIBLE_IIT_MANDI = "course-counselling-ineligible_iit_mandi",
  EVENTS_COUNSELLING_INELIGIBLE_IIT_MANDI = "event-counselling-ineligible_iit_mandi",
  MSAT_COUNSELLING_INELIGIBLE_IIT_MANDI = "msat-counselling-ineligible_iit_mandi",
  COUNSELLING_ELIGIBLE_IIT_MANDI = "counselling-eligible_iit_mandi",
  COUNSELLING_INELIGIBLE_IIT_MANDI = "counselling-ineligible_iit_mandi",

  SEMSTER_FEES_PAYMENT_COUNCELLING_IIT_MANDI = "semester-fees-payment-councelling_iit_mandi",
}

export enum IITG_COUNSELLING_TYPE {
  MSAT_CANCEL_IITG = "msat-cancel_iitg",
  MSAT_FAIL_1ST_ATTEMPT_IITG = "msat-fail-1st-attempt_iitg",
  MSAT_FAIL_2ND_ATTEMPT_IITG = "msat-fail-2nd-attempt_iitg",
  PROFILE_INELIGIBLE_IITG = "profile-ineligible_iitg",
  FAILS_AT_AADHAAR_IITG = "fails-at-aadhaar_iitg",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IITG = "skip-course-selection-msat-passed_iitg",
  REQUEST_CALLBACK_IITG = "request-callback_iitg",
  HOME_COUNSELLING_ELIGIBLE_IITG = "home-counselling-eligible_iitg",
  COURSES_COUNSELLING_ELIGIBLE_IITG = "course-counselling-eligible_iitg",
  EVENTS_COUNSELLING_ELIGIBLE_IITG = "event-counselling-eligible_iitg",
  MSAT_COUNSELLING_ELIGIBLE_IITG = "msat-counselling-eligible_iitg",
  HOME_COUNSELLING_INELIGIBLE_IITG = "home-counselling-ineligible_iitg",
  COURSES_COUNSELLING_INELIGIBLE_IITG = "course-counselling-ineligible_iitg",
  EVENTS_COUNSELLING_INELIGIBLE_IITG = "event-counselling-ineligible_iitg",
  MSAT_COUNSELLING_INELIGIBLE_IITG = "msat-counselling-ineligible_iitg",
  COUNSELLING_ELIGIBLE_IITG = "counselling-eligible_iitg",
  COUNSELLING_INELIGIBLE_IITG = "counselling-ineligible_iitg",
}

export enum IIT_MANDI_COUNSELLING_TYPE {
  MSAT_CANCEL_IIT_MANDI = "msat-cancel_iit_mandi",
  MSAT_FAIL_1ST_ATTEMPT_IIT_MANDI = "msat-fail-1st-attempt_iit_mandi",
  MSAT_FAIL_2ND_ATTEMPT_IIT_MANDI = "msat-fail-2nd-attempt_iit_mandi",
  PROFILE_INELIGIBLE_IIT_MANDI = "profile-ineligible_iit_mandi",
  FAILS_AT_AADHAAR_IIT_MANDI = "fails-at-aadhaar_iit_mandi",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_MANDI = "skip-course-selection-msat-passed_iit_mandi",
  REQUEST_CALLBACK_IIT_MANDI = "request-callback_iit_mandi",
  HOME_COUNSELLING_ELIGIBLE_IIT_MANDI = "home-counselling-eligible_iit_mandi",
  COURSES_COUNSELLING_ELIGIBLE_IIT_MANDI = "course-counselling-eligible_iit_mandi",
  EVENTS_COUNSELLING_ELIGIBLE_IIT_MANDI = "event-counselling-eligible_iit_mandi",
  MSAT_COUNSELLING_ELIGIBLE_IIT_MANDI = "msat-counselling-eligible_iit_mandi",
  HOME_COUNSELLING_INELIGIBLE_IIT_MANDI = "home-counselling-ineligible_iit_mandi",
  COURSES_COUNSELLING_INELIGIBLE_IIT_MANDI = "course-counselling-ineligible_iit_mandi",
  EVENTS_COUNSELLING_INELIGIBLE_IIT_MANDI = "event-counselling-ineligible_iit_mandi",
  MSAT_COUNSELLING_INELIGIBLE_IIT_MANDI = "msat-counselling-ineligible_iit_mandi",
  COUNSELLING_ELIGIBLE_IIT_MANDI = "counselling-eligible_iit_mandi",
  COUNSELLING_INELIGIBLE_IIT_MANDI = "counselling-ineligible_iit_mandi",
}

export enum IIT_ROPAR_COUNSELLING_TYPE {
  MSAT_CANCEL_IIT_ROPAR = "msat-cancel_iit_ropar",
  MSAT_FAIL_1ST_ATTEMPT_IIT_ROPAR = "msat-fail-1st-attempt_iit_ropar",
  MSAT_FAIL_2ND_ATTEMPT_IIT_ROPAR = "msat-fail-2nd-attempt_iit_ropar",
  PROFILE_INELIGIBLE_IIT_ROPAR = "profile-ineligible_iit_ropar",
  FAILS_AT_AADHAAR_IIT_ROPAR = "fails-at-aadhaar_iit_ropar",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_ROPAR = "skip-course-selection-msat-passed_iit_ropar",
  REQUEST_CALLBACK_IIT_ROPAR = "request-callback_iit_ropar",
  HOME_COUNSELLING_ELIGIBLE_IIT_ROPAR = "home-counselling-eligible_iit_ropar",
  COURSES_COUNSELLING_ELIGIBLE_IIT_ROPAR = "course-counselling-eligible_iit_ropar",
  EVENTS_COUNSELLING_ELIGIBLE_IIT_ROPAR = "event-counselling-eligible_iit_ropar",
  MSAT_COUNSELLING_ELIGIBLE_IIT_ROPAR = "msat-counselling-eligible_iit_ropar",
  HOME_COUNSELLING_INELIGIBLE_IIT_ROPAR = "home-counselling-ineligible_iit_ropar",
  COURSES_COUNSELLING_INELIGIBLE_IIT_ROPAR = "course-counselling-ineligible_iit_ropar",
  EVENTS_COUNSELLING_INELIGIBLE_IIT_ROPAR = "event-counselling-ineligible_iit_ropar",
  MSAT_COUNSELLING_INELIGIBLE_IIT_ROPAR = "msat-counselling-ineligible_iit_ropar",
  COUNSELLING_ELIGIBLE_IIT_ROPAR = "counselling-eligible_iit_ropar",
  COUNSELLING_INELIGIBLE_IIT_ROPAR = "counselling-ineligible_iit_ropar",
}

export enum IIT_MANDI_V2_COUNSELLING_TYPE {
  MSAT_CANCEL_IIT_MANDI_V2 = "msat-cancel_iit_mandi_v2",
  MSAT_FAIL_1ST_ATTEMPT_IIT_MANDI_V2 = "msat-fail-1st-attempt_iit_mandi_v2",
  MSAT_FAIL_2ND_ATTEMPT_IIT_MANDI_V2 = "msat-fail-2nd-attempt_iit_mandi_v2",
  PROFILE_INELIGIBLE_IIT_MANDI_V2 = "profile-ineligible_iit_mandi_v2",
  FAILS_AT_AADHAAR_IIT_MANDI_V2 = "fails-at-aadhaar_iit_mandi_v2",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_MANDI_V2 = "skip-course-selection-msat-passed_iit_mandi_v2",
  REQUEST_CALLBACK_IIT_MANDI_V2 = "request-callback_iit_mandi_v2",
  HOME_COUNSELLING_ELIGIBLE_IIT_MANDI_V2 = "home-counselling-eligible_iit_mandi_v2",
  COURSES_COUNSELLING_ELIGIBLE_IIT_MANDI_V2 = "course-counselling-eligible_iit_mandi_v2",
  EVENTS_COUNSELLING_ELIGIBLE_IIT_MANDI_V2 = "event-counselling-eligible_iit_mandi_v2",
  MSAT_COUNSELLING_ELIGIBLE_IIT_MANDI_V2 = "msat-counselling-eligible_iit_mandi_v2",
  HOME_COUNSELLING_INELIGIBLE_IIT_MANDI_V2 = "home-counselling-ineligible_iit_mandi_v2",
  COURSES_COUNSELLING_INELIGIBLE_IIT_MANDI_V2 = "course-counselling-ineligible_iit_mandi_v2",
  EVENTS_COUNSELLING_INELIGIBLE_IIT_MANDI_V2 = "event-counselling-ineligible_iit_mandi_v2",
  MSAT_COUNSELLING_INELIGIBLE_IIT_MANDI_V2 = "msat-counselling-ineligible_iit_mandi_v2",
  COUNSELLING_ELIGIBLE_IIT_MANDI_V2 = "counselling-eligible_iit_mandi_v2",
  COUNSELLING_INELIGIBLE_IIT_MANDI_V2 = "counselling-ineligible_iit_mandi_v2",
}

export enum IIT_MANDI_ES_COUNSELLING_TYPE {
  MSAT_CANCEL_IIT_MANDI_ES = "msat-cancel_iit_mandi_es",
  MSAT_FAIL_1ST_ATTEMPT_IIT_MANDI_ES = "msat-fail-1st-attempt_iit_mandi_es",
  MSAT_FAIL_2ND_ATTEMPT_IIT_MANDI_ES = "msat-fail-2nd-attempt_iit_mandi_es",
  PROFILE_INELIGIBLE_IIT_MANDI_ES = "profile-ineligible_iit_mandi_es",
  FAILS_AT_AADHAAR_IIT_MANDI_ES = "fails-at-aadhaar_iit_mandi_es",
  SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_MANDI_ES = "skip-course-selection-msat-passed_iit_mandi_es",
  REQUEST_CALLBACK_IIT_MANDI_ES = "request-callback_iit_mandi_es",
  HOME_COUNSELLING_ELIGIBLE_IIT_MANDI_ES = "home-counselling-eligible_iit_mandi_es",
  COURSES_COUNSELLING_ELIGIBLE_IIT_MANDI_ES = "course-counselling-eligible_iit_mandi_es",
  EVENTS_COUNSELLING_ELIGIBLE_IIT_MANDI_ES = "event-counselling-eligible_iit_mandi_es",
  MSAT_COUNSELLING_ELIGIBLE_IIT_MANDI_ES = "msat-counselling-eligible_iit_mandi_es",
  HOME_COUNSELLING_INELIGIBLE_IIT_MANDI_ES = "home-counselling-ineligible_iit_mandi_es",
  COURSES_COUNSELLING_INELIGIBLE_IIT_MANDI_ES = "course-counselling-ineligible_iit_mandi_es",
  EVENTS_COUNSELLING_INELIGIBLE_IIT_MANDI_ES = "event-counselling-ineligible_iit_mandi_es",
  MSAT_COUNSELLING_INELIGIBLE_IIT_MANDI_ES = "msat-counselling-ineligible_iit_mandi_es",
  COUNSELLING_ELIGIBLE_IIT_MANDI_ES = "counselling-eligible_iit_mandi_es",
  COUNSELLING_INELIGIBLE_IIT_MANDI_ES = "counselling-ineligible_iit_mandi_es",
}

export enum PAYMENT_COUNSELLING_TYPE_IITG {
  SEMSTER_FEES_PAYMENT_COUNCELLING_IIT = "semester-fees-payment-councelling_iit",
}

export enum PAYMENT_COUNSELLING_TYPE_IIT_MANDI {
  SEMSTER_FEES_PAYMENT_COUNCELLING_IIT_MANDI = "semester-fees-payment-councelling_iit_mandi",
}

export enum MASAI_ONBOARDING_COUNSELLING_TYPE {
  SLACK_JOINING_ISSUE = "slack-joining-issue",
}

export enum MASAI_COUNSELLING_TYPE {
  MSAT_CANCEL = "msat-cancel",
  MSAT_FAIL_1ST_ATTEMPT = "msat-fail-1st-attempt",
  MSAT_FAIL_2ND_ATTEMPT = "msat-fail-2nd-attempt",
  PROFILE_INELIGIBLE = "profile-ineligible",
  FAILS_AT_AADHAAR = "fails-at-aadhaar",
  SKIP_COURSE_SELECTION_MSAT_PASSED = "skip-course-selection-msat-passed",
  REQUEST_CALLBACK = "request-callback",
  HOME_COUNSELLING_ELIGIBLE = "home-counselling-eligible",
  COURSES_COUNSELLING_ELIGIBLE = "course-counselling-eligible",
  EVENTS_COUNSELLING_ELIGIBLE = "event-counselling-eligible",
  MSAT_COUNSELLING_ELIGIBLE = "msat-counselling-eligible",
  HOME_COUNSELLING_INELIGIBLE = "home-counselling-ineligible",
  COURSES_COUNSELLING_INELIGIBLE = "course-counselling-ineligible",
  EVENTS_COUNSELLING_INELIGIBLE = "event-counselling-ineligible",
  MSAT_COUNSELLING_INELIGIBLE = "msat-counselling-ineligible",
  COUNSELLING_ELIGIBLE = "counselling-eligible",
  COUNSELLING_INELIGIBLE = "counselling-ineligible",
}
