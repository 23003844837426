/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import CSVFileUpload from "./CSVFileUpload";
import { Select } from "@chakra-ui/react";
import { CourseSelected, PaymentType } from "../../../../enums/ProgramType.enum";
import { OfferedCourseList, PaymentTypeList } from "../../../../constants/Program-list";
import useSnackBar from "../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";
import get from "lodash.get";
import { useMutation } from "react-query";
import { createBulkExternalPayment } from "../../../../api/utils/api/v2/admin";

export interface PaymentCsvDataType {
  payment_date: string;
  order_id: string;
  email: string;
  name: string;
  phone_number: number;
  amount_paid: number;
}

const BulkUploadPayments = () => {
  const snackbar = useSnackBar();
  const [csvData, setCSVData] = useState<PaymentCsvDataType[]>([]);
  const [courseSelected, setCourseSelected] = useState<CourseSelected>();
  const [paymentType, setPaymentType] = useState<PaymentType>();

  const { mutate: createBulkExternalPaymentFn, isLoading: isCreatingExternalPayment } = useMutation(createBulkExternalPayment, {
    onSuccess: (data: any) => {
      snackbar.success(data.message);
      setCSVData([]);
    },
    onError: (error: any) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        const message = get(e, ["detail"], "Something went wrong");
        snackbar.error(message);
      }
      setCSVData([]);
    },
  });

  const handleBulkExternalPaymentCreation = () => {
    const payload = {
      data: csvData,
      course_selected: courseSelected || CourseSelected.IIT_MANDI_V2,
      paymentType: paymentType || PaymentType.REGISTRATION_FEES,
    };
    createBulkExternalPaymentFn(payload);
  };

  return (
    <Box my="20px" border="1px solid" borderColor={"ms-grey.200"} p="20px" borderRadius={"12px"}>
      <Heading as="h3" mb="16px" color="ms-grey.700">
        Upload Payments in Bulk
      </Heading>
      <Box>
        <Text as="span" textStyle={"body2-md"} ml="12px" color={"red"}>
          1. Upload csv file and column name should match exatcly below format
          <br />
          <Text as="span" textStyle={"body2-md"} ml="40px" color={"blue"}>
            - payment_date, order_id, email, name, phone_number, amount_paid
          </Text>
        </Text>
      </Box>

      <CSVFileUpload setCsvData={setCSVData} />
      {/* Render the CSV data */}
      {csvData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Payment Date</th>
              <th>Order ID</th>
              <th>Email</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Amount Paid</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((payment, index) => (
              <tr key={index}>
                <td>{payment.payment_date}</td>
                <td>{payment.order_id}</td>
                <td>{payment.email}</td>
                <td>{payment.name}</td>
                <td>{payment.phone_number}</td>
                <td>{payment.amount_paid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Box>
        <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
          Select Course Type*
        </label>
        <Select
          placeholder={"Select Course"}
          onChange={(event) => {
            setCourseSelected(event.target.value as CourseSelected);
          }}
        >
          {OfferedCourseList.map((item) => (
            <option key={item?.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </Box>

      <Box>
        <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
          Select Payment Type*
        </label>
        <Select
          placeholder={"Select Payment Type"}
          onChange={(event) => {
            setPaymentType(event.target.value as PaymentType);
          }}
        >
          {PaymentTypeList.map((item) => (
            <option key={item?.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </Box>

      <Button
        isLoading={isCreatingExternalPayment}
        isDisabled={isCreatingExternalPayment || !courseSelected || !paymentType || csvData.length === 0}
        width={"100px"}
        variant="primary"
        onClick={() => {
          // first ask are you sure you want to create payment and if yes then call the function
          if (window.confirm(`Are you sure you want to create the payment?\n\nThis action cannot be undone, so please cross-check the data before proceeding.`)) {
            handleBulkExternalPaymentCreation();
          }
        }}
        ml="auto"
        mt="4"
      >
        Create
      </Button>
    </Box>
  );
};

export default BulkUploadPayments;
