import React, { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import CountdownTimer from "./CountdownTimer";
import PaymentOption from "./PaymentOptions";
import PaymentNote from "./PaymentNote";
import PaymentButton from "./PaymentButton";
import dayjs from "dayjs";
import { dayLeftFor1TrimesterFee } from "../../../../utils/utils";
import { ProfileType } from "../../../../api/schemas/schema";
import { ProgramProfileType } from "../../../../enums/ProgramType.enum";

interface PaymentSectionProps {
  instalmentAmount: number;
  courseStartDate: string;
  endDateForPayment?: { date: string; overdue: boolean };
  registerationPaymentDate?: string;
  profile: ProfileType;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ registerationPaymentDate, instalmentAmount, courseStartDate, endDateForPayment, profile }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = dayjs();
    const endDate = dayLeftFor1TrimesterFee(registerationPaymentDate || "").date;
    const endDateTime = dayjs(endDate);
    const difference = endDateTime.diff(now);

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
  return (
    <Box display="flex" flexDirection="column" paddingBottom={6} backgroundColor="white" borderRadius="2xl" border="1px solid #E5E5E5" boxShadow="md" maxW="542px">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        paddingY={5}
        paddingRight={20}
        paddingLeft={4}
        width="full"
        backgroundColor="blue.50"
        _media={{ base: { paddingRight: 5, maxW: "full" } }}
      >
        <CountdownTimer registerationPaymentDate={registerationPaymentDate} endDateForPayment={endDateForPayment} timeLeft={timeLeft} />
      </Flex>
      <Box display="flex" flexDirection="column" paddingX={6} marginTop={7} width="full" _media={{ base: { paddingX: 5, maxW: "full" } }}>
        <Text fontSize="base" fontWeight="semibold" lineHeight="short" color="black">
          You have selected EMI option:
        </Text>
        <PaymentOption amount={instalmentAmount} />
        <PaymentNote courseStartDate={courseStartDate} isIITRopar={profile.program_profile_type === ProgramProfileType.IIT_ROPAR} />
        <PaymentButton amount={instalmentAmount} profile={profile} semesterPaymentOptionsData={"EMI_PAID"} />
      </Box>
    </Box>
  );
};

export default PaymentSection;
