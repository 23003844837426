import { ProgramProfileType } from "../enums/ProgramType.enum";
import { IITGuwahatiStepsList, IITMandiESAndBAStepsList, IITMandiNewStepsList, IITMandiStepsList, IITRoparStepsList } from "../components/V2/Dashboard/constant";

export const getCourseOnbaordingTimelineListFn = (profileType?: ProgramProfileType) => {
  switch (profileType) {
    case ProgramProfileType.IIT_ROPAR:
      return IITRoparStepsList;
    case ProgramProfileType.IIT_MANDI_V2:
      return IITMandiNewStepsList;
    case ProgramProfileType.IIT_GUWAHATI:
      return IITGuwahatiStepsList;
    case ProgramProfileType.IIT_MANDI_BA:
    case ProgramProfileType.IIT_MANDI_ES:
      return IITMandiESAndBAStepsList;
    default:
      return IITMandiStepsList;
  }
};
