import { Box, Button, Checkbox, FormControl, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import PayInEmi from "./PayInEmi";
import { paymentOptions } from "./constant";
import useIITEntranceOnboardingFullPayment from "../../../api/utils/hooks/useIITMainPaymentInFull";
import { courDataDynamic } from "../constents";
import { useLocation } from "react-router-dom";
import useSnackBar from "../../common/general/SnackBar";
import { useMutation, useQuery } from "react-query";
import { axiosErrorHandler } from "../../../api/utils/error";
import { CreatePaymentRecordPostForIITEntranceMainEMI } from "../../../api/utils/api/v2/payment";
import { GetIITEntranceExamStatus } from "../../../api/utils/api/v2/IITEntranceExam";

const FeePayment = ({ refetchIITOnboardingStatus, seatBookingAmount }: { refetchIITOnboardingStatus: () => void; seatBookingAmount: number }) => {
  const [selectedOption, setSelectedOption] = useState("Pay full amount");
  const { startRazorPay } = useIITEntranceOnboardingFullPayment(() => {
    refetchIITOnboardingStatus();
  });

  const snackbar = useSnackBar();
  const { mutate: emiPaymentMutate, isLoading: isLoadingEMIPayment } = useMutation(CreatePaymentRecordPostForIITEntranceMainEMI, {
    onSuccess: async (data) => {
      snackbar.success("Our team will contact you soon via call for the next steps.");
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const { data: IITEntranceExamStatusData, isLoading: isLoadingIITEntranceExamStatus, refetch: refetchIITExamStatus } = useQuery({
    queryKey: ["getIITEntranceExamStatus"],
    queryFn: async () => await GetIITEntranceExamStatus(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const [payInEMIForm, setPayInEMIForm] = useState({
    emiOption: "6 months",
    currentStatus: "",
    monthlySalary: "",
  });

  const courseFeeAmount =
    courDataDynamic("ranker")[course].courseFee -
    seatBookingAmount -
    (IITEntranceExamStatusData?.scholarshipAmount ? courDataDynamic("ranker")[course].courseFee * (IITEntranceExamStatusData?.scholarshipAmount / 100) : 0);
  return (
    <Box maxW={{ md: "637px", base: "345px" }} justifyContent="center" alignItems="center" mx="auto" display={"flex"} flexDirection={"column"} gap={"20px"} mt="40px">
      <Box
        w="full"
        flex="1"
        justifyContent="center"
        alignItems="center"
        mx="auto"
        borderRadius="16px"
        border="1px solid #E5E5E5"
        boxShadow="0px 0.986px 2.957px 0px rgba(0, 0, 0, 0.10), 0px 0.986px 1.971px 0px rgba(0, 0, 0, 0.06)"
      >
        <Box borderTopRadius="16px" background="#F2F6FF" px={{ md: "24px", base: "13px" }} py={{ md: "15px", base: "18px" }}>
          <Text color="#000" fontSize="16px" fontWeight={700} fontFamily="Poppins" lineHeight="23px" pb="10px">
            Complete fee payment by
          </Text>
          <Box background="#D6E2FA" maxW="170px" color="#3470E4" fontSize="12px" fontWeight={500} borderRadius="16px" px="10px" py="2px">
            Deadline - 29th November
          </Box>
        </Box>

        <Box
          pb="20px"
          // boxShadow="0px 0.986px 2.957px 0px rgba(0, 0, 0, 0.10), 0px 0.986px 1.971px 0px rgba(0, 0, 0, 0.06)"
        >
          <RadioGroup onChange={setSelectedOption} value={selectedOption}>
            <Stack p={5} display="flex" flexDirection="row" gap="20px">
              {paymentOptions.map((option) => (
                <Box
                  key={option.value}
                  border={selectedOption === option.value ? "1px solid #3470E4" : "1px solid #E5E5E5"}
                  mt={option.value === "Pay full amount" ? "0.5rem" : "0px"}
                  borderRadius="12px"
                  px={{ md: "16px", base: "12px" }}
                  py={{ md: "16px", base: "9px" }}
                  flex="1"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Radio value={option.value} fontSize={{ md: "16px", base: "12px" }} color="#21191B" fontWeight={600}>
                    {option.label}
                  </Radio>
                </Box>
              ))}
            </Stack>
          </RadioGroup>

          {selectedOption === "Pay full amount" && (
            //   <Box>
            <Box maxW={{ md: "600px", base: "300" }} color="#fff" borderRadius="12px" bg="#3B3435" mx="auto" px={{ md: "24px", base: "13px" }} py={{ md: "15px", base: "18px" }}>
              <Text fontSize="16px" fontWeight={600} fontFamily="Open Sans">
                Remaining Course Fee
              </Text>
              <Text fontFamily="Open Sans" fontWeight={700} fontSize="33px">
                ₹{courseFeeAmount}
                /-
              </Text>
              {/* </Box> */}
            </Box>
          )}

          {selectedOption === "Pay in EMIs" && <PayInEmi amount={courseFeeAmount * 1.2} state={payInEMIForm} setState={setPayInEMIForm} />}
        </Box>
      </Box>

      {selectedOption === "Pay in EMIs" ? (
        <Box mt="24px" display="flex">
          <FormControl display="flex" gap="20px" alignItems="flex-start">
            <Checkbox
              name="consent"
              isChecked={isConsentGiven}
              onChange={() => {
                setIsConsentGiven(!isConsentGiven);
              }}
              colorScheme="blue"
              mt="4px"
            ></Checkbox>
            <Text fontSize="14px" fontWeight="400">
              Yes, I give consent to lending partner to review my/co-applicant credit information report with Credit Information Companies such as TransUnion CIBIL
            </Text>
          </FormControl>
        </Box>
      ) : null}
      <Box maxW={{ md: "637px", base: "345px" }} w="full">
        <Button
          isLoading={isLoadingEMIPayment || isLoadingIITEntranceExamStatus}
          disabled={isLoadingEMIPayment || isLoadingIITEntranceExamStatus || (selectedOption === "Pay in EMIs" && !isConsentGiven)}
          onClick={() => {
            if (selectedOption === "Pay full amount") {
              startRazorPay(course);
            } else {
              emiPaymentMutate({ course_id: course, emiOption: payInEMIForm.emiOption, monthlySalary: payInEMIForm.monthlySalary, currentStatus: payInEMIForm.currentStatus });
            }
          }}
          variant="primary"
          w="full"
          color="#fff"
        >
          {selectedOption === "Pay full amount" ? "Pay fee now" : "SUBMIT"}
        </Button>
      </Box>
    </Box>
  );
};

export default FeePayment;
