import axios from "axios";
import { useEffect, useState } from "react";
import { Box, Input, Text } from "@chakra-ui/react";
import CustomUploadFileInput from "./CustomUploadFileInput";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import { GetS3PresignedUrlType, ProfileType } from "../../../api/schemas/schema";
import { IITGuwahatiProfileDetailInputInterface } from "../../../constants/IIt-course/iit-form-onboarding";

interface Props {
  isDuplicateNumber?: boolean;
  selfPaidEMIUser?: boolean;
  profileDetailForm: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm: (data: IITGuwahatiProfileDetailInputInterface) => void;
  hasUserPaidSemesterFees: boolean;
  isMobileInputInvalid: boolean;
  setIsMobileInputInvalid: (arg: boolean) => void;
  isAlternativeMobileInputInvalid: boolean;
  setIsAlternativeMobileInputInvalid: (arg: boolean) => void;
  profile: ProfileType;
}

const ProfileDetailDirectFlowForm = ({
  selfPaidEMIUser = false,
  isDuplicateNumber,
  setProfileDetailForm,
  profileDetailForm,
  profile,
  hasUserPaidSemesterFees,
  isMobileInputInvalid,
  setIsMobileInputInvalid,
  isAlternativeMobileInputInvalid,
  setIsAlternativeMobileInputInvalid,
}: Props) => {
  const pattern = new RegExp(/^\d{10}$/);
  const [bankStatement, setBankStatement] = useState<File>();
  const [isSelfEmployed, setIsSelfEmployed] = useState<boolean>(false);
  const [isAadhaarVerified, setIsAadhaarVerified] = useState<boolean>(false);
  const isIITRopar = profile?.program_profile_type === ProgramProfileType.IIT_ROPAR;
  const isIITMandiV2 = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_V2;
  const isIITMandiES = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_ES;
  const isIITMandiBA = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_BA;

  const handleChange = (keyName: string, value: string) => {
    setProfileDetailForm({ ...profileDetailForm, [keyName]: value });
  };

  const handleOnSuccess = async (presignedUrl: string, imageFile: File): Promise<string> => {
    const imageData = imageFile;
    const fileType = imageData as File;
    try {
      await axios.put(presignedUrl, imageData, {
        headers: {
          "Content-Type": fileType.type || "image/png",
        },
      });
      const imageUrl = presignedUrl.split("?")[0];
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  useEffect(() => {
    if (profile && profile?.meta) {
      setIsSelfEmployed(
        isIITRopar
          ? profile?.meta?.employmentTypeForRopar === "self_employed"
          : isIITMandiV2
          ? profile?.meta?.employmentTypeForMandiV2 === "self_employed"
          : isIITMandiES
          ? profile?.meta?.employmentTypeForMandiES === "self_employed"
          : isIITMandiBA
          ? profile?.meta?.employmentTypeForMandiBA === "self_employed"
          : profile?.meta?.employmentType === "self_employed"
      );
      setIsAadhaarVerified(!!(profile?.aadhaar_verified && profile?.meta?.aadharFront && profile?.meta?.aadharBack));
    }
  }, [profile]);

  return (
    <Box mt={5} display={"flex"} flexDir={"column"} gap={5}>
      {selfPaidEMIUser ? (
        <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
          <Box maxW={"500px"}>
            <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={1}>
              {isAadhaarVerified ? 1 : 3}. Upload your last 12 months’ bank statements{" "}
              <Text fontSize={"11px"} color={"gray.600"}>
                (Optional)
              </Text>
            </Text>
            <CustomUploadFileInput
              disabled={false}
              toastMessage="Bank Statement uploaded successfully"
              acceptedFileTypes={["PDF"]}
              fileUploadData={bankStatement as File}
              setFileUploadData={setBankStatement}
              handleDelete={() => {
                if (isIITRopar) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForRopar: undefined });
                } else if (isIITMandiV2) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiV2: undefined });
                } else if (isIITMandiES) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiES: undefined });
                } else if (isIITMandiBA) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiBA: undefined });
                } else {
                  setProfileDetailForm({ ...profileDetailForm, bankStatement: undefined });
                }
              }}
              handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
                const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_pan_back, bankStatement as File);
                if (isIITRopar) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForRopar: imageUrl });
                } else if (isIITMandiV2) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiV2: imageUrl });
                } else if (isIITMandiES) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiES: imageUrl });
                } else if (isIITMandiBA) {
                  setProfileDetailForm({ ...profileDetailForm, bankStatementForMandiBA: imageUrl });
                } else {
                  setProfileDetailForm({ ...profileDetailForm, bankStatement: imageUrl });
                }
              }}
            />
          </Box>
          <Box maxW={"490px"} w={"full"}>
            <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={1}>
              {isAadhaarVerified ? 2 : 4}. If your bank statement is Password protected, please share the password. (Write NA if not applicable){" "}
              <Text fontSize={"11px"} color={"gray.600"}>
                (Optional)
              </Text>
            </Text>
            <Box mt={2}>
              <Input
                type="text"
                value={
                  isIITRopar
                    ? profileDetailForm?.bankStatementPasswordForRopar || ""
                    : isIITMandiV2
                    ? profileDetailForm?.bankStatementPasswordForMandiV2 || ""
                    : isIITMandiES
                    ? profileDetailForm?.bankStatementPasswordForMandiES || ""
                    : isIITMandiBA
                    ? profileDetailForm?.bankStatementPasswordForMandiBA || ""
                    : profileDetailForm?.bankStatementPassword || ""
                }
                name="bank-statement-password"
                color={"blackAlpha.900"}
                bg={"whiteAlpha.700"}
                size="lg"
                onChange={(data) =>
                  handleChange(
                    isIITRopar
                      ? "bankStatementPasswordForRopar"
                      : isIITMandiV2
                      ? "bankStatementPasswordForMandiV2"
                      : isIITMandiES
                      ? "bankStatementPasswordForMandiES"
                      : isIITMandiBA
                      ? "bankStatementPasswordForMandiBA"
                      : "bankStatementPassword",
                    data.target.value.trim()
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      ) : null}

      {!hasUserPaidSemesterFees ? (
        <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
          {/* Business name / Company name */}
          <Box maxW={"490px"} w={"full"}>
            <Text textStyle={"body2-md"} color={"black"}>
              {isAadhaarVerified ? 3 : 5}. Your {isSelfEmployed ? "Business" : "Company"} name*
            </Text>
            <Box>
              <Input
                type="text"
                name="business-name"
                value={
                  isIITRopar
                    ? isSelfEmployed
                      ? profileDetailForm?.businessNameForRopar || ""
                      : profileDetailForm?.companyNameForRopar || ""
                    : isIITMandiV2
                    ? isSelfEmployed
                      ? profileDetailForm?.businessNameForMandiV2 || ""
                      : profileDetailForm?.companyNameForMandiV2 || ""
                    : isIITMandiES
                    ? isSelfEmployed
                      ? profileDetailForm?.businessNameForMandiES || ""
                      : profileDetailForm?.companyNameForMandiES || ""
                    : isIITMandiBA
                    ? isSelfEmployed
                      ? profileDetailForm?.businessNameForMandiBA || ""
                      : profileDetailForm?.companyNameForMandiBA || ""
                    : isSelfEmployed
                    ? profileDetailForm?.businessName || ""
                    : profileDetailForm?.companyName || ""
                }
                color={"blackAlpha.900"}
                bg={"whiteAlpha.700"}
                size="lg"
                onChange={(data) =>
                  handleChange(
                    isIITRopar
                      ? isSelfEmployed
                        ? "businessNameForRopar"
                        : "companyNameForRopar"
                      : isIITMandiV2
                      ? isSelfEmployed
                        ? "businessNameForMandiV2"
                        : "companyNameForMandiV2"
                      : isIITMandiES
                      ? isSelfEmployed
                        ? "businessNameForMandiES"
                        : "companyNameForMandiES"
                      : isIITMandiBA
                      ? isSelfEmployed
                        ? "businessNameForMandiBA"
                        : "companyNameForMandiBA"
                      : isSelfEmployed
                      ? "businessName"
                      : "companyName",
                    data.target.value.trim()
                  )
                }
              />
            </Box>
          </Box>

          {/* Monthly Income / Salary */}
          <Box maxW={"490px"} w={"full"}>
            <Text textStyle={"body2-md"} color={"black"}>
              {isAadhaarVerified ? 4 : 6}. Your Monthly {isSelfEmployed ? "Income" : "Salary"}*
            </Text>
            <Box>
              <Input
                type="number"
                name="parent-name"
                color={"blackAlpha.900"}
                value={
                  isIITRopar
                    ? isSelfEmployed
                      ? profileDetailForm?.monthlyBusinessIncomeForRopar || ""
                      : profileDetailForm?.monthlySalaryForRopar || ""
                    : isIITMandiV2
                    ? isSelfEmployed
                      ? profileDetailForm?.monthlyBusinessIncomeForMandiV2 || ""
                      : profileDetailForm?.monthlySalaryForMandiV2 || ""
                    : isIITMandiES
                    ? isSelfEmployed
                      ? profileDetailForm?.monthlyBusinessIncomeForMandiES || ""
                      : profileDetailForm?.monthlySalaryForMandiES || ""
                    : isIITMandiBA
                    ? isSelfEmployed
                      ? profileDetailForm?.monthlyBusinessIncomeForMandiBA || ""
                      : profileDetailForm?.monthlySalaryForMandiBA || ""
                    : isSelfEmployed
                    ? profileDetailForm?.monthlyBusinessIncome || ""
                    : profileDetailForm?.monthlySalary || ""
                }
                bg={"whiteAlpha.700"}
                size="lg"
                onChange={(data) =>
                  handleChange(
                    isIITRopar
                      ? isSelfEmployed
                        ? "monthlyBusinessIncomeForRopar"
                        : "monthlySalaryForRopar"
                      : isIITMandiV2
                      ? isSelfEmployed
                        ? "monthlyBusinessIncomeForMandiV2"
                        : "monthlySalaryForMandiV2"
                      : isIITMandiES
                      ? isSelfEmployed
                        ? "monthlyBusinessIncomeForMandiES"
                        : "monthlySalaryForMandiES"
                      : isIITMandiBA
                      ? isSelfEmployed
                        ? "monthlyBusinessIncomeForMandiBA"
                        : "monthlySalaryForMandiBA"
                      : isSelfEmployed
                      ? "monthlyBusinessIncome"
                      : "monthlySalary",
                    data.target.value.trim()
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* ALternative Phone Number */}
        <Box maxW={"490px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 5 : 7}. Your Alternate Phone Number*
          </Text>
          <Box>
            <Input
              isInvalid={isAlternativeMobileInputInvalid}
              type="number"
              name="alternative-phone-number"
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              value={
                isIITRopar
                  ? profileDetailForm?.alternativePhoneNumberForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.alternativePhoneNumberForMandiV2 || ""
                  : isIITMandiES
                  ? profileDetailForm?.alternativePhoneNumberForMandiES || ""
                  : isIITMandiBA
                  ? profileDetailForm?.alternativePhoneNumberForMandiBA || ""
                  : profileDetailForm?.alternativePhoneNumber || ""
              }
              size="lg"
              onChange={(data) => {
                if (!pattern.test(data.target.value)) {
                  setIsAlternativeMobileInputInvalid(true);
                } else {
                  setIsAlternativeMobileInputInvalid(false);
                }
                handleChange(
                  isIITRopar
                    ? "alternativePhoneNumberForRopar"
                    : isIITMandiV2
                    ? "alternativePhoneNumberForMandiV2"
                    : isIITMandiES
                    ? "alternativePhoneNumberForMandiES"
                    : isIITMandiBA
                    ? "alternativePhoneNumberForMandiBA"
                    : "alternativePhoneNumber",
                  data.target.value.trim()
                );
              }}
            />
            {(isAlternativeMobileInputInvalid || isDuplicateNumber) && (
              <Text color="ms-error" textStyle="body2">
                {isDuplicateNumber ? "Duplicate Phone Number" : "Invalid Mobile Number"}
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      <Text textStyle={"h5"} mb={1} mt={4}>
        Your Parent’s/Guardian’s Info
      </Text>
      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* Parent's email address */}
        <Box w={"full"} maxW={"490px"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 6 : 8}. Parent’s/Guardian’s Email Address (any one)*
          </Text>
          <Box>
            <Input
              type="text"
              name="parent-email-address"
              color={"blackAlpha.900"}
              value={
                isIITRopar
                  ? profileDetailForm?.parentGuardianEmailForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.parentGuardianEmailForMandiV2 || ""
                  : isIITMandiES
                  ? profileDetailForm?.parentGuardianEmailForMandiES || ""
                  : isIITMandiBA
                  ? profileDetailForm?.parentGuardianEmailForMandiBA || ""
                  : profileDetailForm?.parentGuardianEmail || ""
              }
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) =>
                handleChange(
                  isIITRopar
                    ? "parentGuardianEmailForRopar"
                    : isIITMandiV2
                    ? "parentGuardianEmailForMandiV2"
                    : isIITMandiES
                    ? "parentGuardianEmailForMandiES"
                    : isIITMandiBA
                    ? "parentGuardianEmailForMandiBA"
                    : "parentGuardianEmail",
                  data.target.value.trim()
                )
              }
            />
          </Box>
        </Box>

        {/* Parent phone number */}
        <Box w={"full"} maxW={"490px"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 7 : 9}. Parent’s/Guardian’s Phone Number (any one)*
          </Text>
          <Box>
            <Input
              isInvalid={isMobileInputInvalid}
              type="number"
              name="parent-phone-number"
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              value={
                isIITRopar
                  ? profileDetailForm?.parentGuardianPhoneNumberForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.parentGuardianPhoneNumberForMandiV2 || ""
                  : isIITMandiES
                  ? profileDetailForm?.parentGuardianPhoneNumberForMandiES || ""
                  : isIITMandiBA
                  ? profileDetailForm?.parentGuardianPhoneNumberForMandiBA || ""
                  : profileDetailForm?.parentGuardianPhoneNumber || ""
              }
              size="lg"
              onChange={(data) => {
                if (!pattern.test(data.target.value)) {
                  setIsMobileInputInvalid(true);
                } else {
                  setIsMobileInputInvalid(false);
                }
                handleChange(
                  isIITRopar
                    ? "parentGuardianPhoneNumberForRopar"
                    : isIITMandiV2
                    ? "parentGuardianPhoneNumberForMandiV2"
                    : isIITMandiES
                    ? "parentGuardianPhoneNumberForMandiES"
                    : isIITMandiBA
                    ? "parentGuardianPhoneNumberForMandiBA"
                    : "parentGuardianPhoneNumber",
                  data.target.value.trim()
                );
              }}
            />
            {(isMobileInputInvalid || isDuplicateNumber) && (
              <Text color="ms-error" textStyle="body2">
                {isDuplicateNumber ? "Duplicate Phone Number" : "Invalid Mobile Number"}
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* Parent name */}
        <Box maxW={"490px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 8 : 10}. Parent’s/Guardian’s Name (any one)*
          </Text>
          <Box>
            <Input
              type="text"
              name="parent-name"
              value={
                isIITRopar
                  ? profileDetailForm?.parentGuardianNameForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.parentGuardianNameForMandiV2 || ""
                  : isIITMandiES
                  ? profileDetailForm?.parentGuardianNameForMandiES || ""
                  : isIITMandiBA
                  ? profileDetailForm?.parentGuardianNameForMandiBA || ""
                  : profileDetailForm?.parentGuardianName || ""
              }
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) =>
                handleChange(
                  isIITRopar
                    ? "parentGuardianNameForRopar"
                    : isIITMandiV2
                    ? "parentGuardianNameForMandiV2"
                    : isIITMandiES
                    ? "parentGuardianNameForMandiES"
                    : isIITMandiBA
                    ? "parentGuardianNameForMandiBA"
                    : "parentGuardianName",
                  data.target.value.trim()
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileDetailDirectFlowForm;
