import dayjs from "dayjs";
import { Image, Text } from "@chakra-ui/react";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { PiCertificate, PiChalkboardTeacher, PiSparkle } from "react-icons/pi";
import { LuClock3 } from "react-icons/lu";
import UserCheck from "../../assets/icons/components/UserCheck";

dayjs.extend(advancedFormat);
export const courDataDynamic = (userType: string, newUser?: boolean) => {
  const baseData = {
    IIT_MANDI_BA: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T10:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-18T09:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-10-30T10:00:00" : "2024-10-30T10:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00") : newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-10-28T19:00:00" : "2024-10-28T19:00:00") : newUser ? "2024-10-30T17:00:00" : "2024-10-30T17:00:00",
      mockEnablingTime: newUser ? "2024-11-14T11:00:00" : "2024-10-26T11:00:00",
      mockDisablingTime: newUser ? "2024-11-17T21:00:00" : "2024-10-27T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      yt: "https://youtu.be/O9P9sm0AK4s",
      zoom: "https://us06web.zoom.us/j/88504991222",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_BA_Results_241028.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#52BD94",
      borderColor: "#FDC56A",
      backgroundColor: "#FFF9F0",
      courseFee: 40000,
      darkBackgroundColor: "#FEE8C8",
      coursePayment: 99,
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Business Analytics Program",
      title: "Minor in Business Analytics",
      collegeName: "CCE, IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course1_6549b2bb62.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      rankerListData: {
        borderColor: "#FCA311",
        background: "rgba(252, 163, 17, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88504991222",
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Basic Maths", "Data Interpretation", "Logical Reasoning"],
      },
    },
    IIT_MANDI_ES: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T10:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-18T09:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-10-30T10:00:00" : "2024-10-30T10:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00") : newUser ? "2024-11-03T23:59:00" : "2024-11-03T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-10-28T19:00:00" : "2024-10-28T19:00:00") : newUser ? "2024-10-30T17:00:00" : "2024-10-30T17:00:00",
      mockEnablingTime: newUser ? "2024-11-14T11:00:00" : "2024-10-26T11:00:00",
      mockDisablingTime: newUser ? "2024-11-17T21:00:00" : "2024-10-27T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/88039494513",
      yt: "https://youtu.be/mjKOI69w_Qc",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IIT_Mandi_ES_Results_241028.pdf.pdf",
      seatBookingAmount: 4000,
      collage: "CCE, IIT Mandi",
      selectColor: "#005792",
      borderColor: "#BDF2FF",
      backgroundColor: "#F0FCFF",
      darkBackgroundColor: "#D6F7FF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Minor in Embedded Systems",
      collegeName: "CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for CCE, IIT Mandi's <br/> Minor in Embedded Systems",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course3_df5e3542f3.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Logical Circuits", "Logical Reasoning", "Quantitative Aptitude"],
      },
      rankerListData: {
        borderColor: "#005792",
        background: "rgba(163, 237, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88039494513",
      },
    },
    IIT_GUWAHATI_DS_ML: {
      isCourseOverviewEnabled: false,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-10T10:00:00",
      testDateTimeOver: "2024-11-10T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-11T11:00:00" : "2024-10-28T17:00:00") : newUser ? "2024-11-13T09:00:00" : "2024-10-30T17:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-17T23:59:00" : "2024-11-03T23:59:00") : newUser ? "2024-11-21T23:59:00" : "2024-11-03T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "") : newUser ? "2024-11-11T19:00:00" : "",
      mockEnablingTime: "2024-11-07T11:00:00",
      mockDisablingTime: "2024-11-10T21:00:00",
      // ?  ======== Configurable dates =======
      email: "iitg.daksh.gurukul@masaischool.com",
      zoom: "https://us06web.zoom.us/j/88034220522",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/DakshGurukul_IITGuwahati_Results_241028.pdf",
      seatBookingAmount: 8000,
      collage: "Daksh Gurukul - IIT Guwahati",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#F7F7FF",
      darkBackgroundColor: "#DBDBFF",
      courseFee: 60000,
      coursePayment: 99,
      title: "Credit-Linked Program in Data Science",
      assesmentpageTitle: "Entrance Test for Daksh Gurukul, IIT Guwahati's <br/> Credit-Linked Program in Data Science",
      collegeName: "Daksh Gurukul, IIT Guwahati",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/course2_095b9c5095.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Programming", "Mathematics", "Statistics", "Communication Skills"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
    },
    IIT_ROPAR: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-10T10:00:00",
      testDateTimeOver: "2024-11-10T22:00:00",
      resultDate: userType === "ranker" ? (newUser ? "2024-11-11T09:00:00" : "2024-11-04T11:00:00") : newUser ? "2024-11-13T09:00:00" : "2024-11-06T11:00:00",
      onboardingDate: userType === "ranker" ? (newUser ? "2024-11-12T23:59:00" : "2024-11-08T23:59:00") : newUser ? "2024-11-15T23:59:00" : "2024-11-08T23:59:00",
      courseOverSessionDate: userType === "ranker" ? (newUser ? "2024-11-11T19:00:00" : "2024-11-04T17:30:00") : newUser ? "2024-11-11T19:00:00" : "2024-11-06T19:00:00",
      mockEnablingTime: newUser ? "2024-11-07T11:00:00" : "2024-11-05T11:00:00",
      mockDisablingTime: newUser ? "2024-11-10T21:00:00" : "2024-11-10T21:00:00",
      // ?  ======== Configurable dates =======
      email: "support_aiforall@iitrpr.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Ropar",
      selectColor: "#6E71CC",
      borderColor: "#CCF",
      backgroundColor: "#EBEEFE",
      darkBackgroundColor: "#B5BDFC",
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Artificial Intelligence ( AI )",
      assesmentpageTitle: "Entrance Test for IIT Ropar's <br/> Minor in Artificial Intelligence ( AI )",
      collegeName: "IIT Ropar",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/image-1.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Logical Reasoning", "Aptitude", "Basic Math"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
    },
    IIT_MANDI_V2: {
      isCourseOverviewEnabled: true,
      // ?  ======== Configurable dates =======
      slotConfig: {
        fillingFast: ["14:00", "20:00", "16:00", "23:00"],
        disabled: ["13:00"],
      },
      testDate: "2024-11-17T17:00:00",
      testDateTimeOver: "2024-11-17T22:00:00",
      resultDate: userType === "ranker" ? "2024-11-18T09:00:00" : "2024-11-20T09:00:00",
      onboardingDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      courseOverSessionDate: userType === "ranker" ? "2024-11-08T23:59:00" : "2024-11-08T23:59:00",
      mockEnablingTime: "2024-11-14T11:00:00",
      mockDisablingTime: "2024-11-18T21:00:00",
      // ?  ======== Configurable dates =======
      email: "info.cce@iitmandi.ac.in",
      zoom: "https://us06web.zoom.us/j/82792154343",
      yt: "https://youtu.be/KZKNfEV6CYI",
      userList: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT+Ropar+Minor+in+AI+Entrance+Exam+Result.pdf",
      seatBookingAmount: 4000,
      collage: "IIT Mandi",
      selectColor: "#52BD94", // Updated to match IIT_MANDI
      borderColor: "#FDC56A", // Updated to match IIT_MANDI
      backgroundColor: "#FFF9F0", // Updated to match IIT_MANDI
      darkBackgroundColor: "#FEE8C8", // Updated to match IIT_MANDI
      courseFee: 40000,
      coursePayment: 99,
      title: "Minor in Computer Science by CCE, IIT Mandi",
      assesmentpageTitle: "Entrance Test for IIT Mandi's <br/> Minor in Computer Science",
      collegeName: "IIT Mandi",
      logo: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png",
      icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE.png",
      registrationProcess: {
        title: "Registration Process",
        steps: ["Pay ₹99 and choose your slot", "Unlock free mock test and practice", "Get a refund of ₹99 if you don't clear the final entrance test"],
      },
      topicsCovered: {
        title: "Topics Covered",
        points: ["Basic Programming", "CS Fundamentals", "Basic Maths"],
      },
      rankerListData: {
        borderColor: "#6E71CC",
        background: "rgba(219, 219, 255, 0.39)",
        zoomLink: "https://us06web.zoom.us/j/88034220522",
      },
    },
  };
  const modifiedData = {
    IIT_MANDI_BA: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_BA.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_BA.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>5th Nov 2024</strong>
          </>,
          <>
            Platform onboarding by <strong style={{ color: "#3470E4" }}>5th Nov 2024</strong>
          </>,
          <>
            Course orientation on <strong style={{ color: "#3470E4" }}>15th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Batch start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IITMandi_BA_Brochure_241028.pdf",
      },
    },
    IIT_MANDI_ES: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_ES.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {/* {TestTakingDate.format("MMMM DD")} */}
              {dayjs(baseData.IIT_MANDI_ES.testDate).format("MMMM DD")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_ES.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{dayjs(baseData.IIT_MANDI_ES.onboardingDate).format("Do MMM, hh:mmA")}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>5th Nov 2024</strong>
          </>,
          <>
            Platform onboarding by <strong style={{ color: "#3470E4" }}>5th Nov 2024</strong>
          </>,
          <>
            Course orientation on <strong style={{ color: "#3470E4" }}>15th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>29th Nov 2024</strong>
          </>,
          <>
            Offline orientation in <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Batch start on <strong style={{ color: "#3470E4" }}>20th Jan, 2025</strong>
          </>,
        ],
      },
      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "16 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-iit-for-all.s3.ap-south-1.amazonaws.com/brochures/CCE_IITMandi_ES_Brochure_241028.pdf",
      },
    },
    IIT_GUWAHATI_DS_ML: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_GUWAHATI_DS_ML.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_GUWAHATI_DS_ML.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_GUWAHATI_DS_ML.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>19th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>22th Nov 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>29th Nov, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>22nd Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 2-week campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/DS_IITGuwahati_Brochure.pdf",
      },
    },
    IIT_ROPAR: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="47.6px" src={baseData.IIT_ROPAR.logo} display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_ROPAR.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_ROPAR.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_ROPAR.testDate).format("dddd")}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },
      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>19th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>22th Nov 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>29th Nov, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>22nd Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "2 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/AI_IITRopar_Brochure_Entrance_Test.pdf",
      },
    },
    IIT_MANDI_V2: {
      subtitle: (
        <Text display="flex" alignItems="center" gap="1">
          <Text as="span" fontSize="xs" color="#848081" fontWeight="400">
            from
          </Text>{" "}
          <Image maxW="67.852px" src="https://masai-website-images.s3.ap-south-1.amazonaws.com/iit_logo_631e942a4f.png" display="inline" alt="test" />{" "}
          <Text as="span" fontWeight="600" fontSize="14px" color="#21191B">
            {baseData.IIT_MANDI_V2.collage}
          </Text>
        </Text>
      ),
      thingsToRemember: {
        title: "Things to Remember",
        points: [
          <>
            The duration of the test is <strong>60 minutes</strong>
          </>,
          <>
            You can take the test <strong>only once</strong> on{" "}
            <Text as="strong" color="#3470E4">
              {dayjs(baseData.IIT_MANDI_BA.testDate).format("DD MMMM")}
            </Text>
            , {dayjs(baseData.IIT_MANDI_BA.testDate).format("dddd")} {/*TestTakingDate.format("dddd")*/}
          </>,
          <>
            The test can <strong>only</strong> be taken <strong>on desktop/laptop</strong> on Google Chrome browser
          </>,
        ],
      },

      importantDates: {
        title: "Important Dates",
        points: [
          <>
            Secure your spot by
            <strong style={{ color: "#3470E4", marginLeft: "5px" }}>{"13th Nov, 11:59 PM"}</strong>
          </>,
          <>
            Onboarding formalities by <strong style={{ color: "#3470E4" }}>19th Nov 2024</strong>
          </>,
          <>
            Inaugural session on <strong style={{ color: "#3470E4" }}>22th Nov 2024</strong>
          </>,
          <>
            Foundation classes start <strong style={{ color: "#3470E4" }}>29th Nov, 2024</strong>
          </>,
          <>
            Offline orientation <strong style={{ color: "#3470E4" }}>Dec, 2024</strong>
          </>,
          <>
            Main classes start on <strong style={{ color: "#3470E4" }}>10th Jan, 2025</strong>
          </>,
        ],
      },

      courseOfferings: {
        title: "Course Offerings:",
        points: [
          { icon: PiCertificate, text: "15 program credits" },
          { icon: LuClock3, text: "09 months online program" },
          { icon: PiSparkle, text: "3 terms & 5-days campus training" },
          { icon: PiChalkboardTeacher, text: "Curriculum designed by IIT Professors" },
          { icon: UserCheck, text: "Eligibility: 12th Pass + High School Math" },
        ],
        brochure: "https://masai-website-images.s3.ap-south-1.amazonaws.com/CSE_IITMandixMasai_Brochure_V6+(1)_compressed.pdf",
      },
    },
  };
  return {
    IIT_MANDI_BA: { ...baseData.IIT_MANDI_BA, ...modifiedData.IIT_MANDI_BA },
    IIT_MANDI_ES: { ...baseData.IIT_MANDI_ES, ...modifiedData.IIT_MANDI_ES },
    IIT_GUWAHATI_DS_ML: { ...baseData.IIT_GUWAHATI_DS_ML, ...modifiedData.IIT_GUWAHATI_DS_ML },
    IIT_ROPAR: { ...baseData.IIT_ROPAR, ...modifiedData.IIT_ROPAR },
    IIT_MANDI_V2: { ...baseData.IIT_MANDI_V2, ...modifiedData.IIT_MANDI_V2 },
  };
};
