import React from "react";
import { Box, Image, Heading, Input, FormControl, FormLabel, FormErrorMessage, Flex, Radio, Text, Select, Checkbox, Button } from "@chakra-ui/react";
import { OnboardingGreenCheck } from "../../assets/images";
import slack from "../../assets/icons/slack.svg";
import zoom from "../../assets/icons/zoom.svg";
const ComletOnboardingTab = ({ refetchIITOnboardingStatus }: { refetchIITOnboardingStatus: () => void }) => {
  return (
    <>
      <Flex align="center" gap="10px" mt="60px">
        <Image width={"32px"} height={"32px"} objectFit={"contain"} src={OnboardingGreenCheck} />

        <Heading as="h6" mt={{ base: "0px" }}>
          Onboarding Complete
        </Heading>
      </Flex>
      <Text mt="24px">Our team will reach out to you soon to assist with each step:</Text>
      <Box mt="20px">
        <Text>1. Slack Onboarding</Text>
      </Box>
      <Box mt="10px">
        <Text>2. Course Platform Setup</Text>
      </Box>
      <Box mt="10px">
        <Text>3. Zoom Nolan Authentication</Text>
      </Box>
      <Box mt="10px">
        <Text>4. Orientation Event</Text>
      </Box>
      {/* <Flex w="full" mt="30px" gap="10px">
        <Box display="flex" flexDirection="column" alignItems="center" gap="10px" border="1px solid #D9D9D9" p="24px" width="50%" borderRadius="14px">
          <Image width={"30px"} height={"30px"} objectFit={"contain"} src={slack} alt="Slack Logo" />
          <Button color="#3470E4" maxW={"180px"} height={"35px"}>
            Join Slack
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="10px" border="1px solid #D9D9D9" p="24px" width="50%" borderRadius="14px">
          <Image width={"30px"} height={"30px"} objectFit={"contain"} src={zoom} alt="Zoom Logo" />
          <Button color="#3470E4" maxW={"180px"} height={"35px"}>
            Join Orientation
          </Button>
        </Box>
      </Flex> */}
    </>
  );
};

export default ComletOnboardingTab;
