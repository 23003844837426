import React, { useContext } from "react";
import { Box, Text } from "@chakra-ui/react";
import { AppContext } from "../../../../context/ApplicationContext";

interface PaymentNoteProps {
  courseStartDate: string;
  isIITRopar?: boolean;
}

const PaymentNote: React.FC<PaymentNoteProps> = ({ courseStartDate, isIITRopar = false }) => {
  const [appState] = useContext(AppContext);
  return (
    <Box
      p={2}
      mt={5}
      fontSize="sm"
      display={isIITRopar && !appState.couponCode ? "none" : "block"}
      fontStyle="italic"
      lineHeight="6"
      bg="#FFF3CC"
      borderRadius="md"
      border="1px"
      borderColor="amber.200"
      color="stone.900"
      maxW="full"
    >
      {isIITRopar && appState?.couponCode ? (
        <>
          <Text as="span" fontStyle="italic">
            Note: Congratulations! This code will help you unlock the special discounted price if you complete your payment by today.
          </Text>
        </>
      ) : !isIITRopar ? (
        <>
          <Text as="span" fontStyle="italic">
            Note: First instalment to be paid before course start date{" "}
          </Text>
          <Text as="span" fontStyle="italic" fontWeight="bold">
            {courseStartDate}
          </Text>
          <Text as="span" fontStyle="italic">
            {" "}
            . Our NBFC team will connect for further instalments.
          </Text>
        </>
      ) : null}
    </Box>
  );
};

export default PaymentNote;
