import React, { useEffect } from "react";
import { Box, Flex, Heading, Image, Skeleton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { OnboardingGreenCheck } from "../../assets/images";
import OnboardingForm from "./OnboardingForm";
import ComletOnboardingTab from "./ComletOnboardingTab";
import FeePayment from "../EntranceExam/FeePayment /FeePayment";
import { useQuery } from "react-query";
import { GetIITEntranceOnboardingStatus } from "../../api/utils/api/v2/IITEntranceExam";
import { useLocation } from "react-router-dom";
import { courDataDynamic } from "../EntranceExam/constents";
const LeftContaint = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([
    { stepName: "Complete Form", isStepCompleted: false, isCurrentStep: true },
    { stepName: "Pay Course fee", isStepCompleted: false, isCurrentStep: false },
    { stepName: "Complete Onboarding", isStepCompleted: false, isCurrentStep: false },
  ]);

  const { data: IITEntranceOnboardingStatusData, isLoading: isLoadingIITEntranceOnboardingStatus, refetch: refetchIITOnboardingStatus } = useQuery({
    queryKey: ["getIITEntranceOnboardingStatus"],
    queryFn: async () => await GetIITEntranceOnboardingStatus(course),
    staleTime: 60000,
    retry: 30000,
    enabled: true,

    onSuccess(data) {
      setActiveStep(data.step);
    },
  });

  useEffect(() => {
    if (activeStep === 0) return;

    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step, index) => ({
        ...step,
        isStepCompleted: index < activeStep,
        isCurrentStep: false,
      }));
      // Find the next uncompleted step and set it as current
      const nextUncompletedIndex = updatedSteps.findIndex((step) => !step.isStepCompleted);
      if (nextUncompletedIndex !== -1) {
        updatedSteps[nextUncompletedIndex].isCurrentStep = true;
      }

      return updatedSteps;
    });
  }, [activeStep]);

  if (isLoadingIITEntranceOnboardingStatus) {
    return <Skeleton height="100vh" width="100%" />;
  }
  return (
    <Box sx={{ flex: 1 }} px="4">
      <Box sx={{ maxW: { base: "100%", lg: "685px" }, mx: "auto" }}>
        <Heading as="h3" size="base" mt={{ base: "24px", md: "30px" }}>
          Onboarding
        </Heading>

        <Box sx={{ maxWidth: "700px" }}>
          <Flex pt={"18px"} w="full">
            {steps.map((step, index) => (
              <Flex flex="1" key={index + step.stepName} maxW={260} gap={{ base: 2, lg: 2 }} flexDir={"column"} minH={"89px"}>
                <Flex>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    rounded={"full"}
                    px={step.isStepCompleted ? "0px" : "12px"}
                    w={"32px"}
                    h={"32px"}
                    bg={step.isCurrentStep ? "#3470E4" : "white"}
                    color={step.isCurrentStep ? "white" : "black"}
                    fontSize={"14px"}
                    flexShrink={0}
                    border={"1px solid #848081"}
                    fontWeight={600}
                  >
                    {step.isStepCompleted ? <Image width={"32px"} height={"32px"} objectFit={"contain"} src={OnboardingGreenCheck} /> : index + 1}
                  </Box>
                  <Box w={"full"} h={"1px"} my="auto" borderTop="1px dashed #6C6768" display={index === steps.length - 1 ? "none" : "block"}></Box>
                </Flex>
                <Text color={step.isCurrentStep ? "#3470E4" : "#544D4F"} fontFamily={"inter"} fontWeight={600} fontSize={16} maxW={{ base: "min-content", md: "100%" }}>
                  {step.stepName}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
        {activeStep === 0 && <OnboardingForm refetchIITOnboardingStatus={refetchIITOnboardingStatus} />}
        {activeStep === 1 && <FeePayment seatBookingAmount={IITEntranceOnboardingStatusData?.seatBookingAmount || 4000} refetchIITOnboardingStatus={refetchIITOnboardingStatus} />}
        {activeStep === 2 && <ComletOnboardingTab refetchIITOnboardingStatus={refetchIITOnboardingStatus} />}
      </Box>
    </Box>
  );
};

export default LeftContaint;
