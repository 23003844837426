import { Box, Button, Heading, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { createNewBatch } from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { Select } from "@chakra-ui/react";
import { ActiveBatchList } from "../../../../constants/Program-list";
import { CreateBatchDetailsType, CreateBatchType } from "../../../../api/schemas/schema";
import get from "lodash.get";

const CreateBatch = () => {
  const snackbar = useSnackBar();
  const [slackWaLink, setSlackWaLink] = useState("");
  const [slackToken, setSlackToken] = useState("");
  const [batchType, setBatchType] = useState<CreateBatchType>("SD");
  const [applicationStartDate, setApplicationStartDate] = useState("");
  const [applicationEndDate, setApplicationEndDate] = useState("");
  const [batchStartDate, setBatchStartDate] = useState("");
  const [batchName, setBatchName] = useState("");
  const [batchCampus, setBatchCampus] = useState("");

  const { mutate: createNewBatchFn, isLoading: isUpdatingBatch } = useMutation(createNewBatch, {
    onSuccess: (data) => {
      setBatchCampus(data.batch_campus_id?.toString() || "");
      snackbar.success(data.message);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        const message = get(e, ["detail"], "Something went wrong");
        snackbar.error(message);
      }
    },
  });

  const courseId = batchType === "SD" ? 12 : 14;
  const handleBatchCreate = () => {
    const payload = {
      course_id: courseId,
      batch_type: batchType,
      start_date: batchStartDate,
      application_start_date: applicationStartDate,
      application_end_date: applicationEndDate,
      slack_wa_link: slackWaLink,
      batch_name: batchName,
      slack_token: slackToken,
    } as CreateBatchDetailsType;
    createNewBatchFn(payload);
  };

  const shouldDisableCreateBtn = (courseId === 14 && !slackToken) || !slackWaLink || !batchType || !applicationStartDate || !applicationEndDate || !batchStartDate || !batchName;
  return (
    <Box display={"flex"} flexDirection={"column"} gap="10px" margin="10px">
      <Heading as="h3">Create New Batch</Heading>
      {batchCampus && (
        <Text as="h1" mt="4" color={"red"} fontWeight={"bold"}>
          {" "}
          New Batch Campus Id : {batchCampus}
        </Text>
      )}
      <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select Batch Name*
          </label>
          <Select
            placeholder={"Select Batch Name"}
            onChange={(event) => {
              setBatchType(event.target.value as CreateBatchType);
            }}
          >
            {ActiveBatchList.filter((item) => item.value != "EMPOWER_HER").map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <label htmlFor="slackWaLink" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter Whatsapp/Slack Link*
          </label>
          <Input name="slackWaLink" type="text" placeholder="Enter whatsapp/slack link" value={slackWaLink} onChange={(e) => setSlackWaLink(e.target.value.trim())} />

          <label htmlFor="slackToken" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter Slack Token(Only For DA)*
          </label>
          <Input name="slackToken" type="text" placeholder="Enter slack token" value={slackToken} onChange={(e) => setSlackToken(e.target.value.trim())} />

          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter Batch Name(E.g FT-WEB-40)*
          </label>
          <Input name="batchName" type="text" placeholder="Name of the batch eg. FT-WEB-40" value={batchName} onChange={(e) => setBatchName(e.target.value.trim())} />

          <Box>
            <label htmlFor="batchStartDate" style={{ fontWeight: "bold", fontSize: "14px" }}>
              Enter batch start date time*
            </label>
            <Input name="batchStartDate" type="datetime-local" placeholder="Enter batch start date time" value={batchStartDate} onChange={(e) => setBatchStartDate(e.target.value.trim())} />
          </Box>
          <Box>
            <label htmlFor="applicationStartDate" style={{ fontWeight: "bold", fontSize: "14px" }}>
              Enter application start date time*
            </label>
            <Input
              name="applicationStartDate"
              type="datetime-local"
              placeholder="Enter application start date time"
              value={applicationStartDate}
              onChange={(e) => setApplicationStartDate(e.target.value.trim())}
            />
          </Box>
          <Box>
            <label htmlFor="applicationEndDate" style={{ fontWeight: "bold", fontSize: "14px" }}>
              Enter application end date time*
            </label>
            <Input
              name="applicationEndDate"
              type="datetime-local"
              placeholder="Enter application end date tine"
              value={applicationEndDate}
              onChange={(e) => setApplicationEndDate(e.target.value.trim())}
            />
          </Box>
        </Box>

        <Button
          isLoading={isUpdatingBatch}
          isDisabled={shouldDisableCreateBtn || isUpdatingBatch}
          width={"130px"}
          variant="primary"
          onClick={() => {
            if (window.confirm(`Are you sure you want to create new batch?\n\nThis action cannot be undone, so please cross-check the data before proceeding.`)) {
              handleBatchCreate();
            }
          }}
          ml="auto"
        >
          Create Batch
        </Button>
      </Box>
    </Box>
  );
};

export default CreateBatch;
