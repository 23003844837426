/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Papa from "papaparse";
import { PaymentCsvDataType } from "./BulkUploadPayments";
import { Box, Button } from "@chakra-ui/react";

const CSVFileUpload = ({ setCsvData }: { setCsvData: React.Dispatch<React.SetStateAction<PaymentCsvDataType[]>> }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      parseCSVFile(file);
    }
  };

  
  const parseCSVFile = (file: File) => {
    setLoading(true); // Start loading
    Papa.parse(file, {
      header: true, // Assumes the first row of the CSV file contains headers
      dynamicTyping: true, // Automatically convert numeric values
      skipEmptyLines: true, // Skip empty lines in the CSV file
      complete: (result: any) => {
        if (result.data.length > 0) {
          const parsedData: PaymentCsvDataType[] = result.data;
          setCsvData(parsedData);

          setError(null);
        } else {
          setCsvData([]);
          setError("CSV file is empty.");
        }
        setLoading(false); // Stop loading
      },
      error: (error: any) => {
        console.error(error);
        setCsvData([]);
        setError("Error parsing the CSV file. Please check the format.");
        setLoading(false); // Stop loading on error
      },
    });
  };

  return (
    <Box margin={"10px"}>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* Link to download the sample CSV */}
      <a href="/sample.csv" download="sample.csv" style={{ color: "blue" }}>
        <Button> Download Sample CSV</Button>
      </a>
    </Box>
  );
};

export default CSVFileUpload;
