import { Box, Button, Heading, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { createExternalPayment } from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { Select } from "@chakra-ui/react";
import { CourseSelected, PaymentType } from "../../../../enums/ProgramType.enum";
import { OfferedCourseList, PaymentTypeList } from "../../../../constants/Program-list";
import { CreatePaymentAdminType } from "../../../../api/schemas/schema";
import get from "lodash.get";
import BulkUploadPayments from "./BulkUploadPayments";

const CreateExternalPayment = () => {
  const snackbar = useSnackBar();
  const [email, setEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [paymentCreatedAt, setPaymentCreatedAt] = useState("");
  const [courseSelected, setCourseSelected] = useState<CourseSelected>();
  const [paymentType, setPaymentType] = useState<PaymentType>();

  const { mutate: createExternalPaymentFn, isLoading: isCreatingExternalPayment } = useMutation(createExternalPayment, {
    onSuccess: (data) => {
      snackbar.success(data.message);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        const message = get(e, ["detail"], "Something went wrong");
        snackbar.error(message);
      }
    },
  });

  const handleExternalPaymentCreation = () => {
    const payload = {
      email: email,
      order_id: orderId,
      created_at: paymentCreatedAt,
      course_selected: courseSelected,
      payment_type: paymentType,
      amount_paid: Number(amountPaid),
    } as CreatePaymentAdminType;
    createExternalPaymentFn(payload);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap="10px" margin="10px">
      <Heading as="h3">Create External Payment</Heading>
      <Box width={"fit-content"}>
        <BulkUploadPayments />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user email below *
          </label>
          <Input name="orderId" type="text" placeholder="Enter user email" value={email} onChange={(e) => setEmail(e.target.value.trim())} />
        </Box>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter razorpay/phonepe orderId*
          </label>
          <Input name="email" type="text" placeholder="Enter razorpay orderId" value={orderId} onChange={(e) => setOrderId(e.target.value.trim())} />
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter payment created at*
          </label>
          <Input name="createdAt" type="datetime-local" placeholder="Enter payment created at" value={paymentCreatedAt} onChange={(e) => setPaymentCreatedAt(e.target.value.trim())} />
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select Course Type*
          </label>
          <Select
            placeholder={"Select Course"}
            onChange={(event) => {
              setCourseSelected(event.target.value as CourseSelected);
            }}
          >
            {OfferedCourseList.map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select Payment Type*
          </label>
          <Select
            placeholder={"Select Payment Type"}
            onChange={(event) => {
              setPaymentType(event.target.value as PaymentType);
            }}
          >
            {PaymentTypeList.map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter amount paid*
          </label>
          <Input name="email" type="text" placeholder="Enter razorpay/phonepe orderId" value={amountPaid} onChange={(e) => setAmountPaid(e.target.value.trim())} />
        </Box>

        <Button
          isLoading={isCreatingExternalPayment}
          isDisabled={!email || isCreatingExternalPayment || !orderId || !paymentCreatedAt || !courseSelected || !paymentType || (paymentType === PaymentType.SEMESTER_FEES && Number(amountPaid) <= 0)}
          width={"100px"}
          variant="primary"
          onClick={handleExternalPaymentCreation}
          ml="auto"
        >
          Create
        </Button>
      </Box>
    </Box>
  );
};

export default CreateExternalPayment;
