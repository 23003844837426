import { Box, Image, Heading, useBreakpointValue, Text, Link, Button } from "@chakra-ui/react";
import { MSATPassedVector } from "../../assets/images";
import { Share } from "../../assets/icons/components/SocialMedia/Share";
import useCleverTap, { CleverTapEvents, ModalNames, ScreenNames } from "../../clevertap/useCleverTap";
import { NotFound } from "../../assets/images";
import { useEffect } from "react";
import { useActiveApplication } from "../../api/utils/hooks/useApplication";
interface Props {
  referrerName?: string;
  certificateUrl: string;
  name?: string;
  gtmEvents?: {
    [key: string]: string;
  };
  isIITGuwahatiDashboard?: boolean;
  isIITMandiDashboard?: boolean;
  isIITRoparDashboard?: boolean;
  setIsAdmitCardModalOpen?: (value: boolean) => void;
  isIITMandiV2Dashboard?: boolean;
  isIITMandiESDashboard?: boolean;
}

const ShareModal = ({
  referrerName,
  certificateUrl,
  name,
  gtmEvents,
  isIITGuwahatiDashboard,
  isIITMandiDashboard,
  isIITMandiV2Dashboard,
  isIITMandiESDashboard,
  setIsAdmitCardModalOpen,
  isIITRoparDashboard,
}: Props) => {
  const headingSize = useBreakpointValue<"h5" | "h4">({
    base: "h5",
    md: "h4",
  });
  const clevertap = useCleverTap()[0];
  const { data: activeApplication, refetch } = useActiveApplication();
  const ogUrl = `${process.env.REACT_APP_BASE_URL}/admissions/${activeApplication?.slug}`;
  useEffect((): void => {
    clevertap.fireEvent(CleverTapEvents.viewed_modal, {
      modal_name: ModalNames.msatSuccess,
    });
    refetch();
  }, []);

  const isIITDashboard = isIITGuwahatiDashboard || isIITMandiDashboard || isIITRoparDashboard || isIITMandiV2Dashboard || isIITMandiESDashboard;
  return (
    <>
      <Box borderRadius={"ms-16"} bgImage={MSATPassedVector} bgRepeat={"no-repeat"} pt={{ base: "40px", md: "48px" }} pb={{ base: "8px", md: "16px" }} px={{ base: "24px", md: "54px" }} m={"-24px"}>
        <Box textAlign={"center"}>
          <Heading as={"h5"} color={"ms-blue.500"} mb={"ms-16"}>
            Congrats {name} !
          </Heading>
          <Heading as={headingSize} mb={{ base: "16px", md: "24px" }}>
            {referrerName ? (
              <>
                {" "}
                You have cleared MSAT. <br />
                Thank{" "}
                <Box d="inline" color="ms-blue.500">
                  {" "}
                  {referrerName}
                </Box>{" "}
                for referring you to
                <Box d="inline" color="ms-blue.500">
                  {" "}
                  Masai School.
                </Box>
              </>
            ) : (
              <>
                You have successfully completed <br />
                {isIITDashboard ? "The" : "The Masai School"} Admission Process!
              </>
            )}
          </Heading>
          <Image mx={"auto"} src={certificateUrl ? certificateUrl : NotFound} alt="admit card certificate not found" mb={{ base: "0px", md: "ms-4" }} />

          {certificateUrl ? (
            <Share
              gtmEvents={gtmEvents}
              linkedInUrl={ogUrl}
              facebookUrl={ogUrl}
              twitterUrl={ogUrl}
              copyUrl={ogUrl}
              title="Successfully completed the Masai School Admission Test (MSAT)"
              hashtag="#masaischool #msat"
              screen_name={ScreenNames.msatSuccess}
            />
          ) : (
            <Text textStyle="body1" mb={"32px"} color={"ms-grey.700"}>
              Provisional Admit Card not found, please retry after sometime or reach out to us at{" "}
              <Link
                cursor={"pointer"}
                isExternal={true}
                href="mailto:admissions@masaischool.com"
                color="ms-blue.500"
                textDecoration={"underline"}
                onClick={() => {
                  clevertap.fireEvent(CleverTapEvents.click_contact_us, {
                    screen_name: ScreenNames.msatSuccess,
                    button_text: !isIITDashboard
                      ? "admissions@masaischool.com"
                      : isIITMandiDashboard
                      ? "iitmandi.program@masaischool.com"
                      : isIITGuwahatiDashboard
                      ? "iitg.daksh.gurukul@masaischool.com"
                      : isIITRoparDashboard
                      ? "aiforbharat.iitropar@masaischool.com"
                      : "iitmandi.program@masaischool.com",
                    button_type: "link",
                  });
                }}
              >
                {!isIITDashboard
                  ? "admissions@masaischool.com"
                  : isIITMandiDashboard
                  ? "iitmandi.program@masaischool.com"
                  : isIITGuwahatiDashboard
                  ? "iitg.daksh.gurukul@masaischool.com"
                  : isIITRoparDashboard
                  ? "aiforbharat.iitropar@masaischool.com"
                  : "iitmandi.program@masaischool.com"}
              </Link>
            </Text>
          )}
        </Box>
      </Box>
      {(isIITMandiDashboard || isIITGuwahatiDashboard) && !isIITRoparDashboard && !isIITMandiV2Dashboard && !isIITMandiESDashboard && (
        <Button
          w="full"
          margin="auto"
          justifyContent={"center"}
          variant="primary"
          onClick={() => {
            if (isIITGuwahatiDashboard) {
              window.location.href = "/iitg-onboarding";
            } else if (isIITMandiDashboard) {
              window.location.href = "/iit-onboarding";
            } else {
              setIsAdmitCardModalOpen && setIsAdmitCardModalOpen(false);
            }
          }}
        >
          Done
        </Button>
      )}
    </>
  );
};

export default ShareModal;
