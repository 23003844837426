import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { emiOption } from "./constant";

const PayInEmi = ({
  state,
  setState,
  amount,
}: {
  state: {
    emiOption: string;
    currentStatus: string;
    monthlySalary: string;
  };
  setState: React.Dispatch<React.SetStateAction<{ emiOption: string; currentStatus: string; monthlySalary: string }>>;
  amount: number;
}) => {
  const setEmi = (emiOption: string) => {
    setState((prevState) => ({ ...prevState, emiOption }));
  };

  const setStatus = (currentStatus: string) => {
    setState((prevState) => ({ ...prevState, currentStatus }));
  };

  const setSalary = (monthlySalary: string) => {
    setState((prevState) => ({ ...prevState, monthlySalary }));
  };

  return (
    <Box px={{ md: "24px", base: "13px" }} py={{ md: "15px", base: "18px" }}>
      <Text color="#3B3435" fontSize="16px" fontWeight={600} fontFamily="Open Sans" lineHeight="23px" pb="10px">
        Please fill in the details below. Our Admission team will get in touch with you and assist with the further process.
      </Text>
      <Box px={{ md: "37px", base: "11px" }}>
        <Text pb="15px" color="#21191B" fontSize="14px" fontWeight={600} fontFamily="Open Sans">
          1. Select your EMI option?*
        </Text>

        <RadioGroup onChange={setEmi} value={state.emiOption} pb="20px">
          <Stack>
            {emiOption.map((option) => (
              <>
                <Radio key={option.value} value={option.value} fontSize={{ md: "16px", base: "12px" }} color="#21191B" fontWeight={600}>
                  {option.label}
                </Radio>
                <Text key={option.value} color="#21191B" px="25px" fontWeight={600} fontFamily="Open Sans" fontSize="18px">
                  {`(₹${Math.ceil(amount / 8)} * 8)`}
                </Text>
              </>
            ))}
          </Stack>
        </RadioGroup>

        <Box maxW={{ md: "521px", base: "271px" }} bg="#F2F6FF" border="1px solid #d6e2fa " borderRadius="6px" px="8px" py="8px">
          <Text fontFamily="Open Sans" fontSize="14px" fontWeight={400} lineHeight="24px">
            You must have a family member serve as a co-applicant to process your loan application if you are: <br /> a. Studying or currently not working <br /> b. Earning less than 15000 per month
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PayInEmi;
