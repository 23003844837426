export const statusOptions = [
  { value: "Currently working", label: "Currently working" },
  { value: "Currently studying", label: "Currently studying" },
  { value: "Currently not working nor studying", label: "Currently not working nor studying" },
];

export const emiOption = [{ value: "6 months", label: "6 months", text: "(₹ 8,000 * 6)" }];

export const salaryOptions = [
  { value: "Self (Earning >15000/month)", label: "Self (Earning > 15000/month)" },
  { value: "Co-Applicant (Earning < 15000/month)", label: "Co-Applicant (Earning < 15000/month)" },
];

export const paymentOptions = [
  { value: "Pay full amount", label: "Pay full amount" },
  { value: "Pay in EMIs", label: "Pay in EMI" },
];
