import type { CourseSelected } from "../../../../enums/ProgramType.enum";
import type { RazorpaySuccessReportType, CreatePaymentRecordType, PaymentType, CreatePaymentRecordTypeForIITEntranceTest, CreateIITEntranceMockTestInput, CreateIITEntranceMainTestInput, RescheduleIITEntranceSlot, UpdateTestStatusInput, UpdateUserInfoIITEntranceTest as SaveUserInfoIITEntranceTest, CreatePaymentRecordTypeForIITEntranceTestSeatBook as CreatePaymentRecordForIITEntranceTestSeatBookType, ScheduleIITEntranceCounsessingInput, CreatePaymentRecordForIITEntranceTest, CreatePaymentRecordForIITOnboarding, CreatePaymentRecordForIITOnboardingEMI, ScheduleIITEntranceCounsellingV2Input, CreatePaymentRecordForIITEntranceTestSeatBookForNotSelected, DeadlineExtensionRequestInput } from "../../../schemas/schema";
import api from "../../axios";
export const RazorPaySuccessPost = async (data: RazorpaySuccessReportType) => {
  const client = await api.client;
  const response = await client.report_razorpay_success_v2_payment_record_report_razorpay_success_post(null, data);
  return response.data;
};

export const CreateIITEntranceMockTest = async (data: CreateIITEntranceMockTestInput) => {
  const client = await api.client;
  const response = await client.create_iit_entrance_mock_test_v2_payment_record_iit_entrance_mock_test_post(null, data);
  return response.data;
}
export const UpdateIITEntranceMockTestStatus = async (data: UpdateTestStatusInput) => {
  const client = await api.client;
  const response = await client.update_test_status_v2_payment_record_update_test_status_put(null, data);
  return response.data;
}
export const UpdateIITEntranceSlot = async (data: RescheduleIITEntranceSlot) => {
  const client = await api.client;
  const response = await client.reschedule_IIT_entrance_slot_v2_payment_record_reschedule_entrance_slot_put(null, data);
  return response.data;
}

export const CreateIITEntranceMainTest = async (data: CreateIITEntranceMainTestInput) => {
  const client = await api.client;
  const response = await client.create_iit_entrance_main_test_v2_payment_record_iit_entrance_main_test_post(null, data);
  return response.data;
}

export const CreatePaymentRecordPost = async (data: CreatePaymentRecordType) => {
  const client = await api.client;
  const response = await client.create_payment_record_v2_payment_record_post(null, data);
  return response.data;
};

export const CreatePaymentRecordPostForIITEntranceTest = async (data: CreatePaymentRecordTypeForIITEntranceTest) => {
  const client = await api.client;
  const response = await client.create_iit_entrance_payment_v2_payment_record_iit_entrance_payment_post(null, data);
  return response.data;
}

export const DeadlineExtensionRequest = async (data: DeadlineExtensionRequestInput) => {
  const client = await api.client;
  const response = await client.deadline_extension_request_v2_payment_record_deadline_extension_request_put(null, data);
  return response.data;
}

export const CounsellingSlotBooking = async (data: ScheduleIITEntranceCounsellingV2Input) => {
  const client = await api.client;
  const response = await client.schedule_IIT_entrance_slot_v2_v2_payment_record_schedule_counselling_slot_v2_put(null, data);
  return response.data;
}

export const CreatePaymentRecordPostForIITEntranceMainFullPaymnet = async (data: CreatePaymentRecordForIITOnboarding) => {
  const client = await api.client;
  const response = await client.create_iit_onboarding_full_payment_record_v2_payment_record_iit_entrance_onboarding_full_payment_post(null, data);
  return response.data;
}

export const CreatePaymentRecordPostForIITEntranceMainEMI = async (data: CreatePaymentRecordForIITOnboardingEMI) => {
  const client = await api.client;
  const response = await client.create_iit_onboarding_emi_payment_record_v2_payment_record_iit_entrance_onboarding_emi_payment_post(null, data);
  return response.data;
}

export const BookIITEntranceTestCounsellingSessionSlot = async (data: ScheduleIITEntranceCounsessingInput) => {
  const client = await api.client;
  const response = await client.schedule_counselling_session_v2_payment_record_schedule_counselling_session_put(null, data);
  return response.data;
}
export const CreatePaymentRecordPostForIITEntranceTestSeatBook = async (data: CreatePaymentRecordForIITEntranceTestSeatBookType) => {
  const client = await api.client;
  const response = await client.iit_entrance_payment_seat_book_v2_payment_record_iit_entrance_payment_seat_book_post(null, data);
  return response.data;
}

export const CreatePaymentRecordPostForIITEntranceTestSeatBookNotselected = async (data: CreatePaymentRecordForIITEntranceTestSeatBookForNotSelected) => {
  const client = await api.client;
  const response = await client.create_iit_entrance_payment_record_seat_book_not_selected_v2_payment_record_iit_entrance_payment_seat_book_not_selected_post(null, data);
  return response.data;
}

export const UpdateUserInfoIITEntranceTest = async (data: SaveUserInfoIITEntranceTest) => {
  const client = await api.client;
  const response = await client.save_users_details_iit_entrance_v2_payment_record_save_users_details_iit_entrance_put(null, data);
  return response.data;
}

export const GetPaymentStatus = async (paymentType: PaymentType) => {
  const client = await api.client;
  const response = await client.get_payment_status_v2_payment_record_payment_status_get(paymentType);
  return response.data;
};

export const GetSemesterFee = async (course_profile: CourseSelected) => {
  const client = await api.client;
  const response = await client.semester_payment_v2_payment_record_semester_payment_get({
    course_offered_by: course_profile,
  });
  return response.data;
};

export const HandleRoparStudents = async () => {
  const client = await api.client;
  const response = await client.handle_ropar_student_v2_payment_record_ropar_student_post({});
  return response.data;
};

export const CreatePhonePayPayment = async (data: CreatePaymentRecordType) => {
  const client = await api.client;
  const response = await client.create_payment_record_phone_pay_v2_payment_record_create_phone_pay_post(null, data);
  return response.data;
};

export const GetPhonePayPaymentStatus = async (merchantTransactionId: string) => {
  const client = await api.client;
  const response = await client.report_razorpay_success_v2_payment_record_report_phonepay_success_post(merchantTransactionId);
  return response.data;
};
