import React, { useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import { ProfileType, ProgramProfileType, SemesterPaymentType } from "../../../../api/schemas/schema";
import usePayment, { useGetPaymentStatus, useGetSemesterFee } from "../../../../api/utils/hooks/usePayment";
import { CourseSelected, ProgramProfileType as ProgramType } from "../../../../enums/ProgramType.enum";
import calculateFinalPayment from "../../../common/V2/CalculateFinalPayment";
import { CreatePhonePayPayment } from "../../../../api/utils/api/v2/payment";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { getActiveBatch } from "../../../../api/utils/api/v2/batch";
import CouponInput from "../../../IIT-Onboarding/CouponInput";

interface PaymentButtonProps {
  amount: number;
  profile: ProfileType;
  semesterPaymentOptionsData?: SemesterPaymentType;
}

const PaymentButton: React.FC<PaymentButtonProps> = ({ profile, semesterPaymentOptionsData }) => {
  const snackbar = useSnackBar();
  const [selectedCourseId, setSelectedCourseId] = useState<string>();
  const [paymentAmount, setPaymentAmount] = useState<undefined | number>(undefined);
  const [absolutePaymentAmount, setAbsolutePaymentAmount] = useState(0);
  const [prevFinal, setPrevFinal] = useState(0);
  const { refetch: refetchSemesterEMIFees } = useGetPaymentStatus("SEMESTER_FEES_EMI");

  const isIITRopar = profile?.program_profile_type === ProgramType.IIT_ROPAR;

  //for coupon code
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [couponValue, setCouponValue] = useState("");
  const [discountPrice, setDiscountPrice] = useState(0);
  const [couponCode, setCouponCode] = useState("");

  const { data: semesterData } = useGetSemesterFee(profile?.program_profile_type as CourseSelected);
  const { startRazorPay, isLoading: isPaymentLoading, isApiLoading: isPaymentApiLoading } = usePayment("SEMESTER_FEES_EMI", profile?.program_profile_type as ProgramProfileType);

  const { mutate: createPhonePayPayment, isLoading: isPhonePayApiLoading } = useMutation(CreatePhonePayPayment, {
    onSuccess: (data) => {
      const paymentUrl = data?.redirect_url;
      window.open(paymentUrl, "_self");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handlePaymentCreateOption, isLoading: isActiveApiBatchLoading } = useMutation(getActiveBatch, {
    onSuccess: (data) => {
      const isPhonePayEnabled = data && data.meta_data && data.meta_data.isPhonePayEnabled === "True";
      if (discountAmount && discountAmount > 0 && !isIITRopar) {
        if (isPhonePayEnabled) {
          const redirectUrl = window.location.pathname;
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES_EMI",
            redirect_url: redirectUrl,
            semester_payent_type: "EMI_PAID",
            coupon_code: couponCode,
          });
        } else {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES_EMI", couponCode, "EMI_PAID");
        }
      } else {
        if (isPhonePayEnabled) {
          const redirectUrl = window.location.pathname;
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES_EMI",
            redirect_url: redirectUrl,
            semester_payent_type: "EMI_PAID",
          });
        } else {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES_EMI", null, "EMI_PAID");
        }
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  // Calculating all the possible changes to payment amount
  useEffect(() => {
    if (semesterData) {
      let semsterFee = semesterData?.full_course_fees || 0;
      if (semesterPaymentOptionsData === "FULLY_PAID") {
        semsterFee = semesterData?.full_course_fees || 0;
      } else if (semesterPaymentOptionsData === "PARTIALLY_PAID") {
        semsterFee = semesterData?.partial_semester_one_fees || 0;
      } else if (semesterPaymentOptionsData === "EMI_PAID") {
        semsterFee = semesterData?.semester_one_fees_emi || 0;
      }
      const { finalAmount } = calculateFinalPayment({
        registrationFee: semsterFee,
      });

      const courseId = semesterData?.id.toString() || "";

      setSelectedCourseId(courseId);
      setPaymentAmount(finalAmount);

      setPrevFinal(finalAmount);
      setAbsolutePaymentAmount(finalAmount);
    }
  }, [profile, semesterData, semesterPaymentOptionsData]);

  useEffect(() => {
    if (profile?.meta && profile?.meta?.emi_payment_coupon_code) {
      setCouponCode(profile?.meta?.emi_payment_coupon_code);
      setCouponValue(profile?.meta?.emi_payment_coupon_code);
    }
  }, [profile, profile?.meta]);

  return (
    <Box>
      <CouponInput
        discountedAmount={discountAmount}
        setDiscountedAmount={setDiscountAmount}
        setCouponValue={setCouponValue}
        couponValue={couponValue}
        selectedCoursePrice={prevFinal}
        priceAfterDiscount={absolutePaymentAmount}
        registrationFee={paymentAmount}
        profile={profile}
        selectedCourseId={selectedCourseId || ""}
        setDiscountPrice={setDiscountPrice}
        setCouponCode={setCouponCode}
        paymentType={"SEMESTER_FEES_EMI"}
        semesterPaymentOptionsData="EMI_PAID"
        refectchFees={refetchSemesterEMIFees}
      />
      <Button
        px={4}
        py={2}
        mt={9}
        fontSize="sm"
        fontWeight="semibold"
        letterSpacing="wider"
        lineHeight="6"
        textTransform="uppercase"
        color="white"
        variant="primary"
        maxW="full"
        mr={4}
        display={{ base: "none", md: "flex" }}
        onClick={() => handlePaymentCreateOption(selectedCourseId || "")}
        isLoading={isPaymentApiLoading || isPaymentLoading || isActiveApiBatchLoading || isPhonePayApiLoading}
        isDisabled={isPaymentApiLoading || isPaymentLoading || !absolutePaymentAmount}
      >
        Pay {discountAmount && !isIITRopar ? (paymentAmount ? `₹ ${paymentAmount - discountAmount}/-` : "₹ 0/-") : `₹ ${paymentAmount}/-`}
      </Button>
    </Box>
  );
};

export default PaymentButton;
