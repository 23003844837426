import React, { useState } from "react";
import { Box, Image, Skeleton, Text } from "@chakra-ui/react";
import LearnContent from "../../components/V2/Dashboard/Learn/LearnContent";
import { courDataDynamic } from "../../components/EntranceExam/constents";
import { useQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
// import ExamInfoPage from '../../components/EntranceExam/ExamInfoPage'
import { Constants } from "../../components/common/constants";
import RightContaint from "../../components/OnboardingAfterEntranceTestComponents/RightContaint";
import RightPart from "../../components/EntranceExam/RightPart";
import LeftContaint from "../../components/OnboardingAfterEntranceTestComponents/LeftContaint";

const OnboardingAfterEnternceTest = () => {
  const [isTimeOver, setIsTimeOver] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;

  return (
    <>
      <Box height={`${Constants.headerHeight}`} display="flex" justifyContent="center" shadow="md" position="fixed" top="0px" zIndex="10" w="full" bg="white">
        <Box maxW="1440px" mx="auto" display="flex" justifyContent="space-between" alignItems="center" w="full" px="2">
          <Box display="flex" alignItems="center" gap="2">
            <Image maxW="67px" src={courDataDynamic("ranker")[course]?.logo} alt={courDataDynamic("ranker")[course].title} />

            <Text fontSize="md" fontWeight="600" color="#21191B">
              {courDataDynamic("ranker")[course]?.collegeName}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* <Box w="full" h="fit-content" minH="100vh" pos="absolute" top="0" paddingTop="4.5rem" isolation="isolate">
          <ExamInfoPage testHeading={courDataDynamic("ranker")[course].assesmentpageTitle} course_id={course} />
        </Box> */}
      <Box display={{ base: "block", lg: "flex" }} w="full" h="fit-content" minH="100vh" pos="absolute" top="0" paddingTop="4.5rem" isolation="isolate">
        <LeftContaint />
        <RightContaint />
      </Box>
    </>
  );
};

export default OnboardingAfterEnternceTest;
