import { Box, Button, Image, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { courDataDynamic } from "../../components/EntranceExam/constents";

import React, { useState } from "react";
import { useQuery } from "react-query";
import { CgArrowRight } from "react-icons/cg";
import dayjs from "dayjs";
import { GetIITEntranceExamStatus } from "../../api/utils/api/v2/IITEntranceExam";
import { AiOutlineCheck } from "react-icons/ai";

const RightContaint = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const searchParams = new URLSearchParams(location?.search);
  const course: keyof ReturnType<typeof courDataDynamic> = searchParams.get("course") as keyof ReturnType<typeof courDataDynamic>;
  const testDate = dayjs(courDataDynamic("ranker")[course].testDate).format("YYYY-MM-DD");
  const courseDataRanker = courDataDynamic("ranker")[course];
  const courseDataWaitingList = courDataDynamic("waiting-list")[course];
  const { data } = useQuery({
    queryKey: ["getIITEntranceExamStatus"],
    queryFn: async () => await GetIITEntranceExamStatus(course, testDate),
    staleTime: 60000,
    retry: 30000,
    enabled: true,
    onSuccess(data) {
      if (data.curentSelectionStatus === "") {
        if (data.step === 0) setActiveStep(0);
        else if (data.step === 1) setActiveStep(1);
        else setActiveStep(1);
        return;
      }
      if (data.isSeatBookingDone) {
        setActiveStep(3);
        return;
      }
      if (data.step === 2 && Date.now() <= new Date("2024-10-28T18:00:00").getTime()) return setActiveStep(1);
      setActiveStep(data.step);
    },
  });
  const showOnlyToSelectedCandidates = () => {
    return true;
  };
  // const showOnlyToSelectedCandidates = () => {
  // //   if (data?.isSeatBookingDone) return true;
  // //   if (activeStep >= 2 && data?.curentSelectionStatus === "ranker" && new Date() >= new Date(courseDataRanker.resultDate)) return true;
  // //   if (activeStep >= 2 && data?.curentSelectionStatus === "waiting-list" && new Date() >= new Date(courseDataWaitingList.resultDate)) return true;
  // //   return false;
  // };

  const showToAllCandidates = () => {
    if (activeStep < 2) return true;
    if (activeStep >= 2 && data?.curentSelectionStatus !== "ranker") {
      if (data?.curentSelectionStatus === "waiting-list" && new Date() < new Date(courseDataWaitingList.resultDate)) {
        return true;
      }
      return false;
    }

    if (data?.curentSelectionStatus === "ranker" && new Date() < new Date(courseDataRanker.resultDate)) return true;
    return false;
  };
  return (
    <Box sx={{ flex: 1, maxW: { base: "0", lg: "623px" }, display: { base: "none", lg: "block" } }}>
      <Box
        id="heading"
        sx={{
          height: "100%",
          backgroundColor: courseDataRanker.backgroundColor,
          pb: { base: "100px", lg: "0px" },
          borderLeft: { base: "none", lg: `0.2px solid ${courseDataRanker.borderColor}` },
          fontFamily: "Arial, sans-serif",
        }}
      >
        {course && (
          <div>
            <Box display={{ base: "none", lg: "flex" }} alignItems={"center"} justifyContent={"center"} bg={courseDataRanker.darkBackgroundColor} py="21px">
              <Box display={"flex"} gap="3">
                <Image src={courseDataRanker.icon} alt="📊" maxW="59.317px" maxH="59.317px" minH="59.317px" minW="59.317px" />
                <Box>
                  <Text sx={{ fontSize: "18.537px", fontWeight: "700", fontFamily: "Poppins", mb: "3px" }}>{courseDataRanker.title}</Text>
                  <Text sx={{ fontSize: "16px", color: "#555" }}>{courseDataRanker.subtitle}</Text>
                </Box>
              </Box>
            </Box>

            <Box pt={{ base: "20px", lg: "64px" }} pb="20px" px={{ base: "20px", lg: "80px" }} experimental_spaceY="10%">
              {/* {showToAllCandidates() ? (
                  <Box>
                    <Text fontSize="18px" fontWeight="700" color="#0A0103">
                      {courDataDynamic("ranker")[course].registrationProcess.title}
                    </Text>
  
                    <Box mt="10px" experimental_spaceY="16px" position="relative" isolation="isolate">
                      <Box h="full" w="1px" border="1px dashed #3B3435" left="15px" position="absolute" zIndex="-1"></Box>
                      {courseDataRanker.registrationProcess.steps.map((step, index) => (
                        <Box display="flex" key={index} gap="16px" alignItems="center">
                          <Box
                            minW="31.541px"
                            minH="31.541px"
                            fontSize="13.799px"
                            color="#6C6768"
                            textTransform="uppercase"
                            maxW="31.541px"
                            maxH="31.541px"
                            bg="white"
                            border={`1px solid ${courseDataRanker.borderColor}`}
                            borderRadius="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {index + 1}
                          </Box>

                          <Text key={index} fontSize="14px" fontWeight="600" color="#3B3435">
                            {step}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null} */}

              {/* {showToAllCandidates() ? (
                  <Box>
                    <Text fontSize="18px" fontWeight="700" color="#0A0103">
                      {courseDataRanker.thingsToRemember.title}
                    </Text>
  
                    <Box mt="10px" experimental_spaceY="16px">
                      {courseDataRanker.thingsToRemember.points.map((step, index) => (
                        <Box display="flex" key={index} gap="16px" alignItems="center">
                          <Box>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.7064 5.29279C16.8939 5.48031 16.9992 5.73462 16.9992 5.99979C16.9992 6.26495 16.8939 6.51926 16.7064 6.70679L8.70643 14.7068C8.5189 14.8943 8.2646 14.9996 7.99943 14.9996C7.73427 14.9996 7.47996 14.8943 7.29243 14.7068L3.29243 10.7068C3.11027 10.5182 3.00948 10.2656 3.01176 10.0034C3.01403 9.74119 3.1192 9.49038 3.30461 9.30497C3.49002 9.11956 3.74083 9.01439 4.00303 9.01211C4.26523 9.00983 4.51783 9.11063 4.70643 9.29279L7.99943 12.5858L15.2924 5.29279C15.48 5.10532 15.7343 5 15.9994 5C16.2646 5 16.5189 5.10532 16.7064 5.29279Z"
                                fill="#6E71CC"
                              />
                            </svg>
                          </Box>
                          <Text key={index} fontSize="14px">
                            {step}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null} */}
              {/*   
                {showToAllCandidates() ? (
                  <Box>
                    <Text fontSize="18px" fontWeight="700" color="#0A0103">
                      {courseDataRanker.topicsCovered.title}
                    </Text>
  
                    <Box mt="10px" experimental_spaceY="8px">
                      {courseDataRanker.topicsCovered.points.map((step, index) => (
                        <Box as="ul" display="flex" key={index} gap="16px" alignItems="center" pl="15px">

                          <Text as="li" key={index} fontSize="14px">
                            {step}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : null} */}

              {showOnlyToSelectedCandidates() ? (
                <Box>
                  <Text fontSize="18px" fontWeight="700" color="#0A0103">
                    {courseDataRanker.courseOfferings.title}
                  </Text>

                  <Box mt="10px" experimental_spaceY="8px" position="relative" isolation="isolate">
                    {courseDataRanker.courseOfferings.points.map((step, index) => (
                      <Box display="flex" key={index} gap="8px" alignItems="center">
                        <Box minW="22.244px" minH="22.244px" maxW="22.244px" maxH="22.244px">
                          {step.icon && <step.icon width="22.244px" height="22.244px" color={"#6E71CC"} />}
                        </Box>
                        <Text key={index} fontSize="12.976px" fontWeight="600" color="#544D4F" fontFamily="Open Sans">
                          {step.text}
                        </Text>
                      </Box>
                    ))}
                  </Box>

                  {Boolean(new URL(courseDataRanker.courseOfferings.brochure)) && (
                    <Button variant="link" mt="16px" w="fit-content" bg="transparent" onClick={() => window.open(courseDataRanker.courseOfferings.brochure, "_blank")}>
                      Download Brochure <CgArrowRight size="24px" style={{ marginLeft: "3px" }} />
                    </Button>
                  )}
                </Box>
              ) : null}

              {showOnlyToSelectedCandidates() ? (
                <Box>
                  <Text fontSize="18px" fontWeight="700" color="#0A0103">
                    {courseDataRanker.importantDates.title}
                  </Text>

                  <Box mt="10px" experimental_spaceY="16px" position="relative" isolation="isolate">
                    <Box h="full" w="1px" border="1px dashed #3B3435" left="15px" position="absolute" zIndex="-1"></Box>
                    {courDataDynamic(data?.curentSelectionStatus || "ranker")[course].importantDates.points.map((step, index) => (
                      <Box display="flex" key={index} gap="16px" alignItems="center">
                        {index !== 0 ? (
                          <Box
                            minW="31.541px"
                            minH="31.541px"
                            fontSize="13.799px"
                            color="#6C6768"
                            textTransform="uppercase"
                            maxW="31.541px"
                            maxH="31.541px"
                            bg="white"
                            border={`1px solid ${courseDataRanker.borderColor}`}
                            borderRadius="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {index + 1}
                          </Box>
                        ) : (
                          <Box
                            minW="31.541px"
                            minH="31.541px"
                            maxW="31.541px"
                            maxH="31.541px"
                            bg="white"
                            border={`1px solid ${courseDataRanker.borderColor}`}
                            borderRadius="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            background={"#52BD94"}
                          >
                            <AiOutlineCheck color="white" size="13.799px" />
                          </Box>
                        )}

                        <Text key={index} fontSize="14px" fontWeight="600" color="#3B3435">
                          {step}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default RightContaint;
