import { Skeleton } from "@chakra-ui/react";
import SEO from "../../../components/common/SEO";
import { useContext, useEffect, useState } from "react";
import { seoData } from "../../../components/common/constants";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import PapDashboard from "../../../components/revamp/PapDashboard";
import IITDashboard from "../../../components/revamp/IITDashboard";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import { useGetPaymentStatus } from "../../../api/utils/hooks/usePayment";
import { useLastAssessment } from "../../../api/utils/hooks/useAssessment";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { useBestAssessment, useBestAssessmentForEmpowerHer } from "../../../api/utils/hooks/useBestAssessment";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import { ApplicationType, ProfileAssessment, ProfileType } from "../../../api/schemas/schema";
import SelectCoursePreferencePage from "../../../components/revamp/SelectCoursePreferencePage";
import { useHistory } from "react-router-dom";

export enum ACTIVITY_TYPE {
  AMA = "AMA",
  WEBINAR = "WEBINAR",
  BOOTCAMP = "BOOTCAMP",
  TECHTONIC_SHIFT = "TECHTONIC_SHIFT",
  CRACK_THE_CODE = "CRACK_THE_CODE",
  FOUNDER_WEBINAR = "FOUNDER_WEBINAR",
  MASTERCLASS = "MASTERCLASS",
  IIT_COUNSELING = "IIT_COUNSELING",
  IIT_MANDI_COUNSELING = "IIT_MANDI_COUNSELING",
  IIT_ROPAR_COUNSELING = "IIT_ROPAR_COUNSELING",
}

const Dashboard = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(AppContext);
  const [isPaymentDoneForIITG, setIsPaymentDoneForIITG] = useState<boolean>(false);
  const [isRegisterationPaymentDone, setIsRegisterationPaymentDone] = useState<boolean>(false);
  const [isPaymentDoneForIITMandi, setIsPaymentDoneForIITMandi] = useState<boolean>(false);
  const [, setHideZoomSlack] = useState(false);
  const [, setShowSelectCoursePreference] = useState(false);
  const { data: profile, isLoading: isProfileLoading, refetch: profileRefetch, isFetching, isFetched: isProfileFetched } = useProfile((data) => {
    dispatch({
      type: ApplicationActionType.SET_COURSE_PREFERENCE,
      payload: {
        coursePreference: data?.program_profile_type as ProgramProfileType,
      },
    });
  });

  const { data: semesterPaymentData, isLoading: isSemesterDataLoading } = useGetPaymentStatus("SEMESTER_FEES");
  const { refetch: paymentStatusRefetch, data: paymentData, isLoading: isPaymentDataLoading, isFetching: isPaymentfetching } = useGetPaymentStatus("REGISTRATION_FEES");

  const { data: lastAssessment, isLoading: isLastAssessmentLoading } = useLastAssessment();
  const { data: bestAssessment, isLoading: bestAssessmentLoading, isFetched, isFetching: isAssessmentFetching } = useBestAssessment();

  const { data: empowerHerBestAssessmentFor, isLoading: empowerHerBestAssessmentLoading, isFetching: isEmpowerHerAssessmentFetching } = useBestAssessmentForEmpowerHer();

  const { data: activeApplication, isLoading: activeApplicationLoading, refetch: refetchApplication, isFetching: isApplicationFetching } = useActiveApplication();
  const shouldBookForIITMandiCounsellingEvent = window.location.search.includes("iitMandiCounsellingEvent");
  const shouldBookForIITGuwahatiCounsellingEvent = window.location.search.includes("iitGuwahatiCounsellingEvent");
  const hasPaidRegistrationFees = paymentData && paymentData.status === "PAID" && paymentData?.is_masai_course;

  useEffect(() => {
    if (shouldBookForIITGuwahatiCounsellingEvent) {
      dispatch({
        type: ApplicationActionType.SET_IITG_COUNSELLING_BOOK_EVENT,
        payload: { shouldBookForIITGuwahatiCounsellingEvent: true },
      });
    } else if (shouldBookForIITMandiCounsellingEvent) {
      dispatch({
        type: ApplicationActionType.SET_IIT_MANDI_COUNSELLING_BOOK_EVENT,
        payload: { shouldBookForIITMandiCounsellingEvent: true },
      });
    }
  }, [shouldBookForIITGuwahatiCounsellingEvent, shouldBookForIITMandiCounsellingEvent]);

  useEffect(() => {
    if (!activeApplication) return;
    // store in localstorage
    const hideZoomSlack = localStorage.getItem(`hideZoomSlack-${activeApplication?.id}`);
    if (hideZoomSlack) {
      setHideZoomSlack(true);
    } else {
      setHideZoomSlack(false);
    }
  }, [activeApplication]);

  useEffect(() => {
    profileRefetch();
    refetchApplication();
    paymentStatusRefetch();
  }, []);

  useEffect(() => {
    if (window.location.search.includes("brochure") || window.location.search.includes("request_callback")) {
      if (window.location.search.includes("showIITGuwahati")) {
        dispatch({
          type: ApplicationActionType.SET_COURSE_PREFERENCE,
          payload: {
            coursePreference: ProgramProfileType.IIT_GUWAHATI,
          },
        });
        if (shouldBookForIITGuwahatiCounsellingEvent) {
          window.location.href = "/mc-iitg-cs?brochure=true&details_modal=true&iitGuwahatiCounsellingEvent=true";
        } else {
          window.location.href = "/mc-iitg-cs?brochure=true&details_modal=true";
        }
      } else if (window.location.search.includes("showIITMandi")) {
        dispatch({
          type: ApplicationActionType.SET_COURSE_PREFERENCE,
          payload: {
            coursePreference: ProgramProfileType.IIT_MANDI,
          },
        });
        if (shouldBookForIITMandiCounsellingEvent) {
          window.location.href = "/iit-mandi-ai-ml?brochure=true&details_modal=true&iitMandiCounsellingEvent=true";
        } else {
          window.location.href = "/iit-mandi-ai-ml?brochure=true&details_modal=true";
        }
      } else if (window.location.search.includes("showIITRopar")) {
        dispatch({
          type: ApplicationActionType.SET_COURSE_PREFERENCE,
          payload: {
            coursePreference: ProgramProfileType.IIT_ROPAR,
          },
        });
        window.location.href = "/iit-ropar-ai?brochure=true&details_modal=true";
      } else if (window.location.search.includes("showNewIITMandi")) {
        dispatch({
          type: ApplicationActionType.SET_COURSE_PREFERENCE,
          payload: {
            coursePreference: ProgramProfileType.IIT_MANDI_V2,
          },
        });
        window.location.href = "/minor-in-cse?brochure=true&details_modal=true";
      }
    } else if (window.location.search.includes("showIITGuwahati")) {
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_GUWAHATI,
        },
      });
      if (shouldBookForIITGuwahatiCounsellingEvent) {
        window.location.href = "/mc-iitg-cs?details_modal=true&iitGuwahatiCounsellingEvent=true";
      } else {
        window.location.href = "/mc-iitg-cs?details_modal=true";
      }
    } else if (window.location.search.includes("showIITMandi")) {
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_MANDI,
        },
      });
      if (shouldBookForIITMandiCounsellingEvent) {
        window.location.href = "/iit-mandi-ai-ml?details_modal=true&iitMandiCounsellingEvent=true";
      } else {
        window.location.href = "/iit-mandi-ai-ml?details_modal=true";
      }
    } else if (window.location.search.includes("showIITRopar")) {
      setShowSelectCoursePreference(false);
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_ROPAR,
        },
      });
    } else if (window.location.search.includes("showNewIITMandi")) {
      setShowSelectCoursePreference(false);
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_MANDI_V2,
        },
      });
    } else if (window.location.search.includes("showESIITMandi")) {
      setShowSelectCoursePreference(false);
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_MANDI_ES,
        },
      });
    } else if (window.location.search.includes("showBAIITMandi")) {
      setShowSelectCoursePreference(false);
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.IIT_MANDI_BA,
        },
      });
    } else if (window.location.search.includes("showAlumniConnect")) {
      history.push("/alumni");
    } else {
      const isMasaiCoursePrefered = state.coursePreference === ProgramProfileType.MASAI;
      if (isProfileFetched && isFetched && !isMasaiCoursePrefered) {
        setShowSelectCoursePreference(true);
      }
    }
  }, [profile, isProfileFetched, isFetched, state.coursePreference]);

  useEffect(() => {
    if (window.location.search.includes("openRedirectModal")) {
      localStorage.setItem("openRedirectModal", "true");
    }
    if (window.location.search.includes("from_course_detailsPage")) {
      const courseValue = window.location.search.split("course=")[1];
      if (courseValue === "data-analytics") {
        window.location.href = "/courses/14/details";
      } else if (courseValue === "fullstack-web-development") {
        window.location.href = "/courses/12/details";
      }
    }
  }, []);

  // Loading/Skeleton Conditions
  const isSkeletonLoading =
    activeApplicationLoading ||
    isProfileLoading ||
    bestAssessmentLoading ||
    isFetching ||
    isAssessmentFetching ||
    isPaymentDataLoading ||
    isPaymentfetching ||
    isApplicationFetching ||
    isLastAssessmentLoading ||
    isSemesterDataLoading ||
    empowerHerBestAssessmentLoading ||
    isEmpowerHerAssessmentFetching;
  useEffect(() => {
    if (!isSkeletonLoading) {
      dispatch({
        type: ApplicationActionType.SET_IS_SIDEBAR_LOADING,
        payload: { isSidebarLoading: false },
      });
    }
  }, [isSkeletonLoading]);

  useEffect(() => {
    const shouldRedirectToScholarshipStepsPage = window.location.search.includes("showScholarshipPage");
    const shouldRedirectToScholarshipPage = window.location.search.includes("showScholarshipOnwards");

    // redirect to sonu-sood-scholarship page
    if (shouldRedirectToScholarshipStepsPage) {
      window.location.href = "/scholarship-onboarding";
    }
    if (shouldRedirectToScholarshipPage) {
      window.location.href = "/sonu-sood-scholarship";
    }
  }, []);

  useEffect(() => {
    const showEmpowerHer = window.location.search.includes("showEmpowerHer");
    const hasUserReservedSeatForEmpowerHer = paymentData?.status === "PAID" && paymentData?.is_masai_course ? true : false;
    if (showEmpowerHer && paymentData) {
      // if (hasUserReservedSeatForEmpowerHer) {
      window.location.href = "/empower-her";
      // } else {
      //   window.location.href = "/empowerHer-onboarding";
      // }
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData) {
      setIsPaymentDoneForIITG(paymentData?.is_iit_guwahati_course && paymentData?.status === "PAID");
      setIsPaymentDoneForIITMandi(paymentData?.is_iit_mandi_course && paymentData?.status === "PAID");
    }
  }, [paymentData]);

  useEffect(() => {
    if (semesterPaymentData) {
      setIsRegisterationPaymentDone((semesterPaymentData.is_iit_mandi_course || semesterPaymentData.is_iit_guwahati_course) && semesterPaymentData.status === "PAID");
    }
  }, [semesterPaymentData]);

  const shouldShowDetailsModal =
    (!isSkeletonLoading &&
      !!(
        state &&
        (!state?.profileDataV2?.other_address || (state?.profileDataV2?.other_address && !state?.profileDataV2?.other_address?.currentlyWorking)) &&
        (!state?.profileDataV2?.meta || (state?.profileDataV2?.meta?.hasSkippedLaunchForm !== "True" && !state?.profileDataV2?.meta?.hasFilledLaunchFormDetails))
      )) ||
    (state?.profileDataV2?.aadhaar_verified && state?.profileDataV2?.meta?.hasSkippedLaunchForm === "True");

  if (state.profileDataV2 && !state?.profileDataV2?.program_profile_type && !isSkeletonLoading) {
    return (
      <Skeleton minH={"100vh"} height="100%" bgColor={"white"} isLoaded={true}>
        <SelectCoursePreferencePage />
      </Skeleton>
    );
  }

  return (
    <>
      <SEO {...seoData["MASAI-ONWARD"]} />
      <Skeleton minH={"100vh"} height="100%" bgColor={"#FAFAFA"} pb={{ base: "34px", lg: "44px" }} isLoaded={!isSkeletonLoading}>
        {state.profileDataV2?.program_profile_type === ProgramProfileType.MASAI ? (
          <PapDashboard
            bestAssessment={bestAssessment as ProfileAssessment}
            activeApplication={activeApplication as ApplicationType}
            profile={profile as ProfileType}
            empowerHerBestAssessmentFor={empowerHerBestAssessmentFor as ProfileAssessment}
            hasPaidRegistrationFees={hasPaidRegistrationFees || false}
          />
        ) : (
          <IITDashboard
            activeApplication={activeApplication as ApplicationType}
            profile={profile as ProfileType}
            isPaymentDoneForIITG={isPaymentDoneForIITG}
            isPaymentDoneForIITMandi={isPaymentDoneForIITMandi}
            lastAssessment={lastAssessment as ProfileAssessment}
            isRegisterationPaymentDone={isRegisterationPaymentDone}
            shouldShowDetailsModal={shouldShowDetailsModal || false}
          />
        )}
      </Skeleton>
    </>
  );
};

export default Dashboard;
