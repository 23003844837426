import { Box } from "@chakra-ui/react";
import IITFeePaymentStep from "../../IIT-Onboarding/IITFeePaymentStep";
import { IITEMIOptions, PaymentMethod } from "../../../constants/IIt-course/iit-form-onboarding";
import { SemesterPaymentType } from "../../../api/schemas/schema";

interface Props {
  setEmiOptionsData?: (data: IITEMIOptions) => void;
  emiOptionsData?: IITEMIOptions;
  handleSubmit: () => void;
  updateProfileLoading: boolean;
  registerationPaymentDate?: string;
  activeTab: PaymentMethod;
  setActiveTab: (tab: PaymentMethod) => void;
  disablePaymentBtn: boolean;
  setDisablePaymentBtn: (value: boolean) => void;
  isForIITRopar?: boolean;
  setDiscountAmount?: (value: number) => void;
  setCouponValue?: (value: string) => void;
  shouldHideDirectPayment: boolean;
  setSemesterPaymentOptionsData?: (data: SemesterPaymentType) => void;
  semesterPaymentOptionsData?: SemesterPaymentType;
  isForIITMandi?: boolean;
  isForIITMandiES?: boolean;
  isForIITMandiBA?: boolean;
}

const TrimesterFee = ({
  setEmiOptionsData,
  emiOptionsData,
  handleSubmit,
  updateProfileLoading,
  registerationPaymentDate,
  activeTab,
  setActiveTab,
  disablePaymentBtn,
  setDisablePaymentBtn,
  isForIITRopar,
  setDiscountAmount,
  setCouponValue,
  shouldHideDirectPayment,
  setSemesterPaymentOptionsData,
  semesterPaymentOptionsData,
  isForIITMandi,
  isForIITMandiES,
  isForIITMandiBA,
}: Props) => {
  return (
    <Box display={"flex"} gap={{ base: 3, md: 5 }} flexDir={{ base: "column-reverse", md: "row" }}>
      <IITFeePaymentStep
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isIITGuwahati={true}
        isForIITRopar={isForIITRopar}
        isForIITMandiV2={isForIITMandi}
        isForIITMandiES={isForIITMandiES}
        isForIITMandiBA={isForIITMandiBA}
        setEmiOptionsData={setEmiOptionsData}
        emiOptionsData={emiOptionsData}
        handleSubmit={handleSubmit}
        registerationPaymentDate={registerationPaymentDate}
        updateProfileLoading={updateProfileLoading}
        disablePaymentBtn={disablePaymentBtn}
        setDisablePaymentBtn={setDisablePaymentBtn}
        setDiscountPrice={setDiscountAmount}
        setCouponCode={setCouponValue}
        shouldHideDirectPayment={shouldHideDirectPayment}
        setSemesterPaymentOptionsData={setSemesterPaymentOptionsData}
        semesterPaymentOptionsData={semesterPaymentOptionsData}
      />
      <Box flex={1} />
    </Box>
  );
};

export default TrimesterFee;
