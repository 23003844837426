/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Text, Button, Tooltip } from "@chakra-ui/react";
import {
  Constants,
  urlsToRedirectForIITMandiV2,
  urlsToRedirectForIITRopar,
  urlsToRestrictForIITG,
  urlsToRestrictForIITMandi,
  urlsToRestrictForMasai,
  urlsToRedirectForIITMandiBA,
  urlsToRedirectForIITMandiES,
} from "../constants";
import Header from "./Header";
import { ModalTemplate } from "../general";
import React, { useContext, useEffect } from "react";
import ReferralCodeModalContent from "./ReferralCodeModalContent";
import { axiosErrorHandler } from "../../../api/utils/error";
import { updateReferralCode } from "../../../api/utils/api";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";

import { useHistory, useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../utils/gtm";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useMutation } from "react-query";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import { GetProfileTypeV2 } from "../../../api/schemas/schema";

interface Props {
  children: JSX.Element | JSX.Element[];
  showRightNav?: boolean;
}

const Layout = ({ children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useContext(AppContext);
  const [hideHeader, setHideHeader] = React.useState<boolean>(false);
  const [isReferralModalOpen, setIsReferralModalOpen] = React.useState<undefined | boolean>();
  const [referralErrorMessage, setReferralErrorMessage] = React.useState<string | null>(null);

  const { data: profile, refetch: profileRefetch } = useProfile((data) => {
    dispatch({
      type: ApplicationActionType.SET_PROFILE_V2,
      payload: {
        profileDataV2: data as GetProfileTypeV2,
      },
    });
    dispatch({
      type: ApplicationActionType.SET_COURSE_PREFERENCE,
      payload: {
        coursePreference: data?.program_profile_type as ProgramProfileType,
      },
    });
  });

  const { mutate: updateProfileMutate } = useMutation(updateProfile, {
    onSuccess: () => {
      profileRefetch();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        //   snackbar.error(e.message);
      } else {
        //   snackbar.error("Something went wrong");
      }
    },
  });

  const isMasaiCoursePrefered = state.coursePreference && state.coursePreference === ProgramProfileType.MASAI;
  const isIITGPrefered = state.coursePreference && state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
  const isIITMandiPrefered = state.coursePreference && state.coursePreference === ProgramProfileType.IIT_MANDI;
  const isIITRoparPrefered = state.coursePreference && state.coursePreference === ProgramProfileType.IIT_ROPAR;
  const isIITMandiV2Prefered = state.coursePreference && profile?.program_profile_type === ProgramProfileType.IIT_MANDI_V2;
  const isIITMandiESPrefered = state.coursePreference && profile?.program_profile_type === ProgramProfileType.IIT_MANDI_ES;
  const isIITMandiBAPrefered = state.coursePreference && profile?.program_profile_type === ProgramProfileType.IIT_MANDI_BA;

  useEffect(() => {
    if (urlsToRestrictForIITG.includes(location.pathname) && isIITGPrefered !== null && isIITGPrefered) {
      history.push("/mc-iitg-cs");
    }

    if (urlsToRestrictForIITMandi.includes(location.pathname) && isIITMandiPrefered !== null && isIITMandiPrefered) {
      history.push("/iit-mandi-ai-ml");
    }

    if (urlsToRedirectForIITRopar.includes(location.pathname) && isIITRoparPrefered !== null && isIITRoparPrefered) {
      history.push("/iit-ropar-ai");
    }

    if (urlsToRedirectForIITMandiV2.includes(location.pathname) && isIITMandiV2Prefered !== null && isIITMandiV2Prefered) {
      history.push("/minor-in-cse");
    }

    if (urlsToRedirectForIITMandiES.includes(location.pathname) && isIITMandiESPrefered !== null && isIITMandiESPrefered) {
      history.push("/minor-in-embedded-system");
    }

    if (urlsToRedirectForIITMandiBA.includes(location.pathname) && isIITMandiBAPrefered !== null && isIITMandiBAPrefered) {
      history.push("/iit-mandi-business-analytics");
    }

    if (urlsToRestrictForMasai.includes(location.pathname) && isMasaiCoursePrefered !== null && isMasaiCoursePrefered) {
      history.push("/");
    }
  }, [isMasaiCoursePrefered, isIITMandiPrefered, isIITGPrefered, isIITRoparPrefered, isIITMandiV2Prefered, isIITMandiESPrefered, isIITMandiBAPrefered]);

  useEffect(() => {
    if (["/result"].includes(location.pathname) || (state && state.profileDataV2 && !state.profileDataV2.program_profile_type)) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
  }, [state, profile, profile?.program_profile_type]);

  const onReferralModalClose = (btnType?: "skip" | "submit", referral_code?: string) => {
    updateReferral(referral_code);
  };

  useEffect(() => {
    let showModal = false;
    const skipped_referral_entry = state.profileData?.skipped_referral_entry;
    const referred_by = state.profileData?.referred_by;
    if (skipped_referral_entry === null && referred_by === null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      showModal = true;
    }
  }, [state.profileData]);

  const updateReferral = (referral_code?: string) => {
    if (state.profileData?.slug) {
      updateReferralCode({
        profile_slug: state.profileData.slug,
        referral_code: referral_code,
      })
        .then(() => {
          setIsReferralModalOpen(false);
          dispatch({
            type: ApplicationActionType.FETCH_PROFILE,
            payload: {},
          });
        })
        .catch((err) => {
          dispatch({
            type: ApplicationActionType.FETCH_PROFILE,
            payload: {},
          });
          const e = axiosErrorHandler(err);
          if (typeof e === "object" && "message" in e) {
            setReferralErrorMessage(e.message);
          } else {
            setReferralErrorMessage("Something went wrong!");
          }
        });
    }
  };

  useEffect(() => {
    // if current page is /jobs and isMasaiCoursePrefered is false then set it to true
    if (window.location.pathname.includes("/jobs") && !isMasaiCoursePrefered) {
      updateProfileMutate({ program_profile_type: "MASAI" });
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: ProgramProfileType.MASAI,
        },
      });
    }
  }, []);

  useEffect(() => {
    const shouldRediretToEventsPage = window.location.search.includes("showEventsPage");
    if (shouldRediretToEventsPage) {
      localStorage.setItem("showEventsPage", "true");
    }
  }, []);

  // Don't allow any one to access / (home page) when he choose his preference as IIT
  useEffect(() => {
    if (state.coursePreference === ProgramProfileType.IIT_GUWAHATI && (location.pathname === "/iit-mandi-ai-ml" || location.pathname === "/iit-ropar-ai")) {
      history.push("/mc-iitg-cs");
    } else if (state.coursePreference === ProgramProfileType.IIT_MANDI && (location.pathname === "/mc-iitg-cs" || location.pathname === "/iit-ropar-ai")) {
      history.push("/iit-mandi-ai-ml");
    } else if (state.coursePreference === ProgramProfileType.IIT_ROPAR && (location.pathname === "/" || location.pathname === "/mc-iitg-cs" || location.pathname === "/iit-mandi-ai-ml")) {
      history.push("/iit-ropar-ai");
    } else if (
      state.coursePreference === ProgramProfileType.IIT_MANDI_V2 &&
      (location.pathname === "/" || location.pathname === "/mc-iitg-cs" || location.pathname === "/iit-mandi-ai-ml" || location.pathname === "/iit-ropar-ai")
    ) {
      history.push("/minor-in-cse");
    } else if (
      state.coursePreference === ProgramProfileType.IIT_MANDI_ES &&
      (location.pathname === "/" ||
        location.pathname === "/mc-iitg-cs" ||
        location.pathname === "/iit-mandi-ai-ml" ||
        location.pathname === "/iit-ropar-ai" ||
        location.pathname === "/minor-in-cse" ||
        location.pathname === "/iit-mandi-business-analytics")
    ) {
      history.push("/minor-in-embedded-system");
    } else if (
      state.coursePreference === ProgramProfileType.IIT_MANDI_BA &&
      (location.pathname === "/" ||
        location.pathname === "/mc-iitg-cs" ||
        location.pathname === "/iit-mandi-ai-ml" ||
        location.pathname === "/iit-ropar-ai" ||
        location.pathname === "/minor-in-cse" ||
        location.pathname === "/minor-in-embedded-system")
    ) {
      history.push("/iit-mandi-business-analytics");
    } else if (
      state.coursePreference === ProgramProfileType.MASAI &&
      (location.pathname === "/iit-mandi-ai-ml" ||
        location.pathname === "/mc-iitg-cs" ||
        location.pathname === "/iit-ropar-ai" ||
        location.pathname === "/minor-in-cse" ||
        location.pathname === "/minor-in-embedded-system")
    ) {
      history.push("/");
    }
  }, [state.coursePreference]);

  if (location.pathname === "/self-learning") {
    window.location.href = `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn`;
  }

  return (
    <>
      <Box minH="100vh" display={"flex"}>
        {/* Navbar */}
        {!hideHeader && (
          <Box zIndex={"10"}>
            <Header />
          </Box>
        )}
        {/* Bottom Navbar */}
        <Box zIndex={"10"} mt={"20"} position={"fixed"} bottom={"0"} overflow={location.pathname === "/result" ? "hidden" : "visible"} width={"100%"}>
          {location.pathname === "/result" ? (
            <Box
              boxShadow="0px -1px 0px 0px #D9D9D9"
              bg={"white"}
              h={Constants.bottomNavbarHeight}
              borderTop="1px solid #D9D9D9"
              px={{ md: "16px", base: "" }}
              py="8px"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems="center"
              lineHeight={"22px"}
            >
              <Text display={{ md: "flex", base: "none" }} textStyle={"caption"} fontSize={"14px"} color="#544D4F">
                Don’t want to <Text as="br" display={{ md: "none" }} /> proceed further?
              </Text>
              <Box display={"flex"}>
                <Tooltip
                  minW={{ base: "100%", md: "450px" }}
                  label={
                    <Box display={"flex"} flexDir={"column"} alignItems={"center"} gap={2}>
                      <Text>If you wish to resolve your technical queries regarding the course</Text>
                    </Box>
                  }
                  fontSize="md"
                  bg="white"
                  color="black"
                  borderRadius="md"
                  boxShadow="md"
                >
                  <Button
                    onClick={() => {
                      sendTrackingEvent({
                        event: "d_msat_explore_db",
                        eventLabel: location.pathname,
                      });
                      history.push("/alumni");
                    }}
                    size="sm"
                    ml="24px"
                    variant="secondary"
                  >
                    Connect with Alumni
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d_msat_explore_db",
                      eventLabel: location.pathname,
                    });
                    history.push("/");
                  }}
                  size="sm"
                  ml="24px"
                  variant="secondary"
                >
                  Goto Dashboard
                </Button>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box as="main" w="100%" p="0" mx="0">
          <Flex h="100%" w="100%">
            <Box pt={hideHeader ? "0px" : Constants.headerHeight} h="100%" w="100%" display={{ md: "grid" }} gridTemplateColumns={{ md: "100%" }}>
              <Box h="full">{children}</Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      <ModalTemplate
        title=""
        isOpen={isReferralModalOpen === undefined ? false : isReferralModalOpen}
        onClose={onReferralModalClose}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
      >
        <ReferralCodeModalContent onReferralModalClose={onReferralModalClose} referralErrorMessage={referralErrorMessage} setReferralErrorMessage={setReferralErrorMessage} />
      </ModalTemplate>
    </>
  );
};

export default Layout;
