import { HeaderMenuList, masaiHeaderMenuList, iitHeaderMenuList, iitRoparHeaderMenuList } from "../constants/revamp/header";
import { ProgramProfileType } from "../enums/ProgramType.enum";

export interface HeaderResponse {
  shouldHideHeaderLinks: boolean;
  shouldHideReqCallBtn: boolean;
  shouldHideReferEarnBtn: boolean;
  shouldHideReqCallBtnText: boolean;
  navLinks: HeaderMenuList[];
  shouldHideCourseDropDown: boolean;
}

export const getHeader = (pathname: string, programProfile: string): HeaderResponse => {
  const hideNavLinksUrl = [
    "/onboarding/12",
    "/onboarding/14",
    "/iit-onboarding",
    "/iitg-onboarding",
    "/iit-ropar-onboarding",
    "/minor-in-cse-onboarding",
    "/empowerHer-onboarding",
    "/minor-in-embedded-system",
    "/minor-in-embedded-system-onboarding",
    "/iit-mandi-business-analytics",
    "/iit-mandi-business-analytics-onboarding",
  ];
  const isMasaiCourse = programProfile === ProgramProfileType.MASAI;
  const isIITRoparCourse = programProfile === ProgramProfileType.IIT_ROPAR;
  const isIITMandiV2Course = programProfile === ProgramProfileType.IIT_MANDI_V2;
  const isIITMandiESCourse = programProfile === ProgramProfileType.IIT_MANDI_ES;

  if (hideNavLinksUrl.includes(pathname)) {
    return {
      shouldHideHeaderLinks: true,
      shouldHideReqCallBtn: false,
      shouldHideReferEarnBtn: true,
      shouldHideReqCallBtnText: false,
      navLinks: [],
      shouldHideCourseDropDown: true,
    };
  } else if (!isMasaiCourse) {
    return {
      shouldHideHeaderLinks: false,
      shouldHideReqCallBtn: false,
      shouldHideReferEarnBtn: true,
      shouldHideReqCallBtnText: false,
      navLinks: isIITRoparCourse || isIITMandiV2Course || isIITMandiESCourse ? iitRoparHeaderMenuList : iitHeaderMenuList,
      shouldHideCourseDropDown: isIITRoparCourse || isIITMandiV2Course || isIITMandiESCourse,
    };
  }
  return {
    shouldHideHeaderLinks: false,
    shouldHideReqCallBtn: false,
    shouldHideReferEarnBtn: false,
    shouldHideReqCallBtnText: true,
    navLinks: masaiHeaderMenuList,
    shouldHideCourseDropDown: false,
  };
};
