import React, { useContext, useEffect, useState } from "react";

import { Drawer, DrawerOverlay, DrawerContent, DrawerBody, Box, Text, Button, DrawerHeader, Flex, DrawerCloseButton, Badge, useBreakpointValue } from "@chakra-ui/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel, Image } from "@chakra-ui/react";
import { sendTrackingEvent } from "../utils/gtm";

import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { Logo, ModalTemplate } from "../general";
import { useDost } from "../../../api/utils/hooks/useDost";
import { COUNSELLING_TYPE, ProfileType, onboardingStatus } from "../../../api/schemas/schema";
import counsellingSessionIconImg from "../../../assets/images/counselling-session/iconoir_chat-bubble-empty-solid.webp";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import { isInEligibleUserFn, isRequestCallbackDoneFn } from "../../../utils/user";
import CounsellingSessionModal from "../../counselling-session/CounsellingSessionModal";
import InEligibleUserModalContent from "../InEligibleUserModalContent";
import FoundWebinarModalContent from "../../counselling-session/FoundWebinarModalContent";
import { useMutation } from "react-query";
import useSnackBar from "../general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import { submitCounsellingRequest } from "../../../api/utils/api/v2/activity";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { useLastAssessment } from "../../../api/utils/hooks/useAssessment";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";

type LabelType = "primary" | "secondary" | "tab" | "hamburger-label";

interface Props {
  isOpen: boolean;

  onClose: () => void;
  labelsList: {
    key: number | string;
    title: string | number;
    type: LabelType;
    badgeComponent?: React.ReactElement; // completely optional
    path: string;
    link?: string;
    isExternal?: boolean;
  }[];
}

const HamburgerMenu = ({ isOpen, onClose }: Props) => {
  const snackbar = useSnackBar();
  const [state, dispatch] = useContext(AppContext);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isFirstTimeShowingBanner, setIsFirstTimeShowingBanner] = useState<boolean>(false);
  const [isRequestCallbackDone, setIsRequestCallbackDone] = useState<boolean>(false);
  const [isInEligibleUserModalOpen, setIsInEligibleUserModalOpen] = useState<boolean>(false);
  const [isFounderWebinarModalOpen, setIsFounderWebinarModalOpen] = useState<boolean>(false);
  const [isCounsellingSessionModalOpen, setIsCounsellingSessionModalOpen] = useState<boolean>(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const [dataItems, setDataItems] = useState([
    {
      id: 1,
      label: "Masai Dost",
      link: "/dost",
      badge: "New",
      isExternal: false,
      isDiabled: false,
      event: "d_dost",
    },
    {
      id: 2,
      badge: "New",
      label: "Refer and earn amazing gifts",
      link: "/referral-program",
      isExternal: false,
      isDiabled: false,
      event: "d_header_refer",
    },
    {
      id: 3,
      label: "Join our telegram community",
      link: "https://shorturl.at/npFP5",
      isExternal: true,
      isDiabled: false,
      event: "d_telegram1",
    },
    {
      id: 4,
      label: "Success Stories",
      link: `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}/success-stories`,
      isExternal: true,
      isDiabled: false,
      event: "d_header_success_stories",
    },
  ]);
  const { data: dostData } = useDost();
  const { data: profileData, refetch: refetchProfile } = useProfile();

  const history = useHistory();
  const { data: activeApplication } = useActiveApplication();

  const { data: allCounsellingStatus, refetch: refetchCounsellingStatus } = useAllCounsellingStatus();

  const { mutate: submitCounsellingRequestMutate, isLoading: submitCounsellingRequestLoading } = useMutation(submitCounsellingRequest, {
    onSuccess: () => {
      snackbar.success("Callback Requested Successfully");
      refetchCounsellingStatus();
      setIsInEligibleUserModalOpen(false);
      setIsFirstTimeShowingBanner(false);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleCounsellingRequest = (source: COUNSELLING_TYPE) => {
    submitCounsellingRequestMutate({
      source,
    });
  };

  useEffect(() => {
    setDataItems(
      profileData?.program_profile_type === "MASAI"
        ? [
            {
              id: 1,
              label: "Masai Dost",
              link: "/dost",
              badge: "New",
              isExternal: false,
              isDiabled: onboardingStatus.includes(activeApplication?.status || "") ? (dostData?.me_registered ? true : false) : true,
              event: "d_dost",
            },
            {
              id: 2,
              badge: "New",
              label: "Refer and earn amazing gifts",
              link: "/referral-program",
              isExternal: false,
              isDiabled: false,
              event: "d_header_refer",
            },
            {
              id: 3,
              label: "Join our telegram community",
              link: "https://shorturl.at/npFP5",
              isExternal: true,
              isDiabled: false,
              event: "d_telegram1",
            },
            {
              id: 4,
              label: "Success Stories",
              link: `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}/success-stories`,
              isExternal: true,
              isDiabled: false,
              event: "d_header_sucess_stories",
            },
            {
              id: 5,
              label: "Events",
              link: `/activities`,
              isExternal: false,
              isDiabled: false,
              event: "d_header_activities",
            },
            {
              id: 5,
              label: "Scholarship",
              badge: "New",
              link: `/sonu-sood-scholarship`,
              isExternal: false,
              isDiabled: false,
              event: "d_header_scholarship",
            },
          ]
        : profileData?.program_profile_type === "IIT_GUWAHATI"
        ? [
            {
              id: 1,
              label: "IIT Guwahati",
              link: "/mc-iitg-cs",
              isDiabled: false,
              isExternal: false,
              event: "d_header_iitg_css",
            },
            {
              id: 2,
              label: "Introductory Course",
              link: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
              isExternal: true,
              isDiabled: false,
              event: "d_header_pre_course",
            },
            {
              id: 3,
              link: "/curriculum",
              label: "Curriculum",
              event: "d_ham_curriculum",
              isDiabled: false,
              isExternal: false,
            },
            // {
            //   id: 4,
            //   isDiabled: false,
            //   isExternal: false,
            //   link: "/life-at-guwahati",
            //   label: "Life at Guwahati",
            //   event: "d_ham_life_at_guwahati",
            // },
            {
              id: 5,
              isDiabled: false,
              isExternal: false,
              link: "/videos",
              label: "Videos",
              event: "d_ham_videos",
            },
            {
              id: 6,
              isDiabled: false,
              isExternal: false,
              link: "/media",
              label: "Media",
              event: "d_ham_media",
            },
          ]
        : profileData?.program_profile_type === "IIT_MANDI"
        ? [
            {
              id: 1,
              link: "/iit-mandi-ai-ml",
              label: "IIT Mandi",
              isDiabled: false,
              isExternal: false,
              event: "iitm_mandi_courses",
            },
            {
              id: 2,
              label: "Introductory Course",
              link: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
              isExternal: true,
              isDiabled: false,
              event: "d_header_pre_course",
            },
            {
              id: 3,
              link: "/curriculum",
              label: "Curriculum",
              event: "iit_mandi_ham_curriculum",
              isDiabled: false,
              isExternal: false,
            },
          ]
        : profileData?.program_profile_type === "IIT_MANDI_V2"
        ? [
            {
              id: 1,
              link: "/minor-in-cse",
              label: "IIT Mandi CSE",
              isDiabled: false,
              isExternal: false,
              event: "iit_mandi_cse_courses",
            },
          ]
        : profileData?.program_profile_type === "IIT_MANDI_ES"
        ? [
            {
              id: 1,
              link: "/minor-in-embedded-system",
              label: "IIT Mandi Embedded Systems",
              isDiabled: false,
              isExternal: false,
              event: "iit_mandi_cse_courses",
            },
          ]
        : [
            {
              id: 1,
              link: "/iit-ropar-ai",
              label: "IIT Ropar - AI",
              isDiabled: false,
              isExternal: false,
              event: "iitr_ropar_courses",
            },
          ]
    );
  }, [dostData, activeApplication, profileData]);

  useEffect(() => {
    const isIITGDashboard = state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
    const isIITMandiDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI;
    // get counselling status with source as request callback
    if (allCounsellingStatus && allCounsellingStatus.length > 0) {
      const counsellingStatus = isRequestCallbackDoneFn(allCounsellingStatus, isIITGDashboard, isIITMandiDashboard);
      if (counsellingStatus) {
        setIsRequestCallbackDone(true);
      }
    }
  }, [allCounsellingStatus]);

  useEffect(() => {
    const isInEligibleUser = isInEligibleUserFn(profileData as ProfileType);
    if (isInEligibleUser && isFirstTimeShowingBanner) {
      setIsInEligibleUserModalOpen(true);
    }
  }, [profileData, isFirstTimeShowingBanner]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <>
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay w={"full"} h={"full"} />
        <DrawerContent minW={"80%"} p="0px" boxShadow={"none"} h={"full"}>
          <DrawerHeader px="16px" pt="16px" pb="5px" mb="20px">
            {/* Masai Logo */}
            <Flex align="center" justify="space-between" cursor="pointer" maxH={"40px"}>
              <button
                onClick={() => {
                  history.push("/");
                }}
              >
                <Logo />
              </button>

              <DrawerCloseButton boxSize="24px" position="inherit" color="#848081" />
            </Flex>
          </DrawerHeader>
          <DrawerBody px="0px">
            <Box display={"flex"} flexDir={"column"} justifyContent={"space-between"} h={"full"}>
              <Box>
                {state.coursePreference === ProgramProfileType.MASAI && (
                  <>
                    <h2>
                      <Text px="16px" textStyle={"body2-md"} textAlign={"left"}>
                        Get your queries resolved now by talking to our Alumni’s
                        <Badge ml="3px" bg="#F6EDE7" rounded="100px" color="#CC926E">
                          New
                        </Badge>
                      </Text>
                    </h2>
                    <Box px="16px" pb="14px">
                      <Box position={"relative"} mt="9px" display={"flex"} bgColor={"#6E71CC"} borderRadius={"8px"}>
                        <Box pl="10px">
                          <Text py="16px" my="auto" mr="48px" textStyle={"caption"} color="#fff">
                            1000+ others have reached out to our Alumni for their experience of Masai. Your success is just a chat away!
                          </Text>
                        </Box>
                        <Image position={"absolute"} right="0" top="0" src="/img/sidebar-student-1.svg" />
                        <Image right="0" bottom="0" position={"absolute"} src="/img/sidebar-student-2.svg" />
                      </Box>
                      <Button
                        onClick={() => {
                          sendTrackingEvent({
                            event: "d_connect_alumni",
                            eventLabel: window.location.pathname,
                          });
                          history.push("/alumni");
                        }}
                        mt="12px"
                        size="sm"
                        variant="primary"
                        width={"100%"}
                      >
                        connect with our alumni
                      </Button>
                    </Box>
                    {/* Book Counselling Session */}
                    <Box borderTop={"1px solid #E3E1E1"}>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton>
                            <Text textStyle={"body2-md"} flex="1" textAlign="left">
                              Book a counselling session
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>

                          <AccordionPanel pb={4}>
                            <Box bg={"#F2F6FF"} rounded={"lg"} h={"auto"} w={"full"} mx={"auto"} p={"12px"}>
                              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"8px"} textAlign={"center"}>
                                <Image w={46} h={46} src={counsellingSessionIconImg} />
                                <Box>
                                  <Text fontSize="13px">Have a query? Connect with our team member and get it resolved</Text>
                                </Box>
                              </Box>
                              <Button
                                disabled={isRequestCallbackDone}
                                onClick={() => {
                                  setIsCounsellingSessionModalOpen(true);
                                  onClose();
                                }}
                                w={"full"}
                                mt="8px"
                                variant="primary"
                              >
                                {isRequestCallbackDone ? "Booked" : "Book now"}
                              </Button>
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Box>{" "}
                  </>
                )}

                {dataItems.map((item) =>
                  !item.isExternal ? (
                    <Box
                      w="full"
                      to={item.link}
                      as={RouterLink}
                      cursor="pointer"
                      display={item?.isDiabled ? "none" : "block"}
                      borderBottom={item.id === dataItems.length ? "1px solid #E3E1E1" : ""}
                      borderTop={item.link === "/mc-iitg-cs" ? "" : "1px solid #E3E1E1"}
                      key={item.id}
                      py="10px"
                      px="18px"
                      onClick={() => {
                        sendTrackingEvent({
                          event: item.event,
                          eventLabel: window.location.pathname,
                        });
                      }}
                    >
                      <Text textStyle={"body2-md"}>
                        {item.label}
                        {item?.badge ? (
                          <Badge ml="3px" bg="#F6EDE7" rounded="100px" color="#CC926E">
                            {item?.badge}
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </Text>
                    </Box>
                  ) : (
                    <Box
                      as="a"
                      w="full"
                      target={item.event === "d_header_pre_course" ? "_self" : "_blank"}
                      href={item.link}
                      textAlign="left"
                      cursor="pointer"
                      display={item?.isDiabled ? "none" : "block"}
                      borderBottom={item.id === dataItems.length ? "1px solid #E3E1E1" : ""}
                      borderTop={"1px solid #E3E1E1"}
                      key={item.id}
                      py="10px"
                      px="18px"
                      onClick={() => {
                        sendTrackingEvent({
                          event: item.event,
                          eventLabel: window.location.pathname,
                        });
                      }}
                    >
                      <Text textStyle={"body2-md"}>
                        {item.label}
                        {item?.badge ? (
                          <Badge ml="3px" bg="#F6EDE7" rounded="100px" color="#CC926E">
                            {item?.badge}
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </Text>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ModalTemplate
        title=""
        isOpen={isCounsellingSessionModalOpen}
        onClose={() => {
          setIsCounsellingSessionModalOpen(false);
        }}
        showCloseButtonIcon={true}
        maxWidth={isMobile ? "90%" : "570px"}
      >
        <CounsellingSessionModal
          setIsFirstTimeShowingBanner={setIsFirstTimeShowingBanner}
          setIsCounsellingSessionModalOpen={setIsCounsellingSessionModalOpen}
          refetchCounsellingStatus={refetchCounsellingStatus}
          refetchProfile={refetchProfile}
          setIsFounderWebinarModalOpen={setIsFounderWebinarModalOpen}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={isInEligibleUserModalOpen}
        onClose={() => {
          setIsInEligibleUserModalOpen(false);
        }}
        showCloseButtonIcon={true}
        maxWidth={isMobile ? "90%" : "524px"}
      >
        <InEligibleUserModalContent handleCounsellingRequest={handleCounsellingRequest} submitCounsellingRequestLoading={submitCounsellingRequestLoading} />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={isFounderWebinarModalOpen}
        onClose={() => {
          setIsFounderWebinarModalOpen(false);
        }}
        showCloseButtonIcon={true}
        maxWidth={isMobile ? "90%" : "490px"}
      >
        <FoundWebinarModalContent setIsFounderWebinarModalOpen={setIsFounderWebinarModalOpen} />
      </ModalTemplate>
    </>
  );
};

export default HamburgerMenu;
