import { useState, useEffect, useRef } from "react";
import useSnackBar from "../../../components/common/general/SnackBar";
import { useMutation, useQuery } from "react-query";
import { CreatePaymentRecordPostForIITEntranceMainFullPaymnet, GetPaymentStatus } from "../api/v2/payment";
import { axiosErrorHandler } from "../error";
import { RazorPaySuccessPost } from "../api/v2/payment";
import type { PaymentType } from "../../schemas/schema";

function useIITEntranceOnboardingFullPayment(onSuccess?: () => void) {
    const [isLoading, setisLoading] = useState(false);
    const ref = useRef<{ course: string }>(null);
    const snackbar = useSnackBar();
    const { mutate: paymentStatusCheckerMutate, isLoading: isLoadingPaymentSuccessChecker } = useMutation(RazorPaySuccessPost, {
        onSuccess: async () => {
            snackbar.success("Payment Successful!");

            if (onSuccess) {
                onSuccess();
                return;
            }
            refetch();
        },
        onError: (err) => {
            const e = axiosErrorHandler(err);
            if (typeof e === "object" && "message" in e) {
                snackbar.error(e.message);
            } else {
                snackbar.error("something went wrong");
            }
            // redirect to failure page
        },
    });
    const { mutate: paymentMutate, isLoading: isPaymentLoading } = useMutation(CreatePaymentRecordPostForIITEntranceMainFullPaymnet, {
        onSuccess: async (data) => {
            snackbar.success("Starting payment process!");
            const options = {
                key: process.env.REACT_APP_RAZORPAY_CLIENT_ID || "",
                order_id: data?.order_id,
                name: `${ref.current?.course || ""}`,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handler: (response: any) => {
                    if (response?.razorpay_payment_id && response?.razorpay_order_id && response?.razorpay_signature) {
                        // send request to backend to verify payment
                        paymentStatusCheckerMutate({
                            razorpay_payment_id: response?.razorpay_payment_id,
                            razorpay_order_id: response?.razorpay_order_id,
                            razorpay_signature: response?.razorpay_signature,
                        });
                    }
                },
            };
            if (window?.Razorpay) {
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }
        },
        onError: (err) => {
            const e = axiosErrorHandler(err);
            if (typeof e === "object" && "message" in e) {
                snackbar.error(e.message);
            } else {
                snackbar.error("something went wrong");
            }
        },
    });

    const { refetch } = useGetPaymentStatus("REGISTRATION_FEES");
    useEffect(() => {
        if (isLoadingPaymentSuccessChecker === false || isPaymentLoading === false) {
            setisLoading(false);
        } else {
            setisLoading(true);
        }
    }, [isLoadingPaymentSuccessChecker, isPaymentLoading]);
    // display razorpay modal once order id is available
    const startRazorPay = async (courseId: string) => {
        if (ref.current != null) {
            ref.current.course = courseId;
        }
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
            snackbar.error("Payment Gateway failed to load. Please check internet connection!");
            return;
        }
        const params = { payment_type: "REGISTRATION_FEES", course_id: courseId };
        paymentMutate({ ...params });
    };
    return {
        startRazorPay,
        isLoading: isLoading,
        isApiLoading: isLoadingPaymentSuccessChecker || isPaymentLoading,
    };
}

export default useIITEntranceOnboardingFullPayment;
const loadScript = (src: string) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

export function useGetPaymentStatus(paymentType: PaymentType) {
    return useQuery({
        queryKey: ["getPaymentStatus", paymentType],
        queryFn: async () => await GetPaymentStatus(paymentType),
        staleTime: 60000,
        retry: false, // Number of times to retry a failed query
        retryOnMount: false,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}
